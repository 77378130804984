import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function Chart({ title, dataA, dataB, chartLabels }) {
	//chart options
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom',
			},
			title: {
				display: true,
				text: title,
			},
		},
	}

	//chart labels
	const labels = chartLabels

	const chartData = {
		labels,
		datasets: [
			{
				label: 'Omzet',
				data: dataA,
				backgroundColor: 'green',
			},
			{
				label: 'Biaya',
				data: dataB,
				backgroundColor: '#FF4500',
			},
		],
	}

	return (
		<div
			style={{
				background: 'white',
				padding: '2px',
				borderRadius: '5px',
				marginBottom: '20px',
				marginTop: '10px',
			}}
		>
			<Bar options={options} data={chartData} />
		</div>
	)
}

export default Chart
