import PropTypes from 'prop-types'
import { FaEdit, FaTimesCircle, FaPhone } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Card from '../../components/shared/Card'

function AdminAccessItem({ item, deleteAdminAccess }) {
	return (
		<Card reverse={false}>
			<div className="flex-2cols">
				<div className="text-title">{item.name}</div>
				<span
					className={`badge ${
						item.status === 'active' ? 'badge-active' : 'badge-inactive'
					}`}
				>
					{item.status === 'active' ? 'Aktif' : 'Inaktif'}
				</span>
			</div>
			<div className="inputGroup2cols">
				<div className="text-display">{item.property_names}</div>
				<div className="text-display" style={{ textAlign: 'right' }}>
					<FaPhone /> {item.handphone}
				</div>
			</div>
			<div style={{ marginTop: '10px' }}>
				<Link to={`/adminaccess/edit/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaEdit /> Ubah
					</button>
				</Link>
				<button
					className="btn btn-small btn-danger"
					onClick={() => deleteAdminAccess(item.id)}
				>
					<FaTimesCircle /> Hapus
				</button>
			</div>
		</Card>
	)
}

AdminAccessItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default AdminAccessItem
