import { useContext, useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom'

import ExpensesItem from './ExpensesItem'
import AuthContext from '../../context/AuthContext'
import PropertiesContext from '../../context/PropertiesContext'
import Button from '../../components/shared/Button'
import Spinner2 from '../../components/Spinner2'
import PropertySelect from '../../components/PropertySelect'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function ExpensesList() {
	const { properties } = useContext(PropertiesContext)
	const { activeProperty, userData } = useContext(AuthContext)
	const { currMonth, currYear, role, admin_access } = userData
	const { property_id, property_name } = activeProperty

	const [year, setYear] = useState(currYear)
	const [month, setMonth] = useState(currMonth)
	const [expenses, setExpenses] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin' && admin_access.expenses === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role, admin_access])

	useEffect(() => {
		if (property_id) {
			getAllExpenses(property_id, month, year)
		}
		//eslint-disable-next-line
	}, [property_id, month, year])

	//get all expenses from API
	const getAllExpenses = async (property_id, month, year) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/expense/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}&month=${month}&year=${year}`

			const config = configData()

			const res = await axios.get(url, config)

			setExpenses(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//delete expense item
	const deleteExpense = async (property_id, id, month, year) => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		confirmAlert({
			title: 'Yakin mau hapus?',
			message: 'Data biaya ini akan terhapus selamanya.',
			buttons: [
				{
					label: 'Batal',
					onClick: () => null,
				},
				{
					label: 'Hapus',
					onClick: async () => {
						setIsLoading(true)

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/expense/delete?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

							const config = configData()

							let formData = new FormData()
							formData.append('property_id', property_id)
							formData.append('expense_id', id)

							const res = await axios.post(url, formData, config)

							if (res.status === 200) {
								getAllExpenses(property_id, month, year)

								toast.success('Sukses menghapus biaya.', {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	let expenseInfo

	if (expenses && expenses.length === 0) {
		expenseInfo = <p>Belum ada biaya. Silahkan tambah baru.</p>
	} else if (expenses && expenses.length > 0) {
		expenseInfo = (
			<div className="feedback-list">
				{expenses.map((item) => (
					<ExpensesItem
						key={item.id}
						item={item}
						month={month}
						year={year}
						deleteExpense={deleteExpense}
					/>
				))}
			</div>
		)
	}

	const onChangeYear = (e) => {
		setYear(e.target.value)
	}

	const onChangeMonth = (e) => {
		setMonth(e.target.value)
	}

	const yearText = ['2022', '2023', '2024']

	const monthText = [
		'Januari',
		'Februari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember',
	]

	const date = new Date()
	date.setMonth(month - 1)
	const currMonthText = date.toLocaleString('id-ID', {
		month: 'short',
	})

	let total = 0
	if (expenses && expenses.length > 0) {
		expenses.forEach((expense) => {
			total = parseInt(total) + parseInt(expense.amount)
		})
	}

	let addExpenseButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.expenses_add === 'yes')
	) {
		addExpenseButton = (
			<Link to="/expenses/edit">
				<Button type="button" version="block" isDisabled={false}>
					<FaPlus /> Tambah Biaya
				</Button>
			</Link>
		)
	}

	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})

	return (
		<>
			<h2 className="pageTitle">Biaya Operasional</h2>
			<p>Pencatatan biaya operasional untuk menghitung laba rugi.</p>
			{properties.length > 1 && <PropertySelect />}
			{addExpenseButton}
			<h3>Biaya {property_name}</h3>
			<div className="inputGroup2cols">
				<div className="inputGroup">
					<select
						value={year}
						onChange={onChangeYear}
						className="select"
						required
					>
						<option value="">Pilih Tahun</option>
						{yearText.map((text, index) => (
							<option key={index} value={text}>
								{text}
							</option>
						))}
					</select>
				</div>
				<div className="inputGroup">
					<select
						value={month}
						onChange={onChangeMonth}
						className="select"
						required
					>
						<option value="">Pilih Bulan</option>
						{monthText.map((text, index) => (
							<option key={index} value={index + 1}>
								{text}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<p>
				<strong>
					Biaya {currMonthText} {year}: {formatter.format(total)}
				</strong>
			</p>
			{expenseInfo}
		</>
	)
}

export default ExpensesList
