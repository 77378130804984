import { useContext } from 'react'
import PropTypes from 'prop-types'
import { FaTimesCircle, FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

function ExpensesItem({ item, month, year, deleteExpense }) {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	let deleteExpenseButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.expenses_delete === 'yes')
	) {
		deleteExpenseButton = (
			<button
				className="btn btn-small btn-danger btnNoMargin"
				onClick={() => deleteExpense(property_id, item.id, month, year)}
			>
				<FaTimesCircle /> Hapus
			</button>
		)
	}

	return (
		<Card reverse={false}>
			<div className="flex-2cols">
				<div className="text-title">{item.expense_name}</div>
				<div className="text-display">
					{moment(item.date).format('DD MMM YYYY')}
				</div>
			</div>
			<div className="flex-2cols">
				<div className="text-display">{formatter.format(item.amount)}</div>
				<div style={{ marginTop: '10px' }}>
					<Link to={`/expenses/edit/${item.id}`}>
						<button className="btn btn-small btn-success">
							<FaEdit /> Ubah
						</button>
					</Link>
					{deleteExpenseButton}
				</div>
			</div>
		</Card>
	)
}

ExpensesItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default ExpensesItem
