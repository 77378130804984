import { useContext, useEffect } from 'react'
import { FaPlus, FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import PropertiesItem from './PropertiesItem'
import PropertiesContext from '../../context/PropertiesContext'
import AuthContext from '../../context/AuthContext'
import Button from '../../components/shared/Button'
import Spinner2 from '../../components/Spinner2'

function PropertiesList() {
	const { properties, getAllProperties, isLoading } =
		useContext(PropertiesContext)
	const { userData } = useContext(AuthContext)
	const { role } = userData

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role])

	useEffect(() => {
		getAllProperties()
		//eslint-disable-next-line
	}, [])

	let propertyInfo

	if (properties && properties.length === 0) {
		propertyInfo = <p>Belum ada properti. Silahkan tambah baru.</p>
	} else if (properties && properties.length > 0) {
		propertyInfo = (
			<div className="feedback-list">
				{properties.map((item) => (
					<PropertiesItem key={item.id} item={item} />
				))}
			</div>
		)
	}

	return (
		<>
			<Link to="/setting">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">Atur Properti</h2>
			<Link to="/properties/edit">
				<Button type="button" version="block" isDisabled={false}>
					<FaPlus /> Tambah Properti
				</Button>
			</Link>
			<h3>Daftar Properti Anda</h3>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{propertyInfo}
		</>
	)
}

export default PropertiesList
