import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft, FaUsers, FaBed, FaFan, FaCog } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { useNavigate } from 'react-router-dom'
import Switch from 'react-switch'

import Button from '../../components/shared/Button'
import PropertiesContext from '../../context/PropertiesContext'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function AdminAccessForm() {
	const { properties, getAllProperties } = useContext(PropertiesContext)
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role } = userData

	const [formData, setFormData] = useState({
		name: '',
		handphone: '',
		status: 'active',
		tenants: true,
		tenants_add: true,
		tenants_edit: true,
		tenants_checkout: true,
		rooms: true,
		rooms_add: true,
		rooms_edit: true,
		rooms_delete: true,
		expenses: true,
		expenses_add: true,
		expenses_edit: true,
		expenses_delete: true,
		services: true,
		services_add: true,
		services_edit: true,
		services_delete: true,
		report: false,
		billing: false,
	})
	const {
		name,
		handphone,
		status,
		tenants,
		tenants_add,
		tenants_edit,
		tenants_checkout,
		rooms,
		rooms_add,
		rooms_edit,
		rooms_delete,
		expenses,
		expenses_add,
		expenses_edit,
		expenses_delete,
		services,
		services_add,
		services_edit,
		services_delete,
		report,
		billing,
	} = formData
	const [isLoading, setIsLoading] = useState(false)
	const [chosenProperties, setChosenProperties] = useState([])

	let { id } = useParams()

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role])

	useEffect(() => {
		getAllProperties()
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (!id) {
			if (properties.length > 0) {
				const currProperties = []

				properties.forEach((property) => {
					currProperties.push({
						id: property.id,
						isChecked: false,
					})
				})
				setChosenProperties(currProperties)
			}
		}
		//eslint-disable-next-line
	}, [properties])

	useEffect(() => {
		if (id && property_id) {
			getAdmin(property_id, id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	//get single admin (detail data) from API
	const getAdmin = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/admin_access/detail?user_id=${tokens[2]}&property_id=${property_id}&admin_id=${id}&refresh_token=${tokens[1]}`

			const config = configData()

			const res = await axios.get(url, config)

			setFormData({
				name: res.data.data.name,
				handphone: res.data.data.handphone,
				status: res.data.data.status,
				tenants: res.data.data.tenants === 'yes' ? true : false,
				tenants_add: res.data.data.tenants_add === 'yes' ? true : false,
				tenants_edit: res.data.data.tenants_edit === 'yes' ? true : false,
				tenants_checkout:
					res.data.data.tenants_checkout === 'yes' ? true : false,
				rooms: res.data.data.tenants_add === 'yes' ? true : false,
				rooms_add: res.data.data.rooms_add === 'yes' ? true : false,
				rooms_edit: res.data.data.rooms_edit === 'yes' ? true : false,
				rooms_delete: res.data.data.rooms_delete === 'yes' ? true : false,
				expenses: res.data.data.expenses === 'yes' ? true : false,
				expenses_add: res.data.data.expenses_add === 'yes' ? true : false,
				expenses_edit: res.data.data.expenses_edit === 'yes' ? true : false,
				expenses_delete: res.data.data.expenses_delete === 'yes' ? true : false,
				services: res.data.data.services === 'yes' ? true : false,
				services_add: res.data.data.services_add === 'yes' ? true : false,
				services_edit: res.data.data.services_edit === 'yes' ? true : false,
				services_delete: res.data.data.services_delete === 'yes' ? true : false,
				report: res.data.data.report === 'yes' ? true : false,
				billing: res.data.data.billing === 'yes' ? true : false,
			})

			setChosenProperties(res.data.data.chosenProperties)
		} catch (err) {
			navigate('/adminaccess')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new admin
	const addAdmin = async (newAdmin) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/admin_access/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)

			formData.append('name', newAdmin.name)
			formData.append('handphone', newAdmin.handphone)
			formData.append('status', newAdmin.status)

			formData.append('tenants', newAdmin.tenants)
			formData.append('tenants_add', newAdmin.tenants_add)
			formData.append('tenants_edit', newAdmin.tenants_edit)
			formData.append('tenants_checkout', newAdmin.tenants_checkout)

			formData.append('rooms', newAdmin.rooms)
			formData.append('rooms_add', newAdmin.rooms_add)
			formData.append('rooms_edit', newAdmin.rooms_edit)
			formData.append('rooms_delete', newAdmin.rooms_delete)

			formData.append('expenses', newAdmin.expenses)
			formData.append('expenses_add', newAdmin.expenses_add)
			formData.append('expenses_edit', newAdmin.expenses_edit)
			formData.append('expenses_delete', newAdmin.expenses_delete)

			formData.append('services', newAdmin.services)
			formData.append('services_add', newAdmin.services_add)
			formData.append('services_edit', newAdmin.services_edit)
			formData.append('services_delete', newAdmin.services_delete)

			formData.append('report', newAdmin.report)
			formData.append('billing', newAdmin.billing)
			formData.append(
				'chosenProperties',
				JSON.stringify(newAdmin.chosenProperties)
			)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				navigate('/adminaccess')

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(true)
	}

	//update admin
	const updateAdmin = async (id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/admin_access/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()

			formData.append('property_id', property_id)
			formData.append('admin_id', id)
			formData.append('name', updItem.name)
			formData.append('handphone', updItem.handphone)
			formData.append('status', updItem.status)

			formData.append('tenants', updItem.tenants)
			formData.append('tenants_add', updItem.tenants_add)
			formData.append('tenants_edit', updItem.tenants_edit)
			formData.append('tenants_checkout', updItem.tenants_checkout)

			formData.append('rooms', updItem.rooms)
			formData.append('rooms_add', updItem.rooms_add)
			formData.append('rooms_edit', updItem.rooms_edit)
			formData.append('rooms_delete', updItem.rooms_delete)

			formData.append('expenses', updItem.expenses)
			formData.append('expenses_add', updItem.expenses_add)
			formData.append('expenses_edit', updItem.expenses_edit)
			formData.append('expenses_delete', updItem.expenses_delete)

			formData.append('services', updItem.services)
			formData.append('services_add', updItem.services_add)
			formData.append('services_edit', updItem.services_edit)
			formData.append('services_delete', updItem.services_delete)

			formData.append('report', updItem.report)
			formData.append('billing', updItem.billing)
			formData.append(
				'chosenProperties',
				JSON.stringify(updItem.chosenProperties)
			)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				getAllProperties()

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const onChangeSwitch = (isChecked, event, id) => {
		switch (id) {
			//tenants
			case 'tenants':
				let newTenants = !tenants

				setFormData((prevState) => ({
					...prevState,
					tenants: !tenants,
				}))

				if (!newTenants) {
					setFormData((prevState) => ({
						...prevState,
						tenants_add: false,
						tenants_edit: false,
						tenants_checkout: false,
					}))
				} else {
					setFormData((prevState) => ({
						...prevState,
						tenants_add: true,
						tenants_edit: true,
						tenants_checkout: true,
					}))
				}
				break
			case 'tenants_add':
				if (tenants) {
					setFormData((prevState) => ({
						...prevState,
						tenants_add: !tenants_add,
					}))
				}
				break
			case 'tenants_edit':
				if (tenants) {
					setFormData((prevState) => ({
						...prevState,
						tenants_edit: !tenants_edit,
					}))
				}
				break
			case 'tenants_checkout':
				if (tenants) {
					setFormData((prevState) => ({
						...prevState,
						tenants_checkout: !tenants_checkout,
					}))
				}
				break

			//rooms
			case 'rooms':
				let newrooms = !rooms

				setFormData((prevState) => ({
					...prevState,
					rooms: !rooms,
				}))

				if (!newrooms) {
					setFormData((prevState) => ({
						...prevState,
						rooms_add: false,
						rooms_edit: false,
						rooms_delete: false,
					}))
				} else {
					setFormData((prevState) => ({
						...prevState,
						rooms_add: true,
						rooms_edit: true,
						rooms_delete: true,
					}))
				}
				break
			case 'rooms_add':
				if (rooms) {
					setFormData((prevState) => ({
						...prevState,
						rooms_add: !rooms_add,
					}))
				}
				break
			case 'rooms_edit':
				if (rooms) {
					setFormData((prevState) => ({
						...prevState,
						rooms_edit: !rooms_edit,
					}))
				}
				break
			case 'rooms_delete':
				if (rooms) {
					setFormData((prevState) => ({
						...prevState,
						rooms_delete: !rooms_delete,
					}))
				}
				break

			//expenses
			case 'expenses':
				let newexpenses = !expenses

				setFormData((prevState) => ({
					...prevState,
					expenses: !expenses,
				}))

				if (!newexpenses) {
					setFormData((prevState) => ({
						...prevState,
						expenses_add: false,
						expenses_edit: false,
						expenses_delete: false,
					}))
				} else {
					setFormData((prevState) => ({
						...prevState,
						expenses_add: true,
						expenses_edit: true,
						expenses_delete: true,
					}))
				}
				break
			case 'expenses_add':
				if (expenses) {
					setFormData((prevState) => ({
						...prevState,
						expenses_add: !expenses_add,
					}))
				}
				break
			case 'expenses_edit':
				if (expenses) {
					setFormData((prevState) => ({
						...prevState,
						expenses_edit: !expenses_edit,
					}))
				}
				break
			case 'expenses_delete':
				if (expenses) {
					setFormData((prevState) => ({
						...prevState,
						expenses_delete: !expenses_delete,
					}))
				}
				break

			//services
			case 'services':
				let newservices = !services

				setFormData((prevState) => ({
					...prevState,
					services: !services,
				}))

				if (!newservices) {
					setFormData((prevState) => ({
						...prevState,
						services_add: false,
						services_edit: false,
						services_delete: false,
					}))
				} else {
					setFormData((prevState) => ({
						...prevState,
						services_add: true,
						services_edit: true,
						services_delete: true,
					}))
				}
				break
			case 'services_add':
				if (services) {
					setFormData((prevState) => ({
						...prevState,
						services_add: !services_add,
					}))
				}
				break
			case 'services_edit':
				if (services) {
					setFormData((prevState) => ({
						...prevState,
						services_edit: !services_edit,
					}))
				}
				break
			case 'services_delete':
				if (services) {
					setFormData((prevState) => ({
						...prevState,
						services_delete: !services_delete,
					}))
				}
				break

			//others
			case 'report':
				setFormData((prevState) => ({
					...prevState,
					report: !report,
				}))
				break
			case 'billing':
				setFormData((prevState) => ({
					...prevState,
					billing: !billing,
				}))
				break

			default:
				break
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!name || !handphone) {
			toast.error('Mohon mengisi nama dan handphone.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		//check for chosen properties
		let countChosenProperties = 0
		chosenProperties.forEach((property) => {
			if (property.isChecked === true) {
				countChosenProperties++
			}
		})

		if (countChosenProperties === 0) {
			toast.error('Harus pilih minimal 1 properti.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const twoCharsHp = handphone.substring(0, 2)

		if (twoCharsHp !== '08') {
			toast.error('Nomor Hp harus dimulai dengan 08.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const newAdmin = {
			name,
			handphone,
			status,
			tenants,
			tenants_add,
			tenants_edit,
			tenants_checkout,
			rooms,
			rooms_add,
			rooms_edit,
			rooms_delete,
			expenses,
			expenses_add,
			expenses_edit,
			expenses_delete,
			services,
			services_add,
			services_edit,
			services_delete,
			report,
			billing,
			chosenProperties,
		}

		if (id) {
			updateAdmin(id, newAdmin)
		} else {
			addAdmin(newAdmin)
		}
	}

	const handleCheckbox = (index) => {
		const newChosenProperties = [...chosenProperties]

		newChosenProperties[index].isChecked = !newChosenProperties[index].isChecked

		setChosenProperties(newChosenProperties)
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Ubah'
	} else if (!isLoading && !id) {
		buttonState = 'Tambah'
	}

	return (
		<>
			<Link to="/adminaccess">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">{id ? 'Edit' : 'Tambah'} Admin</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Admin</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Nama Admin"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Handphone (WA) Admin</b>
						</label>
					</div>
					<input
						type="number"
						className="textInput"
						placeholder="Nomor WA 08XXXXXXXXXX"
						id="handphone"
						value={handphone}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputLabel">
					<label>
						<b>Pilih Properti</b>
					</label>
				</div>
				<div className="inputGroup2cols">
					{properties &&
						properties.map(({ id, name }, index) => {
							return (
								<div className="inputGroup" key={id}>
									<input
										type="checkbox"
										checked={
											chosenProperties[index] &&
											chosenProperties[index].isChecked
										}
										onClick={() => handleCheckbox(index)}
										style={{
											height: '20px',
											width: '20px',
											position: 'relative',
											top: '5px',
										}}
									/>{' '}
									<span style={{ textTransform: 'capitalize' }}>{name}</span>
								</div>
							)
						})}
				</div>

				<div className="inputGroup">
					<div className="inputLabel">
						<label style={{ cursor: 'pointer' }}>
							<b>Pilih Status Akses</b>
						</label>
					</div>
					<select
						id="status"
						value={status}
						onChange={onChange}
						className="select"
						required
					>
						<option value="">Pilih Status</option>
						<option value="active">Aktif</option>
						<option value="inactive">Tidak Aktif</option>
					</select>
				</div>

				<Accordion allowZeroExpanded>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<FaUsers /> Fitur Penyewa
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p>
								Pengaturan Fitur penyewa. Bila di nonaktifkan, admin tidak dapat
								mengakses fitur terkait.
							</p>
							<div className="inputGroup2cols">
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Fitur Penyewa</b>
										</label>
									</div>
									<Switch
										id="tenants"
										width={70}
										onChange={onChangeSwitch}
										checked={tenants}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Tambah Penyewa</b>
										</label>
									</div>
									<Switch
										id="tenants_add"
										width={70}
										onChange={onChangeSwitch}
										checked={tenants_add}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Ubah Penyewa</b>
										</label>
									</div>
									<Switch
										id="tenants_edit"
										width={70}
										onChange={onChangeSwitch}
										checked={tenants_edit}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Checkout Penyewa</b>
										</label>
									</div>
									<Switch
										id="tenants_checkout"
										width={70}
										onChange={onChangeSwitch}
										checked={tenants_checkout}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>

				<Accordion allowZeroExpanded>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<FaBed /> Fitur Kamar
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p>
								Pengaturan Fitur kamar. Bila di nonaktifkan, admin tidak dapat
								mengakses fitur terkait.
							</p>
							<div className="inputGroup2cols">
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Fitur Kamar</b>
										</label>
									</div>
									<Switch
										id="rooms"
										width={70}
										onChange={onChangeSwitch}
										checked={rooms}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Tambah Kamar</b>
										</label>
									</div>
									<Switch
										id="rooms_add"
										width={70}
										onChange={onChangeSwitch}
										checked={rooms_add}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Ubah Kamar</b>
										</label>
									</div>
									<Switch
										id="rooms_edit"
										width={70}
										onChange={onChangeSwitch}
										checked={rooms_edit}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Hapus Kamar</b>
										</label>
									</div>
									<Switch
										id="rooms_delete"
										width={70}
										onChange={onChangeSwitch}
										checked={rooms_delete}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>

				<Accordion allowZeroExpanded>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<FaFan /> Fitur Biaya
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p>
								Pengaturan Fitur Biaya. Bila di nonaktifkan, admin tidak dapat
								mengakses fitur terkait.
							</p>
							<div className="inputGroup2cols">
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Fitur Biaya</b>
										</label>
									</div>
									<Switch
										id="expenses"
										width={70}
										onChange={onChangeSwitch}
										checked={expenses}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Tambah Biaya</b>
										</label>
									</div>
									<Switch
										id="expenses_add"
										width={70}
										onChange={onChangeSwitch}
										checked={expenses_add}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Ubah Biaya</b>
										</label>
									</div>
									<Switch
										id="expenses_edit"
										width={70}
										onChange={onChangeSwitch}
										checked={expenses_edit}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Hapus Biaya</b>
										</label>
									</div>
									<Switch
										id="expenses_delete"
										width={70}
										onChange={onChangeSwitch}
										checked={expenses_delete}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>

				<Accordion allowZeroExpanded>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<FaFan /> Fitur Layanan Tambahan
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p>
								Pengaturan Fitur Layanan Tambahan. Bila di nonaktifkan, admin
								tidak dapat mengakses fitur terkait.
							</p>
							<div className="inputGroup2cols">
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Fitur Layanan Tambahan</b>
										</label>
									</div>
									<Switch
										id="services"
										width={70}
										onChange={onChangeSwitch}
										checked={services}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Tambah Layanan Tambahan</b>
										</label>
									</div>
									<Switch
										id="services_add"
										width={70}
										onChange={onChangeSwitch}
										checked={services_add}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Ubah Layanan Tambahan</b>
										</label>
									</div>
									<Switch
										id="services_edit"
										width={70}
										onChange={onChangeSwitch}
										checked={services_edit}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Hapus Layanan Tambahan</b>
										</label>
									</div>
									<Switch
										id="services_delete"
										width={70}
										onChange={onChangeSwitch}
										checked={services_delete}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>

				<Accordion allowZeroExpanded>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<FaCog /> Fitur Lainnya
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p>
								Pengaturan Fitur Lainnya. Bila di nonaktifkan, admin tidak dapat
								mengakses fitur terkait.
							</p>
							<div className="inputGroup2cols">
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Fitur Laporan</b>
										</label>
									</div>
									<Switch
										id="report"
										width={70}
										onChange={onChangeSwitch}
										checked={report}
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Fitur Langganan</b>
										</label>
									</div>
									<Switch
										id="billing"
										width={70}
										onChange={onChangeSwitch}
										checked={billing}
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>

				<Button type="submit" version="block" isDisabled={isLoading}>
					{buttonState}
				</Button>
			</form>
			<br />
			<Link to="/adminaccess">
				<FaChevronLeft /> Kembali
			</Link>
		</>
	)
}

export default AdminAccessForm
