import { useContext } from 'react'
import PropTypes from 'prop-types'
import { FaEdit, FaTimesCircle, FaPhone } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Card from '../../components/shared/Card'
import PropertiesContext from '../../context/PropertiesContext'

function PropertiesItem({ item }) {
	const { deleteProperty } = useContext(PropertiesContext)

	return (
		<Card reverse={false}>
			<div className="flex-2cols">
				<div className="text-title">{item.name}</div>
				<span
					className={`badge ${
						item.status === 'active' ? 'badge-active' : 'badge-inactive'
					}`}
				>
					{item.status === 'active' ? 'Aktif' : 'Inaktif'}
				</span>
			</div>
			<div className="text-display">
				{item.address}. {item.city}
			</div>
			<div className="text-display">
				<span className="priceBorder">
					<FaPhone /> Admin: {item.admin_handphone}
				</span>
			</div>
			<div style={{ marginTop: '10px' }}>
				<Link to={`/properties/edit/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaEdit /> Ubah
					</button>
				</Link>
				<button
					className="btn btn-small btn-danger"
					onClick={() => deleteProperty(item.id)}
				>
					<FaTimesCircle /> Hapus
				</button>
			</div>
		</Card>
	)
}

PropertiesItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default PropertiesItem
