import { useContext, useState, useEffect } from 'react'
import {
	FaUser,
	FaFileInvoice,
	FaBed,
	FaMoneyBill,
	FaFileExcel,
} from 'react-icons/fa'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import PropertiesContext from '../../context/PropertiesContext'
import PropertySelect from '../../components/PropertySelect'
import Spinner2 from '../../components/Spinner2'
import Chart from '../../components/Chart'

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

const tokens = JSON.parse(localStorage.getItem('tokens'))

function ReportPage() {
	const { properties } = useContext(PropertiesContext)
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty
	const { currMonth, currYear, role, admin_access } = userData

	const [year, setYear] = useState(currYear)
	const [month, setMonth] = useState(currMonth)
	const [incomes, setIncomes] = useState('')
	const [totalIncomes, setTotalIncomes] = useState(0)
	const [expenses, setExpenses] = useState('')
	const [totalExpenses, setTotalExpenses] = useState(0)
	const [chartIncomes, setChartIncomes] = useState('')
	const [chartExpenses, setChartExpenses] = useState('')
	const [chartMonths, setChartMonths] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin' && admin_access.report === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role, admin_access])

	useEffect(() => {
		if (property_id) {
			getIncomesExpenses(property_id, month, year)
		}
		//eslint-disable-next-line
	}, [property_id, month, year])

	//get all Reports from API
	const getIncomesExpenses = async (property_id, month, year) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/report/sales_expenses?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}&month=${month}&year=${year}`

			const config = configData()

			const res = await axios.get(url, config)

			setIncomes(res.data.data.incomes)
			setChartIncomes(res.data.data.chart_incomes)
			setChartExpenses(res.data.data.chart_expenses)
			setExpenses(res.data.data.expenses)
			setTotalIncomes(res.data.data.incomes_total)
			setTotalExpenses(res.data.data.expenses_total)
			setChartMonths(res.data.data.chart_months)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const onChangeYear = (e) => {
		setYear(e.target.value)
	}

	const onChangeMonth = (e) => {
		setMonth(e.target.value)
	}

	const yearText = ['2022', '2023']

	const monthText = [
		'Januari',
		'Februari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember',
	]

	return (
		<>
			<h2 className="pageTitle">Laporan {property_name}</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{properties.length > 1 && <PropertySelect />}
			<Chart
				title={`Omzet vs Biaya ${year}`}
				dataA={chartIncomes}
				dataB={chartExpenses}
				chartLabels={chartMonths}
			/>
			<h3>
				Omzet & Biaya {monthText[month - 1]} {year}
			</h3>
			<div className="inputGroup2cols">
				<div className="inputGroup">
					<select
						value={year}
						onChange={onChangeYear}
						className="select"
						required
					>
						<option value="">Pilih Tahun</option>
						{yearText.map((text, index) => (
							<option key={index} value={text}>
								{text}
							</option>
						))}
					</select>
				</div>
				<div className="inputGroup">
					<select
						value={month}
						onChange={onChangeMonth}
						className="select"
						required
					>
						<option value="">Pilih Bulan</option>
						{monthText.map((text, index) => (
							<option key={index} value={index + 1}>
								{text}
							</option>
						))}
					</select>
				</div>
			</div>
			{property_id && month && year && tokens && tokens.length > 0 && (
				<div style={{ marginBottom: '18px' }}>
					<a
						href={`${process.env.REACT_APP_APIURL}v1/excel_export/excel_profitlost?user_id=${tokens[2]}&token=${tokens[0]}&refresh_token=${tokens[1]}&property_id=${property_id}&month=${month}&year=${year}`}
						rel="noreferrer"
						className="btn btn-small btn-success"
						style={{ padding: '5px' }}
					>
						<FaFileExcel /> Ekspor ke Excel
					</a>
				</div>
			)}
			<Accordion allowZeroExpanded>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<span style={{ display: 'inline-block' }}>
								<strong>Omzet</strong>
							</span>
							<span style={{ float: 'right', display: 'inline-block' }}>
								<strong>{formatter.format(totalIncomes)}</strong>
							</span>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<table
							border="1"
							style={{
								tableLayout: 'fixed',
								width: '100%',
								marginBottom: '20px',
							}}
						>
							<tbody>
								<tr>
									<th>Tanggal</th>
									<th>Sudah Bayar</th>
								</tr>
								{incomes &&
									incomes.map((income) => (
										<tr key={income.id}>
											<td>
												{moment(income.paid_at).format('DD MMM YYYY')}
												<br />
												<a
													target="_blank"
													rel="noreferrer"
													href={`${process.env.REACT_APP_APIURL}uploads/payments/${income.receipt_file}`}
												>
													<span
														style={{ fontFamily: 'arial', fontSize: '0.75rem' }}
													>
														<FaMoneyBill /> {income.receipt_number}
													</span>
												</a>

												<br />
												<a
													target="_blank"
													rel="noreferrer"
													href={`${process.env.REACT_APP_APIURL}uploads/invoices/${income.invoice_file}`}
												>
													<span
														style={{ fontFamily: 'arial', fontSize: '0.75rem' }}
													>
														<FaFileInvoice /> {income.invoice_number}
													</span>
												</a>
											</td>
											<td>
												{formatter.format(income.amount)}
												<br />
												<span style={{ fontSize: '0.75rem' }}>
													<FaUser /> {income.name}
												</span>
												<br />
												<span style={{ fontSize: '0.75rem' }}>
													<FaBed /> {income.room_name}
												</span>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<span style={{ display: 'inline-block' }}>
								<strong>Biaya</strong>
							</span>
							<span style={{ float: 'right', display: 'inline-block' }}>
								<strong>{formatter.format(totalExpenses)}</strong>
							</span>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<table
							border="1"
							style={{
								tableLayout: 'fixed',
								width: '100%',
								marginBottom: '20px',
							}}
						>
							<tbody>
								<tr>
									<th>Tanggal</th>
									<th>Keterangan</th>
								</tr>
								{expenses &&
									expenses.map((expense) => (
										<tr key={expense.id}>
											<td>{moment(expense.date).format('DD MMM YYYY')}</td>
											<td>
												{formatter.format(expense.amount)}
												<br />
												<span style={{ fontSize: '0.8rem' }}>
													{expense.expense_name}
												</span>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</AccordionItemPanel>
				</AccordionItem>
			</Accordion>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					fontSize: '1rem',
					marginBottom: '1rem',
					borderTop: '1px solid white',
					paddingTop: '10px',
				}}
			>
				<div>
					<strong>Laba/Rugi</strong>
				</div>
				<div>
					<strong>{formatter.format(totalIncomes - totalExpenses)}</strong>
				</div>
			</div>
		</>
	)
}

export default ReportPage
