import { Link } from 'react-router-dom'

import Card from '../components/shared/Card'

function AboutPage() {
	return (
		<Card>
			<div className="about">
				<h2 className="pageTitle">About This Project</h2>
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad tenetur
					odit autem commodi natus aperiam doloremque praesentium officiis rem
					eius necessitatibus odio at explicabo.
				</p>
				<p>
					<Link to="/">Back To Home</Link>
				</p>
			</div>
		</Card>
	)
}

export default AboutPage
