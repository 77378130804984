import { useState } from 'react'
import PropTypes from 'prop-types'
import {
	FaClock,
	FaEye,
	FaDollarSign,
	FaCashRegister,
	FaMoneyBill,
	FaStickyNote,
	FaTimes,
} from 'react-icons/fa'
import moment from 'moment'
import Modal from 'react-modal'
import QRCode from 'react-qr-code'

import Spinner2 from '../../components/Spinner2'
import Card from '../../components/shared/Card'

const customStyles = {
	content: {
		bottom: 'auto',
		background: '#672f0a',
		borderRadius: '10px',
		inset: '20px 20px auto',
		zIndex: 1,
		maxWidth: '600px',
		maxHeight: '95%',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

function BillingItem({ item, getAllBillings }) {
	const [modal1IsOpen, set1IsOpen] = useState(false)
	const [modal2IsOpen, set2IsOpen] = useState(false)

	function openModal1() {
		set1IsOpen(true)
	}

	function closeModal1() {
		set1IsOpen(false)
	}

	function openModal2() {
		set2IsOpen(true)
	}

	function closeModal2() {
		set2IsOpen(false)
	}

	let paymentBadge
	let paymentText
	switch (item.status) {
		case 'not paid':
			paymentBadge = <span className="badge badge-danger">Belum Bayar</span>
			paymentText = 'Belum Bayar'
			break
		case 'partial paid':
			paymentBadge = (
				<span className="badge badge-warning">Cicil. Belum Lunas</span>
			)
			paymentText = 'Cicil. Belum Lunas'
			break
		case 'fully paid':
			paymentBadge = <span className="badge badge-success">Lunas</span>
			paymentText = 'Lunas'
			break
		case 'cancel':
			paymentBadge = <span className="badge badge-inactive">Batal</span>
			paymentText = 'Batal'
			break

		default:
			break
	}

	const dueDate = new Date(item.due_date)
	let currDate = new Date()
	let passDueText = ''

	if (
		dueDate < currDate &&
		item.status !== 'paid' &&
		item.status !== 'cancel'
	) {
		passDueText = (
			<>
				<br />
				<span style={{ color: 'red', fontSize: '0.8rem' }}>
					<strong>Sudah Jatuh Tempo</strong>
				</span>
			</>
		)
	}

	return (
		<Card reverse={false}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>{paymentBadge}</div>
				{item.status !== 'paid' && item.status !== 'cancel' && (
					<button onClick={openModal1} className="btn btn-success btn-small">
						<FaCashRegister /> Bayar
					</button>
				)}
				<Modal
					isOpen={modal1IsOpen}
					onRequestClose={closeModal1}
					style={customStyles}
					ariaHideApp={false}
				>
					<h3 style={{ marginTop: 0 }}>Pembayaran</h3>
					<table
						border="1"
						style={{
							tableLayout: 'fixed',
							width: '100%',
						}}
					>
						<tbody>
							<tr>
								<td>Nomor Billing</td>
								<td style={{ fontFamily: 'arial', fontSize: '0.8rem' }}>
									{item.billing_number}
								</td>
							</tr>
							<tr>
								<td>Periode Langganan</td>
								<td>
									{moment(item.subscription_start).format('DD MMM YYYY')} -
									<br />
									{moment(item.subscription_end).format('DD MMM YYYY')}
								</td>
							</tr>
							<tr>
								<td>Jatuh Tempo</td>
								<td>{moment(item.due_date).format('DD MMM YYYY')}</td>
							</tr>
							{item.billing_properties &&
								item.billing_properties.length > 0 &&
								item.billing_properties.map((item) => (
									<tr key={item.id}>
										<td>{item.property_name}</td>
										<td>
											{item.total_rooms} Kamar
											<br />
											{formatter.format(item.prices)}
										</td>
									</tr>
								))}
							<tr>
								<td>Total Harga</td>
								<td>{formatter.format(item.total_price)}</td>
							</tr>
							{item.discount_percentage > 0 && (
								<tr>
									<td>Diskon ({item.discount_percentage}%)</td>
									<td>
										{formatter.format(
											(item.discount_percentage / 100) * item.total_price
										)}
									</td>
								</tr>
							)}
							<tr>
								<td>
									<strong>Total Bayar</strong>
								</td>
								<td>
									<strong>{formatter.format(item.final_price)}</strong>
								</td>
							</tr>
						</tbody>
					</table>
					<br />
					<p>Lakukan pembayaran QRIS, dengan cara scan barcode dibawah ini.</p>
					<div id="qrcodeContainer">
						<QRCode value="00020101021226680016ID.CO.TELKOM.WWW011893600898025599662702150001952559966270303UMI51440014ID.CO.QRIS.WWW0215ID10200211817450303UMI520457325303360540825578.005502015802ID5916InterActive Corp6013KOTA SURABAYA61056013662130509413255111630439B7" />
						<div>
							<img
								src={process.env.PUBLIC_URL + '/qris-logo.jpg'}
								alt="QRIS"
								style={{ width: '270px', maxWidth: '100%', marginTop: '10px' }}
							/>
						</div>
					</div>
					<button className="btn btn-small btn-cancel" onClick={closeModal1}>
						<FaTimes /> Batal
					</button>
				</Modal>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className="text-display">
					<strong>{formatter.format(item.total_price)}</strong>
					<br />
					<span style={{ fontSize: '0.8rem' }}>
						{moment(item.subscription_start).format('DD MMM YYYY')} -{' '}
						{moment(item.subscription_end).format('DD MMM YYYY')}
					</span>
					<br />
					<a
						target="_blank"
						rel="noreferrer"
						href={`${process.env.REACT_APP_APIURL}uploads/billings/${item.billing_file}`}
					>
						<FaEye />{' '}
						<strong style={{ fontFamily: 'arial', fontSize: '0.8rem' }}>
							{item.billing_number}
						</strong>
					</a>
				</div>
				<div className="text-display" style={{ textAlign: 'right' }}>
					<FaClock />{' '}
					<strong>{moment(item.due_date).format('DD MMM YYYY')}</strong>
					{passDueText}
					{item.status === 'paid' && (
						<>
							<br />
							<button
								className="btn btn-xsmall btn-secondary"
								onClick={openModal2}
							>
								<FaDollarSign /> Daftar Pembayaran
							</button>
						</>
					)}
				</div>
			</div>
			<Modal
				isOpen={modal2IsOpen}
				onRequestClose={closeModal2}
				style={customStyles}
				ariaHideApp={false}
			>
				<h3 style={{ marginTop: 0 }}>Daftar Pembayaran</h3>

				<table
					style={{
						tableLayout: 'fixed',
						width: '100%',
						marginBottom: '40px',
					}}
				>
					<tbody>
						<tr>
							<td>Billing</td>
							<td style={{ fontFamily: 'arial', fontSize: '0.8rem' }}>
								{item.billing_number}
							</td>
						</tr>
						<tr>
							<td>Jatuh Tempo</td>
							<td>{moment(item.due_date).format('DD MMM YYYY')}</td>
						</tr>
						<tr>
							<td>Total Bayar</td>
							<td>{formatter.format(item.total_price)}</td>
						</tr>
						<tr>
							<td>Status</td>
							<td>{paymentText}</td>
						</tr>
					</tbody>
				</table>
				<table
					border="1"
					style={{
						tableLayout: 'fixed',
						width: '100%',
					}}
				>
					<tbody>
						<tr>
							<th>
								<strong>Tanggal</strong>
							</th>
							<th>
								<strong>Jumlah Bayar</strong>
							</th>
						</tr>
						{item.payments &&
							item.payments.map((payment) => (
								<tr key={payment.id}>
									<td>{moment(payment.paid_at).format('DD MMM YYYY')}</td>
									<td style={{ fontSize: '0.9rem' }}>
										{formatter.format(payment.amount)}
										{payment.payment_proof && (
											<>
												<br />
												<a
													target="_blank"
													rel="noreferrer"
													href={`${process.env.REACT_APP_APIURL}uploads/payments/${payment.payment_proof}`}
													style={{
														display: 'inline-block',
														marginTop: '5px',
													}}
												>
													<FaMoneyBill /> Bukti Bayar
												</a>
											</>
										)}
										<br />
										<a
											target="_blank"
											rel="noreferrer"
											href={`${process.env.REACT_APP_APIURL}uploads/payments/${payment.receipt_file}`}
											style={{
												fontFamily: 'arial',
												display: 'inline-block',
												marginTop: '5px',
												marginBottom: '5px',
											}}
										>
											{payment.receipt_number}
										</a>
										<br />
										{payment.payment_method}
										{payment.note && (
											<>
												<br />
												<FaStickyNote /> {payment.note}
											</>
										)}
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<br />
				<div className="spinnerBlock" style={{ marginBottom: '10px' }}>
					<Spinner2 />
				</div>
				<button className="btn btn-small btn-secondary" onClick={closeModal2}>
					Tutup
				</button>
			</Modal>
		</Card>
	)
}

BillingItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default BillingItem
