import { Route, Routes } from 'react-router-dom'

import ServicesList from './ServicesList'
import ServicesForm from './ServicesForm'

function ServicesPage() {
	return (
		<Routes>
			<Route path="/" element={<ServicesList />} />
			<Route path="/edit" element={<ServicesForm />} />
			<Route path="/edit/:id" element={<ServicesForm />} />
			<Route
				path="*"
				element={
					<>
						<h1>404</h1>
						<p>Upps halaman tidak ditemukan.</p>
					</>
				}
			/>
		</Routes>
	)
}

export default ServicesPage
