import { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Button from '../components/shared/Button'
import AuthContext from '../context/AuthContext'
import Spinner from '../components/Spinner'
import DownloadApp from '../components/DownloadApp'

function RegisterPage() {
	const { registerUser, userData, toOtpPage, isLoading } =
		useContext(AuthContext)
	const { isAuthenticated, user_id } = userData

	const [formData, setFormData] = useState({
		name: '',
		handphone: '',
		property: '',
		address: '',
		city: '',
		tocChecked: false,
	})
	const { name, handphone, property, address, city, tocChecked } = formData

	const navigate = useNavigate()

	useEffect(() => {
		if (toOtpPage) {
			navigate('/otp')
		}
	})

	useEffect(() => {
		if (isAuthenticated && user_id) {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [isAuthenticated, user_id])

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!name || !handphone || !property || !address || !city) {
			toast.error('Mohon melengkapi semua isian.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const twoCharsHp = handphone.substring(0, 2)

		if (twoCharsHp !== '08') {
			toast.error('Nomor Hp harus dimulai dengan 08.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const newUser = {
			name,
			handphone,
			property,
			address,
			city,
		}

		registerUser(newUser)
	}

	const handleCheckbox = () => {
		setFormData((prevState) => ({
			...prevState,
			tocChecked: !tocChecked,
		}))
	}

	return (
		<>
			<DownloadApp />
			<img
				className="fluidImage"
				src={process.env.PUBLIC_URL + '/bedroom-banner.jpg'}
				alt="Bedroom Banner"
			/>
			<h2 className="pageTitle">Daftar</h2>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Anda</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Nama Anda"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Handphone (WA)</b>
						</label>
					</div>
					<input
						type="number"
						className="textInput"
						placeholder="Nomor WA 08XXXXXXXXXX"
						id="handphone"
						value={handphone}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Kos</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Nama kos"
						id="property"
						value={property}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Alamat Kos</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Alamat lengkap"
						id="address"
						value={address}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Kota</b>
						</label>
					</div>
					<select
						id="city"
						value={city}
						onChange={onChange}
						className="select"
						required
					>
						<option value="">Pilih Kota</option>
						<option value="Jakarta">Jakarta</option>
						<option value="Bogor">Bogor</option>
						<option value="Depok">Depok</option>
						<option value="Tangerang">Tangerang</option>
						<option value="Bekasi">Bekasi</option>
						<option value="Malang">Malang</option>
						<option value="Surabaya">Surabaya</option>
						<option value="Yogyakarta">Yogyakarta</option>
						<option value="Lainnya">Lainnya</option>
					</select>
				</div>
				<div className="inputGroup">
					<input
						type="checkbox"
						checked={tocChecked}
						onChange={handleCheckbox}
						style={{
							height: '20px',
							width: '20px',
							position: 'relative',
							top: '5px',
						}}
					/>{' '}
					Saya setuju dengan{' '}
					<a
						href="https://www.adminkos.com/page/syarat-dan-ketentuan"
						target="_blank"
						rel="noreferrer"
					>
						Syarat & Ketentuan
					</a>{' '}
					dan{' '}
					<a
						href="https://www.adminkos.com/page/kebijakan-privasi"
						target="_blank"
						rel="noreferrer"
					>
						Kebijakan Privasi
					</a>
				</div>
				<Button
					type="submit"
					version="block"
					isDisabled={isLoading || !tocChecked}
				>
					{isLoading ? <Spinner /> : 'Daftar'}
				</Button>
			</form>
			<p className="registerLink">
				<Link to="/login">
					Sudah punya akun?{' '}
					<span style={{ textDecoration: 'underline' }}>MASUK</span>
				</Link>
			</p>
		</>
	)
}

export default RegisterPage
