import { useContext, useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom'

import RoomsItem from './RoomsItem'
import PropertiesContext from '../../context/PropertiesContext'
import AuthContext from '../../context/AuthContext'
import Button from '../../components/shared/Button'
import Spinner2 from '../../components/Spinner2'
import PropertySelect from '../../components/PropertySelect'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function RoomsList() {
	const { properties } = useContext(PropertiesContext)
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty
	const { role, admin_access } = userData

	const [isLoading, setIsLoading] = useState(false)
	const [rooms, setRooms] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin' && admin_access.rooms === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role, admin_access])

	useEffect(() => {
		if (property_id) {
			getAllRooms(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all Rooms from API
	const getAllRooms = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setRooms(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//duplicate room item
	const duplicateRoom = async (property_id, id) => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		setIsLoading(true)

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/duplicate?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('room_id', id)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				getAllRooms(property_id)

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//delete room item
	const deleteRoom = async (property_id, id) => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		confirmAlert({
			title: 'Yakin mau hapus?',
			message: 'Data kamar ini akan terhapus selamanya.',
			buttons: [
				{
					label: 'Batal',
					onClick: () => null,
				},
				{
					label: 'Hapus',
					onClick: async () => {
						setIsLoading(true)

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/room/delete?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

							const config = configData()

							let formData = new FormData()
							formData.append('property_id', property_id)
							formData.append('room_id', id)

							const res = await axios.post(url, formData, config)

							if (res.status === 200) {
								getAllRooms(property_id)

								toast.success(res.data.message, {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	let roomInfo

	if (rooms && rooms.length === 0) {
		roomInfo = <p>Belum ada kamar. Silahkan tambah baru.</p>
	} else if (rooms && rooms.length > 0) {
		roomInfo = (
			<div className="feedback-list">
				{rooms.map((item) => (
					<RoomsItem
						key={item.id}
						item={item}
						deleteRoom={deleteRoom}
						duplicateRoom={duplicateRoom}
					/>
				))}
			</div>
		)
	}

	let addRoomButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.rooms_add === 'yes')
	) {
		addRoomButton = (
			<Link to="/rooms/edit">
				<Button type="button" version="block" isDisabled={false}>
					<FaPlus /> Tambah Kamar
				</Button>
			</Link>
		)
	}

	return (
		<>
			<h2 className="pageTitle">Kamar</h2>
			<p>Buat kamar sebelum mengisi penyewa.</p>
			{properties.length > 1 && <PropertySelect />}
			{addRoomButton}
			<h3>Kamar {property_name}</h3>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{roomInfo}
		</>
	)
}

export default RoomsList
