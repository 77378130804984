import { Route, Routes } from 'react-router-dom'

import PaymentMethodsList from '../PaymentMethods/PaymentMethodsList'
import PaymentMethodsForm from './PaymentMethodsForm'

function PaymentMethodsPage() {
	return (
		<Routes>
			<Route path="/" element={<PaymentMethodsList />} />
			<Route path="/edit" element={<PaymentMethodsForm />} />
			<Route path="/edit/:id" element={<PaymentMethodsForm />} />
			<Route
				path="*"
				element={
					<>
						<h1>404</h1>
						<p>Upps halaman tidak ditemukan.</p>
					</>
				}
			/>
		</Routes>
	)
}

export default PaymentMethodsPage
