import { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Button from '../components/shared/Button'
import AuthContext from '../context/AuthContext'
import Spinner from '../components/Spinner'
import DownloadApp from '../components/DownloadApp'

function LoginPage() {
	const { loginUser, userData, lastPage, toOtpPage, isLoading } =
		useContext(AuthContext)
	const { isAuthenticated, user_id } = userData

	const [formData, setFormData] = useState({
		handphone: '',
	})
	const { handphone } = formData

	const navigate = useNavigate()

	useEffect(() => {
		if (toOtpPage) {
			navigate('/otp')
		}
	})

	useEffect(() => {
		if (isAuthenticated && user_id) {
			navigate(lastPage)
		}
		//eslint-disable-next-line
	}, [isAuthenticated, user_id])

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!handphone) {
			toast.error('Handphone tidak boleh kosong.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const twoCharsHp = handphone.substring(0, 2)

		if (twoCharsHp !== '08') {
			toast.error('Nomor Hp harus dimulai dengan 08.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		loginUser(handphone)
	}

	return (
		<>
			<DownloadApp />
			<img
				className="fluidImage"
				src={process.env.PUBLIC_URL + '/bedroom-banner.jpg'}
				alt="Bedroom Banner"
			/>
			<h2 className="pageTitle">Masuk</h2>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Handphone (WA)</b>
						</label>
					</div>
					<input
						type="number"
						className="textInput"
						placeholder="Nomor WA 08XXXXXXXXXX"
						id="handphone"
						value={handphone}
						onChange={onChange}
						required
					/>
				</div>
				<Button type="submit" version="block" isDisabled={isLoading}>
					{isLoading ? <Spinner /> : 'Masuk'}
				</Button>
			</form>
			<p className="registerLink">
				<Link to="/register">
					Belum punya akun?{' '}
					<span style={{ textDecoration: 'underline' }}>DAFTAR</span>
				</Link>
			</p>
		</>
	)
}

export default LoginPage
