import ClipLoader from 'react-spinners/ClipLoader'

function Spinner() {
	return (
		<div style={{ textAlign: 'center' }}>
			<ClipLoader
				color={`#fff`}
				loading={true}
				size={35}
				aria-label="Loading"
				data-testid="loader"
			/>
		</div>
	)
}

export default Spinner
