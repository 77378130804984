import { useContext, useEffect, useState } from 'react'
import { FaPlus, FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom'

import PaymentMethodesItem from './PaymentMethodsItem'
import PropertiesContext from '../../context/PropertiesContext'
import AuthContext from '../../context/AuthContext'
import Button from '../../components/shared/Button'
import Spinner2 from '../../components/Spinner2'
import PropertySelect from '../../components/PropertySelect'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function PaymentMethodsList() {
	const { properties } = useContext(PropertiesContext)
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty
	const { role } = userData

	const [isLoading, setIsLoading] = useState(false)
	const [paymentMethods, setPaymentmethods] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role])

	useEffect(() => {
		if (property_id) {
			getAllPaymentMethods(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all Payment Methods from API
	const getAllPaymentMethods = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/payment_method/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setPaymentmethods(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//delete payment method item
	const deletePaymentMethod = async (property_id, id) => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		confirmAlert({
			title: 'Yakin mau hapus?',
			message: 'Data metode pembayaran ini akan terhapus selamanya.',
			buttons: [
				{
					label: 'Batal',
					onClick: () => null,
				},
				{
					label: 'Hapus',
					onClick: async () => {
						setIsLoading(true)

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/payment_method/delete?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

							const config = configData()

							let formData = new FormData()
							formData.append('property_id', property_id)
							formData.append('payment_method_id', id)

							const res = await axios.post(url, formData, config)

							if (res.status === 200) {
								getAllPaymentMethods(property_id)

								toast.success('Sukses menghapus metode pembayaran.', {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	let paymentMethodInfo

	if (paymentMethods && paymentMethods.length === 0) {
		paymentMethodInfo = (
			<p>Belum ada metode pembayaran. Silahkan tambah baru.</p>
		)
	} else if (paymentMethods && paymentMethods.length > 0) {
		paymentMethodInfo = (
			<div className="feedback-list">
				{paymentMethods.map((item) => (
					<PaymentMethodesItem
						key={item.id}
						item={item}
						deletePaymentMethod={deletePaymentMethod}
					/>
				))}
			</div>
		)
	}

	return (
		<>
			<Link to="/setting">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">Metode Pembayaran</h2>
			<p>Penyewa akan membayar dengan pilihan pembayaran di dalam tagihan.</p>
			{properties.length > 1 && <PropertySelect />}
			<Link to="/payment_methods/edit">
				<Button type="button" version="block" isDisabled={false}>
					<FaPlus /> Tambah Metode Pembayaran
				</Button>
			</Link>
			<h3>Metode Pembayaran {property_name}</h3>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{paymentMethodInfo}
		</>
	)
}

export default PaymentMethodsList
