import { useContext, useEffect } from 'react'
// import { Outlet, useLocation } from 'react-router-dom'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { FaCalendar, FaCog } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import NavBar from './NavBar'
import AuthContext from '../context/AuthContext'
import PropertiesContext from '../context/PropertiesContext'
import Spinner2 from './Spinner2'

function ProtectedRoute() {
	const { properties, getAllProperties } = useContext(PropertiesContext)
	const {
		userData,
		setLastPage,
		getProfile,
		activeProperty,
		setActiveProperty,
	} = useContext(AuthContext)
	const { isAuthenticated, user_id, name, date } = userData
	const { property_id, property_name } = activeProperty

	const location = useLocation()

	const tokens = JSON.parse(localStorage.getItem('tokens'))

	useEffect(() => {
		setLastPage(location.pathname)
		//eslint-disable-next-line
	}, [location])

	useEffect(() => {
		if (user_id) {
			getProfile()
		}
		//eslint-disable-next-line
	}, [user_id, date])

	useEffect(() => {
		if (user_id) {
			getAllProperties()
		}
		//eslint-disable-next-line
	}, [user_id])

	useEffect(() => {
		if (properties.length > 0) {
			if (!property_id || !property_name) {
				setActiveProperty({
					property_id: properties[0].id,
					property_name: properties[0].name,
				})
			}
		}
		//eslint-disable-next-line
	}, [properties])

	if (user_id && isAuthenticated) {
		return (
			<>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: '',
					}}
				>
					<p style={{ marginBottom: '0.5rem' }}>
						<FaCalendar /> {date}
					</p>
					<p
						style={{
							marginBottom: '0.5rem',
							textTransform: 'capitalize',
							textAlign: 'right',
						}}
					>
						<Link to="/setting" style={{ color: 'white', fontSize: '1rem' }}>
							<FaCog /> {name}
						</Link>
					</p>
				</div>
				<Outlet />
				<NavBar />
			</>
		)
	} else {
		if (tokens && user_id) {
			return (
				<>
					<div style={{ textAlign: 'center' }}>
						<img
							src={process.env.PUBLIC_URL + '/logo192.png'}
							alt="Admin Kos"
						/>
					</div>
					<div className="spinnerBlock" style={{ textAlign: 'center' }}>
						<Spinner2 />
					</div>
				</>
			)
		} else {
			return <Navigate to="/login" replace />
		}
	}
}

export default ProtectedRoute
