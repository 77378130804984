import { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft, FaQuestionCircle } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Switch from 'react-switch'

import Button from '../../components/shared/Button'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function RoomsForm() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	const [room, setRoom] = useState({
		name: '',
		description: '',
		price_monthly: '',
		price_weekly: '',
		price_quarterly: '',
		deposit: '',
		penalty: '',
		status: '',
	})
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		price_monthly: '',
		price_weekly: '',
		price_quarterly: '',
		deposit: '',
		status: 'active',
		multi_rooms: false,
		room_qty: 1,
	})
	const {
		name,
		description,
		price_monthly,
		price_weekly,
		price_quarterly,
		deposit,
		penalty,
		status,
		multi_rooms,
		room_qty,
	} = formData
	const [isLoading, setIsLoading] = useState(false)

	let { id } = useParams()

	const navigate = useNavigate()

	useEffect(() => {
		if (!id && role === 'admin' && admin_access.rooms_add === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [id, role, admin_access])

	useEffect(() => {
		if (id && property_id) {
			getRoom(property_id, id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	useEffect(() => {
		if (id) {
			setFormData({
				name: room.name,
				description: room.description,
				price_monthly: room.price_monthly
					? addCommas(removeNonNumeric(room.price_monthly))
					: '',
				price_weekly: room.price_weekly
					? addCommas(removeNonNumeric(room.price_weekly))
					: '',
				price_quarterly: room.price_quarterly
					? addCommas(removeNonNumeric(room.price_quarterly))
					: '',
				deposit: room.deposit ? addCommas(removeNonNumeric(room.deposit)) : '',
				penalty: room.penalty ? addCommas(removeNonNumeric(room.penalty)) : '',
				status: room.status,
			})
		}
		//eslint-disable-next-line
	}, [room])

	useEffect(() => {
		if (!multi_rooms) {
			setFormData((prevState) => ({
				...prevState,
				room_qty: 1,
			}))
		}
		//eslint-disable-next-line
	}, [multi_rooms])

	//get single room from API
	const getRoom = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/detail?user_id=${tokens[2]}&room_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setRoom({
				name: res.data.data.name,
				description: res.data.data.description,
				price_monthly: res.data.data.price_monthly,
				price_weekly: res.data.data.price_weekly,
				price_quarterly: res.data.data.price_quarterly,
				deposit: res.data.data.deposit,
				penalty: res.data.data.penalty,
				status: res.data.data.status,
			})
		} catch (err) {
			navigate('/rooms')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new room
	const addRoom = async (property_id, newroom) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('name', newroom.name)
			formData.append('description', newroom.description)
			formData.append('price_monthly', newroom.price_monthly)
			formData.append('price_weekly', newroom.price_weekly)
			formData.append('price_quarterly', newroom.price_quarterly)
			formData.append('deposit', newroom.deposit)
			formData.append('penalty', newroom.penalty)
			formData.append('status', newroom.status)
			formData.append('room_qty', newroom.room_qty)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				navigate('/rooms')

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//update room
	const updateRoom = async (property_id, id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('room_id', id)
			formData.append('name', updItem.name)
			formData.append('description', updItem.description)
			formData.append('price_monthly', updItem.price_monthly)
			formData.append('price_weekly', updItem.price_weekly)
			formData.append('price_quarterly', updItem.price_quarterly)
			formData.append('deposit', updItem.deposit)
			formData.append('penalty', updItem.penalty)
			formData.append('status', updItem.status)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const onChange = (e) => {
		let targetValue

		if (
			e.target.id === 'price_monthly' ||
			e.target.id === 'price_weekly' ||
			e.target.id === 'price_quarterly' ||
			e.target.id === 'deposit' ||
			e.target.id === 'penalty'
		) {
			targetValue = addCommas(removeNonNumeric(e.target.value))
		} else {
			targetValue = e.target.value
		}

		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: targetValue,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!price_monthly && !price_weekly && !price_quarterly) {
			toast.error(
				'Mohon mengisi harga sewa salah satu dari bulanan, mingguan, dan 3 bulanan.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		if (
			price_monthly === '0' ||
			price_weekly === '0' ||
			price_quarterly === '0' ||
			deposit === '0' ||
			penalty === '0'
		) {
			toast.error(
				'Harga harus lebih besar dari 0 untuk durasi sewa yang dipilih, atau kosongkan bila tidak dipakai.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		//dont use replaceall(), because not supported in chrome <85
		//replace(/\./g, '') means replace . with ''
		const newRoom = {
			name,
			description,
			price_monthly:
				price_monthly !== '' ? parseInt(price_monthly.replace(/\./g, '')) : '',
			price_weekly:
				price_weekly !== '' ? parseInt(price_weekly.replace(/\./g, '')) : '',
			price_quarterly:
				price_quarterly !== ''
					? parseInt(price_quarterly.replace(/\./g, ''))
					: '',
			deposit: deposit !== '' ? parseInt(deposit.replace(/\./g, '')) : '',
			status,
			penalty: penalty !== '' ? parseInt(penalty) : '',
			room_qty,
		}

		if (id && property_id) {
			updateRoom(property_id, id, newRoom)
		} else {
			addRoom(property_id, newRoom)
		}
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Ubah'
	} else if (!isLoading && !id) {
		buttonState = 'Tambah'
	}

	let updateRoomButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.rooms_edit === 'yes')
	) {
		updateRoomButton = (
			<Button type="submit" version="block" isDisabled={isLoading}>
				{buttonState}
			</Button>
		)
	}

	const onChangeSwitch = (isChecked, event, id) => {
		switch (id) {
			//tenants
			case 'multi_rooms':
				setFormData((prevState) => ({
					...prevState,
					multi_rooms: !multi_rooms,
				}))
				break
			default:
				break
		}
	}

	return (
		<>
			<Link to="/rooms">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">{id ? 'Edit' : 'Tambah'} Kamar</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nomor / Nama Kamar</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Contoh: 1, A1, Bunga2"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
				</div>
				{!id && (
					<div className="inputGroup2cols">
						<div className="inputGroup">
							<div
								className="inputLabel"
								data-tooltip-id="q-sendwa"
								data-tooltip-content="Sistem akan membuat beberapa kamar sekaligus dengan menambahkan iterasi angka kamar."
							>
								<label>
									<b>Isi banyak kamar</b> <FaQuestionCircle />
								</label>
								<Tooltip id="q-sendwa" style={{ maxWidth: '70%' }} />
							</div>
							<Switch
								id="multi_rooms"
								width={70}
								onChange={onChangeSwitch}
								checked={multi_rooms}
							/>
						</div>
						{multi_rooms && (
							<div className="inputGroup">
								<div className="inputLabel">
									<label>
										<b>Jumlah Kamar</b>
									</label>
								</div>
								<input
									type="number"
									className="textInput"
									placeholder="Isi jumlah kamar"
									id="room_qty"
									value={room_qty}
									onChange={onChange}
									required
								/>
							</div>
						)}
					</div>
				)}
				<div className="inputLabel">
					<label>
						<b>Harga Sewa (Rp).</b> Isi minimal 1 harga.
					</label>
				</div>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Sewa / Bulan</b>
							</label>
						</div>
						<NumericFormat
							value={price_monthly}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga per bulan"
							id="price_monthly"
							onChange={onChange}
						/>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Sewa / Minggu</b>
							</label>
						</div>
						<NumericFormat
							value={price_weekly}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga per minggu"
							id="price_weekly"
							onChange={onChange}
						/>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Sewa / 3 Bulan</b>
							</label>
						</div>
						<NumericFormat
							value={price_quarterly}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga per 3 bulan"
							id="price_quarterly"
							onChange={onChange}
						/>
					</div>
					{/* <div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-deposit"
							data-tooltip-content="Deposit adalah uang jaminan yang akan dikembalikan di akhir sewa."
						>
							<label style={{ cursor: 'pointer' }}>
								<b>Deposit</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-deposit" style={{ maxWidth: '70%' }} />
						</div>
						<NumericFormat
							value={deposit}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Deposit (opsional)"
							id="deposit"
							onChange={onChange}
						/>
					</div> */}
					{/* <div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-penalty"
							data-tooltip-content="Denda keterlambatan pembayaran (%) dari total harga sewa dan layanan."
						>
							<label style={{ cursor: 'pointer' }}>
								<b>Denda (%)</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-penalty" style={{ maxWidth: '70%' }} />
						</div>
						<NumericFormat
							value={penalty}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Denda (opsional)"
							id="penalty"
							onChange={onChange}
						/>
					</div> */}
					<div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-deposit"
							data-tooltip-content="Kamar tersedia atau tidak. Contohnya revonasi sehingga kamar tidak tersedia."
						>
							<label style={{ cursor: 'pointer' }}>
								<b>Tersedia?</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-deposit" style={{ maxWidth: '70%' }} />
						</div>
						<select
							id="status"
							value={status}
							onChange={onChange}
							className="select"
							required
						>
							<option value="">Pilih ketersediaan</option>
							<option value="active">Tersedia</option>
							<option value="inactive">Tidak tersedia</option>
						</select>
					</div>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Deskripsi & Catatan (opsional)</b>
						</label>
					</div>
					<textarea
						className="textarea"
						placeholder="Contoh: AC, kamar mandi dalam, dekat dapur, ukuran 3x3"
						id="description"
						value={description}
						onChange={onChange}
					/>
				</div>
				{updateRoomButton}
			</form>
			<br />
			<Link to="/rooms">
				<FaChevronLeft /> Kembali
			</Link>
		</>
	)
}

export default RoomsForm
