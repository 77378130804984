import { useContext, useEffect, useState } from 'react'
import { FaPlus, FaHistory } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

import TenantsItem from './TenantsItem'
import PropertiesContext from '../../context/PropertiesContext'
import AuthContext from '../../context/AuthContext'
import Button from '../../components/shared/Button'
import Spinner2 from '../../components/Spinner2'
import PropertySelect from '../../components/PropertySelect'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function TenantsList() {
	const { properties } = useContext(PropertiesContext)
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty
	const { role, admin_access } = userData

	const [isLoading, setIsLoading] = useState(false)
	const [addTenantDisable, setAddTenantDisable] = useState(false)
	const [tenants, setTenants] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin' && admin_access.tenants === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role, admin_access])

	useEffect(() => {
		if (property_id) {
			getAllTenants(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all tenants from API
	const getAllTenants = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setTenants(res.data.data.tenants)

			if (res.data.data.rooms_count > 0) {
				setAddTenantDisable(false)
			} else {
				setAddTenantDisable(true)
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	let tenantInfo

	if (tenants && tenants.length === 0) {
		if (!addTenantDisable) {
			tenantInfo = <p>Belum ada penyewa. Silahkan tambah baru.</p>
		}
	} else if (tenants && tenants.length > 0) {
		tenantInfo = (
			<div className="feedback-list">
				{tenants.map((item) => (
					<TenantsItem
						key={item.id}
						item={item}
						getAllTenants={getAllTenants}
					/>
				))}
			</div>
		)
	}

	let addTenantButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.tenants_add === 'yes')
	) {
		addTenantButton = (
			<Link to="/tenants/edit">
				<Button type="button" version="block" isDisabled={addTenantDisable}>
					<FaPlus /> Tambah Penyewa
				</Button>
			</Link>
		)
	}

	return (
		<>
			<h2 className="pageTitle">Penyewa</h2>
			<p>Daftar penyewa kamar di kos Anda.</p>
			{properties.length > 1 && <PropertySelect />}
			{addTenantDisable && (
				<p>
					Belum ada kamar. Silahkan <Link to="/rooms">tambah kamar</Link>
				</p>
			)}
			{addTenantButton}
			<div className="flex-2cols">
				<h3>{property_name}</h3>
				<Link to="/tenants/past">
					<h4
						style={{
							fontSize: '0.9rem',
							position: 'relative',
							bottom: '4px',
						}}
					>
						<FaHistory /> Penyewa Lama
					</h4>
				</Link>
			</div>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{tenantInfo}
		</>
	)
}

export default TenantsList
