import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from './context/AuthContext'
import Header from './components/Header'
import AboutPage from './pages/AboutPage'
import RegisterPage from './pages/RegisterPage'
import LoginPage from './pages/LoginPage'
import HomePage from './pages/Home/HomePage'
import TenantsPage from './pages/Tenants/TenantsPage'
import RoomsPage from './pages/Rooms/RoomsPage'
import ReportPage from './pages/Reports/ReportPage'
import PropertiesPage from './pages/Properties/PropertiesPage'
import AdminAccessPage from './pages/AdminAccess/AdminAccessPage'
import ServicesPage from './pages/Services/ServicesPage'
import ExpensesPage from './pages/Expenses/ExpensesPage'
import PaymentMethodsPage from './pages/PaymentMethods/PaymentMethodsPage'
import SettingPage from './pages/Setting/SettingPage'
import ProtectedRoute from './components/ProtectedRoute'
import OtpPage from './pages/OtpPage'
import LogoutPage from './pages/LogoutPage'
import DeleteUserPage from './pages/DeleteUserPage'
import InvoicesPage from './pages/Invoices/InvoicesPage'
import BillingPage from './pages/Billing/BillingPage'
import { PropertiesProvider } from './context/PropertiesContext'

function App() {
	return (
		<Router>
			<AuthProvider>
				<PropertiesProvider>
					<Header text="AdminKos" />
					<div className="container">
						<Routes>
							<Route path="/register" element={<RegisterPage />} />
							<Route path="/login" element={<LoginPage />} />
							<Route path="/otp" element={<OtpPage />} />
							<Route element={<ProtectedRoute />}>
								<Route path="/" element={<HomePage />} />
								<Route path="/about" element={<AboutPage />} />
								<Route path="/setting" element={<SettingPage />} />
								<Route path="/logout" element={<LogoutPage />} />
								<Route path="/delete-user" element={<DeleteUserPage />} />
								<Route path="/tenants/*" element={<TenantsPage />} />
								<Route path="/rooms/*" element={<RoomsPage />} />
								<Route path="/properties/*" element={<PropertiesPage />} />
								<Route path="/adminaccess/*" element={<AdminAccessPage />} />
								<Route path="/services/*" element={<ServicesPage />} />
								<Route path="/expenses/*" element={<ExpensesPage />} />
								<Route
									path="/payment_methods/*"
									element={<PaymentMethodsPage />}
								/>
								<Route path="/invoices/*" element={<InvoicesPage />} />
								<Route path="/billings/*" element={<BillingPage />} />
								<Route path="/report/*" element={<ReportPage />} />
							</Route>
							<Route
								path="*"
								element={
									<>
										<h1>404</h1>
										<p>Upps halaman tidak ditemukan.</p>
									</>
								}
							/>
						</Routes>
					</div>
					<ToastContainer limit={1} />
				</PropertiesProvider>
			</AuthProvider>
		</Router>
	)
}

export default App
