import BeatLoader from "react-spinners/BeatLoader";

function Spinner2() {
  return (
    <BeatLoader
      color={`#fff`}
      loading={true}
      size={15}
      aria-label="Loading"
      data-testid="loader"
    />
  );
}

export default Spinner2;
