import { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	FaClock,
	FaEye,
	FaUser,
	FaBed,
	FaDollarSign,
	FaCashRegister,
	FaMoneyBill,
	FaStickyNote,
	FaTimes,
} from 'react-icons/fa'
import moment from 'moment'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import imageCompression from 'browser-image-compression'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'
import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'

const customStyles = {
	content: {
		bottom: 'auto',
		background: '#672f0a',
		borderRadius: '10px',
		inset: '20px 20px auto',
		zIndex: 1,
		maxWidth: '600px',
		maxHeight: '95%',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function InvoicesItem({ item, getAllInvoices }) {
	const { activeProperty } = useContext(AuthContext)
	const { property_id } = activeProperty

	const [paymentMethods, setPaymentmethods] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [paymentDate, setPaymentDate] = useState('')
	const [modal1IsOpen, set1IsOpen] = useState(false)
	const [modal2IsOpen, set2IsOpen] = useState(false)
	const [formData, setFormData] = useState({
		paymentAmount: '',
		paymentmethod_id: '',
		receipt_image: '',
		receipt_url: '',
		note: '',
	})
	const { paymentAmount, paymentmethod_id, receipt_image, note } = formData

	useEffect(() => {
		if (property_id) {
			getAllPaymentMethods(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	const navigate = useNavigate()

	//get all Payment Methods from API
	const getAllPaymentMethods = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/payment_method/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setPaymentmethods(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new payment
	const addPayment = async (property_id, paymentData) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/invoice/add_payment?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('tenant_id', paymentData.tenant_id)
			formData.append('invoice_id', paymentData.invoice_id)
			formData.append('note', paymentData.note)
			formData.append('payment_proof', paymentData.payment_proof)
			formData.append('amount', paymentData.amount)
			formData.append('paymentmethod_id', paymentData.paymentmethod_id)
			formData.append('paid_at', paymentData.paid_at)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				getAllInvoices(property_id, paymentData.tenant_id)

				navigate(`/invoices/${paymentData.tenant_id}`)

				toast.success('Sukses mencatat pembayaran.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	function openModal1() {
		set1IsOpen(true)
	}

	function closeModal1() {
		setPaymentDate('')

		setFormData({
			paymentAmount: '',
			paymentmethod_id: '',
			receipt_image: '',
			receipt_url: '',
			note: '',
		})

		set1IsOpen(false)
	}

	function openModal2() {
		set2IsOpen(true)
	}

	function closeModal2() {
		set2IsOpen(false)
	}

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const onChangeImage = async (e) => {
		const imageFile = e.target.files[0]

		if (!imageFile.type.startsWith('image')) {
			toast.error('Tipe gambar harus jpg/png', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		} else {
			const options = {
				maxSizeMB: 0.8,
				maxWidthOrHeight: 600,
				useWebWorker: true,
				onProgress: () => null,
			}
			try {
				const compressedBlob = await imageCompression(imageFile, options)

				const compressedFile = new File([compressedBlob], imageFile.name, {
					lastModified: compressedBlob.lastModified,
					type: 'image/jpeg',
				})

				setFormData((prevState) => ({
					...prevState,
					receipt_image: compressedFile,
				}))
			} catch (error) {
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		}
	}

	const submitPayment = (e) => {
		e.preventDefault()

		if (!paymentAmount || paymentAmount === '0' || !paymentmethod_id) {
			toast.error(
				'Jumlah bayar dan metode pembayaran tidak boleh 0 atau kosong.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		confirmAlert({
			title: 'Yakin Bayar?',
			message: `Anda akan mencatat pembayaran ${formatter.format(
				paymentAmount !== '' ? parseInt(paymentAmount.replace(/\./g, '')) : ''
			)}, ${moment(paymentDate).format('DD MMM YYYY')} untuk tagihan ${
				item.invoice_number
			} (Kamar ${item.room_name}).`,
			buttons: [
				{
					label: 'Batal',
					onClick: () => null,
				},
				{
					label: 'Yakin, Bayar',
					onClick: async () => {
						const paymentData = {
							note,
							amount:
								paymentAmount !== ''
									? parseInt(paymentAmount.replace(/\./g, ''))
									: '',
							paymentmethod_id,
							tenant_id: item.tenant_id,
							invoice_id: item.id,
							paid_at: moment(paymentDate).format('YYYY-MM-DD'),
							payment_proof: receipt_image,
						}

						addPayment(property_id, paymentData)

						setPaymentDate('')

						setFormData({
							paymentAmount: '',
							paymentmethod_id: '',
							receipt_image: '',
							receipt_url: '',
							note: '',
						})

						set1IsOpen(false)
						set2IsOpen(true)
					},
				},
			],
		})
	}

	let paymentBadge
	let paymentText
	switch (item.status) {
		case 'not paid':
			paymentBadge = <span className="badge badge-info">Belum Bayar</span>
			paymentText = 'Belum Bayar'
			break
		case 'partial paid':
			paymentBadge = (
				<span className="badge badge-info">Cicil. Belum Lunas</span>
			)
			paymentText = 'Cicil. Belum Lunas'
			break
		case 'fully paid':
			paymentBadge = <span className="badge badge-info">Lunas</span>
			paymentText = 'Lunas'
			break
		case 'cancel':
			paymentBadge = <span className="badge badge-info">Batal</span>
			paymentText = 'Batal'
			break

		default:
			break
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading) {
		buttonState = 'Bayar'
	}

	const dueDate = new Date(item.due_date)
	let currDate = new Date()
	let passDueText = ''

	if (
		dueDate < currDate &&
		item.status !== 'fully paid' &&
		item.status !== 'cancel'
	) {
		passDueText = (
			<>
				<br />
				<span style={{ color: 'red', fontSize: '0.8rem' }}>
					<strong>Sudah Jatuh Tempo</strong>
				</span>
			</>
		)
	}

	return (
		<Card reverse={false}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>{paymentBadge}</div>
				{item.status !== 'fully paid' && item.status !== 'cancel' && (
					<button
						onClick={openModal1}
						className="btn btn-success btn-small btnNoMargin"
					>
						<FaCashRegister /> Catat Pembayaran
					</button>
				)}
				<Modal
					isOpen={modal1IsOpen}
					onRequestClose={closeModal1}
					style={customStyles}
					ariaHideApp={false}
				>
					<h3 style={{ marginTop: 0 }}>Catat Pembayaran</h3>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<p style={{ textTransform: 'capitalize' }}>
							<FaUser /> {item.name}
						</p>
						<p>
							<FaBed /> {item.room_name}
						</p>
					</div>
					<table
						border="1"
						style={{
							tableLayout: 'fixed',
							width: '100%',
						}}
					>
						<tbody>
							<tr>
								<td>Nomor Invoice</td>
								<td style={{ fontFamily: 'arial', fontSize: '0.8rem' }}>
									{item.invoice_number}
								</td>
							</tr>
							<tr>
								<td>Jatuh Tempo</td>
								<td>{moment(item.due_date).format('DD MMM YYYY')}</td>
							</tr>
							<tr>
								<td>Total Tagihan</td>
								<td>{formatter.format(item.total_price)}</td>
							</tr>
							<tr>
								<td>Sudah Dibayar</td>
								<td>{formatter.format(item.total_paid)}</td>
							</tr>
							<tr>
								<td>
									<strong>Sisa Tagihan</strong>
								</td>
								<td>
									<strong>{formatter.format(item.remaining_amount)}</strong>
								</td>
							</tr>
						</tbody>
					</table>
					<form
						onSubmit={submitPayment}
						autoComplete="off"
						style={{ marginTop: '20px' }}
					>
						<div className="inputGroup2cols">
							<div className="inputGroup">
								<div className="inputLabel">
									<label>
										<b>Tanggal Bayar</b>
									</label>
								</div>
								<DatePicker
									selected={paymentDate}
									onChange={(date) => setPaymentDate(date)}
									dateFormat="dd-MM-yyyy"
									maxDate={new Date()}
									onKeyDown={(e) => {
										e.preventDefault()
									}}
									required
									placeholderText="Pilih tanggal"
									onFocus={(e) => e.target.blur()}
								/>
							</div>
							<div className="inputGroup">
								<div className="inputLabel">
									<label>
										<b>Jumlah (Rp)</b>
									</label>
								</div>
								<NumericFormat
									value={paymentAmount}
									className="textInput"
									thousandSeparator="."
									decimalSeparator=","
									placeholder="Jumlah Bayar"
									id="paymentAmount"
									onChange={onChange}
									required
								/>
							</div>
						</div>
						<div className="inputGroup">
							<div className="inputLabel">
								<label>
									<b>Pilih Metode Pembayaran</b>
								</label>
							</div>
							<select
								id="paymentmethod_id"
								value={paymentmethod_id}
								onChange={onChange}
								className="select"
								required
							>
								<option value="">Pilih Metode</option>
								{paymentMethods &&
									paymentMethods.map((method) => (
										<option key={method.id} value={method.id}>
											{method.payment_type} {method.provider_name}{' '}
											{method.account_number}
										</option>
									))}
							</select>
						</div>
						<div className="inputGroup">
							<div className="inputLabel">
								<label>
									<b>Bukti Bayar (opsional, jpg/png)</b>
								</label>
							</div>
							<input
								type="file"
								className="textInput fileInput"
								id="receipt_image"
								onChange={onChangeImage}
							/>
						</div>
						<div className="inputGroup">
							<div className="inputLabel">
								<label>
									<b>Catatan Tambahan (opsional)</b>
								</label>
							</div>
							<input
								type="text"
								className="textInput"
								placeholder="Isi catatan tambahan"
								id="note"
								value={note}
								onChange={onChange}
							/>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<button
								className="btn btn-medium btn-cancel btn-block"
								onClick={closeModal1}
							>
								<FaTimes /> Batal
							</button>
							<button
								type="submit"
								className="btn btn-medium btn-success btn-block"
							>
								{buttonState}
							</button>
						</div>
					</form>
				</Modal>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className="text-display">
					<a
						target="_blank"
						rel="noreferrer"
						href={`${process.env.REACT_APP_APIURL}uploads/invoices/${item.invoice_file}`}
					>
						<FaEye />{' '}
						<strong style={{ fontFamily: 'arial', fontSize: '0.9rem' }}>
							{item.invoice_number}
						</strong>
					</a>
					<br />
					<strong>{formatter.format(item.total_price)}</strong>
				</div>
				<div className="text-display" style={{ textAlign: 'right' }}>
					<FaClock />
					<strong>{moment(item.due_date).format('DD MMM YYYY')}</strong>
					{passDueText}
					{item.status &&
						(item.status === 'fully paid' ||
							item.status === 'partial paid') && (
							<>
								<br />
								<button
									className="btn btn-small btn-success btnNoMargin"
									onClick={openModal2}
								>
									<FaDollarSign /> Daftar Pembayaran
								</button>
							</>
						)}
				</div>
			</div>
			<Modal
				isOpen={modal2IsOpen}
				onRequestClose={closeModal2}
				style={customStyles}
				ariaHideApp={false}
			>
				<h3 style={{ marginTop: 0 }}>Daftar Pembayaran</h3>

				<table
					style={{
						tableLayout: 'fixed',
						width: '100%',
						marginBottom: '40px',
					}}
				>
					<tbody>
						<tr>
							<td>{item.name}</td>
							<td>Kamar {item.room_name}</td>
						</tr>
						<tr>
							<td>Invoice</td>
							<td style={{ fontFamily: 'arial', fontSize: '0.8rem' }}>
								{item.invoice_number}
							</td>
						</tr>
						<tr>
							<td>Jatuh Tempo</td>
							<td>{moment(item.due_date).format('DD MMM YYYY')}</td>
						</tr>
						<tr>
							<td>Total Tagihan</td>
							<td>{formatter.format(item.total_price)}</td>
						</tr>
						<tr>
							<td>Sudah Dibayar</td>
							<td>{formatter.format(item.total_paid)}</td>
						</tr>
						<tr>
							<td>Sisa Tagihan</td>
							<td>{formatter.format(item.remaining_amount)}</td>
						</tr>
						<tr>
							<td>Status</td>
							<td>{paymentText}</td>
						</tr>
					</tbody>
				</table>
				<table
					border="1"
					style={{
						tableLayout: 'fixed',
						width: '100%',
					}}
				>
					<tbody>
						<tr>
							<th>
								<strong>Tanggal</strong>
							</th>
							<th>
								<strong>Jumlah Bayar</strong>
							</th>
						</tr>
						{item.payments &&
							item.payments.map((payment) => (
								<tr key={payment.id}>
									<td>{moment(payment.paid_at).format('DD MMM YYYY')}</td>
									<td style={{ fontSize: '0.9rem' }}>
										{formatter.format(payment.amount)}
										{payment.payment_proof && (
											<>
												<br />
												<a
													target="_blank"
													rel="noreferrer"
													href={`${process.env.REACT_APP_APIURL}uploads/payments/${payment.payment_proof}`}
													style={{
														display: 'inline-block',
														marginTop: '5px',
													}}
												>
													<FaMoneyBill /> Bukti Bayar
												</a>
											</>
										)}
										<br />
										<a
											target="_blank"
											rel="noreferrer"
											href={`${process.env.REACT_APP_APIURL}uploads/payments/${payment.receipt_file}`}
											style={{
												fontFamily: 'arial',
												display: 'inline-block',
												marginTop: '5px',
												marginBottom: '5px',
											}}
										>
											{payment.receipt_number}
										</a>
										<br />
										{payment.payment_method}
										{payment.note && (
											<>
												<br />
												<FaStickyNote /> {payment.note}
											</>
										)}
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<br />
				{isLoading && (
					<div className="spinnerBlock" style={{ marginBottom: '10px' }}>
						<Spinner2 />
					</div>
				)}
				<button className="btn btn-small btn-secondary" onClick={closeModal2}>
					Tutup
				</button>
			</Modal>
		</Card>
	)
}

InvoicesItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default InvoicesItem
