import { useContext, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { FaTimesCircle } from 'react-icons/fa'
import axios from 'axios'
import { toast } from 'react-toastify'

import AuthContext from '../../context/AuthContext'
import Spinner2 from '../../components/Spinner2'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function TenantsServicesForm({
	index,
	item,
	duration,
	editService,
	deleteService,
}) {
	const { activeProperty } = useContext(AuthContext)
	const { property_id } = activeProperty

	const [services, setServices] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [currService, setCurrService] = useState({
		id: '',
		name: '',
		price: '',
	})
	const { id, name, price } = currService

	useEffect(() => {
		editService(index, {
			id,
			name,
			price,
		})
		//eslint-disable-next-line
	}, [id, name, price])

	useEffect(() => {
		setCurrService({
			id: item.id,
			name: item.name,
			price: item.price,
		})
	}, [item])

	useEffect(() => {
		if (property_id) {
			getAllServices(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all Services from API
	const getAllServices = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/available?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setServices(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get single service from API
	const getService = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/detail?user_id=${tokens[2]}&service_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			let servicePrice = ''
			switch (duration) {
				case 'month':
					servicePrice = res.data.data.price_monthly
					break
				case 'week':
					servicePrice = res.data.data.price_weekly
					break
				case 'quarter':
					servicePrice = res.data.data.price_quarterly
					break
				default:
					break
			}

			setCurrService((prevState) => ({
				...prevState,
				price: servicePrice,
			}))
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const onChange = (e) => {
		if (e.target.id === 'id') {
			if (e.target.value === '') {
				setCurrService({
					id: '',
					name: '',
					price: '',
				})
			} else {
				setCurrService((prevState) => ({
					...prevState,
					id: e.target.value,
					name: e.target.options[e.target.selectedIndex].text,
				}))

				if (property_id) {
					getService(property_id, e.target.value)
				}
			}
		}

		if (e.target.id === 'price') {
			setCurrService((prevState) => ({
				...prevState,
				price: e.target.value,
			}))
		}
	}

	let durationText

	switch (duration) {
		case 'month':
			durationText = 'bulan'
			break
		case 'week':
			durationText = 'minggu'
			break
		case 'quarter':
			durationText = '3 bulan'
			break
		default:
			durationText = 'durasi'
			break
	}

	return (
		<>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<div className="inputGroup3cols">
				<div className="inputGroup">
					<select
						onChange={onChange}
						className="select"
						id="id"
						value={id}
						required
					>
						<option value="">Pilih jasa</option>
						{services &&
							services.map((service) => (
								<option key={service.id} value={service.id}>
									{service.name}
								</option>
							))}
					</select>
				</div>
				<div className="inputGroup">
					<NumericFormat
						id="price"
						value={price}
						className="textInput"
						thousandSeparator="."
						decimalSeparator=","
						placeholder={`Harga/${durationText}`}
						onChange={onChange}
						required
					/>
				</div>
				<div style={{ textAlign: 'center', cursor: 'pointer' }}>
					<FaTimesCircle size={20} onClick={() => deleteService(index)} />
				</div>
			</div>
		</>
	)
}

export default TenantsServicesForm
