import { Route, Routes } from 'react-router-dom'

import TenantsList from './TenantsList'
import TenantsListPast from './TenantsListPast'
import TenantsAdd from './TenantsAdd'
import TenantsEdit from './TenantsEdit'

function TenantsPage() {
	return (
		<Routes>
			<Route path="/" element={<TenantsList />} />
			<Route path="/past" element={<TenantsListPast />} />
			<Route path="/edit" element={<TenantsAdd />} />
			<Route path="/edit/:id" element={<TenantsEdit />} />
			<Route
				path="*"
				element={
					<>
						<h1>404</h1>
						<p>Upps halaman tidak ditemukan.</p>
					</>
				}
			/>
		</Routes>
	)
}

export default TenantsPage
