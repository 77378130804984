import { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate, useLocation } from 'react-router-dom'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
	const [userData, setUserData] = useState({
		isAuthenticated: false,
		user_id: null,
		name: null,
		handphone: null,
		role: '',
		admin_access: null,
		otpType: '',
		date: '',
		currMonth: '',
		currYear: '',
	})
	const { user_id, handphone, otpType } = userData
	const [toOtpPage, setToOtpPage] = useState(false)
	const [lastPage, setLastPage] = useState('/')
	const [isLoading, setIsLoading] = useState(false)
	const [activeProperty, setActiveProperty] = useState({
		property_id: '',
		property_name: '',
	})

	const navigate = useNavigate()
	let location = useLocation()

	//when location (url) change, re-run getProfilee to get user latest role & access
	useEffect(() => {
		getProfile()
		//eslint-disable-next-line
	}, [location])

	//get current user
	const getProfile = async () => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		if (tokens) {
			try {
				const url = `${process.env.REACT_APP_APIURL}v1/account/profile?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

				const config = {
					headers: {
						'X-API-Key': process.env.REACT_APP_XAPIKEY,
						Authorization: `Bearer ${tokens[0]}`,
					},
				}

				const res = await axios.get(url, config)

				if (res.status === 200) {
					setUserData({
						isAuthenticated: true,
						user_id: res.data.data.user_id,
						name: res.data.data.name,
						handphone: res.data.data.handphone,
						role: res.data.data.role,
						admin_access: res.data.data.admin_access,
						date: res.data.data.date,
						currMonth: res.data.data.month,
						currYear: res.data.data.year,
					})
				}
			} catch (err) {
				console.log(err.response.data.message)
			}
		}
	}

	//register user
	const registerUser = async (newUser) => {
		setIsLoading(true)

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/account/register`
			const config = {
				headers: {
					'X-API-Key': process.env.REACT_APP_XAPIKEY,
				},
			}

			let formData = new FormData()
			formData.append('name', newUser.name)
			formData.append('handphone', newUser.handphone)
			formData.append('property_name', newUser.property)
			formData.append('address', newUser.address)
			formData.append('city', newUser.city)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				setUserData((prevState) => ({
					...prevState,
					user_id: res.data.data.user_id,
					name: res.data.data.name,
					handphone: res.data.data.handphone,
					otpType: 'register',
				}))

				//store to local storage
				localStorage.setItem('otp_token', res.data.data.otp_token)

				setToOtpPage(true)

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//login user
	const loginUser = async (handphone) => {
		setIsLoading(true)

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/account/login`
			const config = {
				headers: {
					'X-API-Key': process.env.REACT_APP_XAPIKEY,
				},
			}
			let formData = new FormData()
			formData.append('handphone', handphone)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				setUserData((prevState) => ({
					...prevState,
					user_id: res.data.data.user_id,
					name: res.data.data.name,
					handphone: res.data.data.handphone,
					otpType: 'login',
				}))

				//store to local storage
				localStorage.setItem('otp_token', res.data.data.otp_token)

				setToOtpPage(true)

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//verify otp
	const verifyOtp = async (otp) => {
		setIsLoading(true)

		const otp_token = localStorage.getItem('otp_token')

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/account/verify_otp`
			const config = {
				headers: {
					'X-API-Key': process.env.REACT_APP_XAPIKEY,
				},
			}
			let formData = new FormData()
			formData.append('handphone', handphone)
			formData.append('otp', otp)
			formData.append('otp_token', otp_token)
			formData.append('type', otpType)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				setUserData((prevState) => ({
					...prevState,
					isAuthenticated: true,
				}))

				//store tokens to local storage
				const tokens = [
					res.data.data.token,
					res.data.data.refresh_token,
					res.data.data.user_id,
				]
				localStorage.setItem('tokens', JSON.stringify(tokens))

				//unset otp_token
				localStorage.removeItem('otp_token')

				setToOtpPage(false)

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//logout user
	const logoutUser = async () => {
		confirmAlert({
			title: 'Yakin mau keluar?',
			message: 'Anda akan keluar dari aplikasi.',
			buttons: [
				{
					label: 'Batal',
					onClick: () => navigate('/setting'),
				},
				{
					label: 'Yakin, Keluar',
					onClick: async () => {
						setIsLoading(true)

						const tokens = JSON.parse(localStorage.getItem('tokens'))

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/account/logout?user_id=${user_id}&refresh_token=${tokens[1]}`

							const config = {
								headers: {
									'X-API-Key': process.env.REACT_APP_XAPIKEY,
									Authorization: `Bearer ${tokens[0]}`,
								},
							}

							const res = await axios.get(url, config)

							if (res.status === 200) {
								setUserData({
									isAuthenticated: false,
									user_id: null,
									name: null,
									handphone: null,
									otpType: '',
									date: '',
									currMonth: '',
									currYear: '',
								})

								setActiveProperty({
									property_id: '',
									property_name: '',
								})

								localStorage.removeItem('tokens')

								toast.success(res.data.message, {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	//delete user
	const deleteUser = async () => {
		confirmAlert({
			title: 'Yakin hapus Akun?',
			message:
				'Semua data akun dan data properti Anda akan terhapus selamanya. Apakah Anda sudah yakin untuk hapus?',
			buttons: [
				{
					label: 'Batal',
					onClick: () => navigate('/setting'),
				},
				{
					label: 'Yakin, Hapus',
					onClick: async () => {
						setIsLoading(true)

						const tokens = JSON.parse(localStorage.getItem('tokens'))

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/account/destroy?user_id=${user_id}&refresh_token=${tokens[1]}`

							const config = {
								headers: {
									'X-API-Key': process.env.REACT_APP_XAPIKEY,
									Authorization: `Bearer ${tokens[0]}`,
								},
							}

							const res = await axios.get(url, config)

							if (res.status === 200) {
								setUserData({
									isAuthenticated: false,
									user_id: null,
									name: null,
									handphone: null,
									otpType: '',
								})

								setActiveProperty({
									property_id: '',
									property_name: '',
								})

								localStorage.removeItem('tokens')

								toast.success(res.data.message, {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	return (
		<AuthContext.Provider
			value={{
				userData,
				registerUser,
				loginUser,
				verifyOtp,
				logoutUser,
				deleteUser,
				lastPage,
				setLastPage,
				activeProperty,
				setActiveProperty,
				toOtpPage,
				setToOtpPage,
				isLoading,
				getProfile,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContext
