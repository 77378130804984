import { Route, Routes } from 'react-router-dom'

import BillingList from './BillingList'

function BillingPage() {
	return (
		<Routes>
			<Route path="/" element={<BillingList />} />
			<Route
				path="*"
				element={
					<>
						<h1>404</h1>
						<p>Upps halaman tidak ditemukan.</p>
					</>
				}
			/>
		</Routes>
	)
}

export default BillingPage
