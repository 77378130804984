import { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/shared/Button'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function ServicesForm() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	const [service, setService] = useState({
		id: '',
		name: '',
		price_monthly: '',
		price_weekly: '',
		price_quarterly: '',
	})
	const [formData, setFormData] = useState({
		name: '',
		price_monthly: '',
		price_weekly: '',
		price_quarterly: '',
	})
	const { name, price_monthly, price_weekly, price_quarterly } = formData
	const [isLoading, setIsLoading] = useState(false)

	let { id } = useParams()

	const navigate = useNavigate()

	useEffect(() => {
		if (!id && role === 'admin' && admin_access.services_add === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [id, role, admin_access])

	useEffect(() => {
		if (id && property_id) {
			getService(property_id, id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	useEffect(() => {
		if (id) {
			setFormData({
				name: service.name,
				price_monthly: service.price_monthly
					? addCommas(removeNonNumeric(service.price_monthly))
					: '',
				price_weekly: service.price_weekly
					? addCommas(removeNonNumeric(service.price_weekly))
					: '',
				price_quarterly: service.price_quarterly
					? addCommas(removeNonNumeric(service.price_quarterly))
					: '',
			})
		}
		//eslint-disable-next-line
	}, [service])

	//get single service from API
	const getService = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/detail?user_id=${tokens[2]}&service_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setService({
				id: res.data.data.id,
				name: res.data.data.name,
				price_monthly: res.data.data.price_monthly,
				price_weekly: res.data.data.price_weekly,
				price_quarterly: res.data.data.price_quarterly,
			})
		} catch (err) {
			navigate('/services')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new service
	const addService = async (property_id, newservice) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('name', newservice.name)
			formData.append('price_monthly', newservice.price_monthly)
			formData.append('price_weekly', newservice.price_weekly)
			formData.append('price_quarterly', newservice.price_quarterly)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				navigate('/services')

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//update service
	const updateService = async (property_id, id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('service_id', id)
			formData.append('name', updItem.name)
			formData.append('price_monthly', updItem.price_monthly)
			formData.append('price_weekly', updItem.price_weekly)
			formData.append('price_quarterly', updItem.price_quarterly)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const onChange = (e) => {
		let targetValue

		if (
			e.target.id === 'price_monthly' ||
			e.target.id === 'price_weekly' ||
			e.target.id === 'price_quarterly'
		) {
			targetValue = addCommas(removeNonNumeric(e.target.value))
		} else {
			targetValue = e.target.value
		}

		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: targetValue,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!price_monthly && !price_weekly && !price_quarterly) {
			toast.error(
				'Mohon mengisi harga layanan salah satu dari bulanan, mingguan, dan 3 bulanan.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		if (
			price_monthly === '0' ||
			price_weekly === '0' ||
			price_quarterly === '0'
		) {
			toast.error(
				'Harga harus lebih besar dari 0 untuk durasi layanan yang dipilih, atau kosongkan bila tidak dipakai.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		const newService = {
			name,
			price_monthly:
				price_monthly !== '' ? parseInt(price_monthly.replace(/\./g, '')) : '',
			price_weekly:
				price_weekly !== '' ? parseInt(price_weekly.replace(/\./g, '')) : '',
			price_quarterly:
				price_quarterly !== ''
					? parseInt(price_quarterly.replace(/\./g, ''))
					: '',
		}

		if (id && property_id) {
			updateService(property_id, id, newService)
		} else {
			addService(property_id, newService)
		}
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Ubah'
	} else if (!isLoading && !id) {
		buttonState = 'Tambah'
	}

	let updateServiceButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.services_edit === 'yes')
	) {
		updateServiceButton = (
			<Button type="submit" version="block" isDisabled={isLoading}>
				{buttonState}
			</Button>
		)
	}

	return (
		<>
			<Link to="/services">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">{id ? 'Edit' : 'Tambah'} Layanan</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Layanan</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Contoh: Cuci baju, Internet Wifi, gas"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputLabel">
					<label>
						<b>Harga Layanan (Rp)</b>
						<br />
						Isi minimal 1 harga. Kosongkan yang tidak dibutuhkan.
					</label>
				</div>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Harga / 1 bulan</b>
							</label>
						</div>
						<NumericFormat
							value={price_monthly}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga per bulan"
							id="price_monthly"
							onChange={onChange}
						/>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Harga / 1 minggu</b>
							</label>
						</div>
						<NumericFormat
							value={price_weekly}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga per minggu"
							id="price_weekly"
							onChange={onChange}
						/>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Harga / 3 bulan</b>
							</label>
						</div>
						<NumericFormat
							value={price_quarterly}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga per 3 bulan"
							id="price_quarterly"
							onChange={onChange}
						/>
					</div>
				</div>
				{updateServiceButton}
			</form>
		</>
	)
}

export default ServicesForm
