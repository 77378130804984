import { useContext } from 'react'
import PropTypes from 'prop-types'
import { FaTimesCircle, FaEdit, FaCopy, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

function RoomsItem({ item, deleteRoom, duplicateRoom }) {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	let ccBadgeClass
	let badgeName
	if (item.status === 'active' && item.tenant_id) {
		ccBadgeClass = 'badge-info'
		badgeName = 'Terisi'
	} else if (item.status === 'active' && !item.tenant_id) {
		ccBadgeClass = 'badge-active'
		badgeName = 'Tersedia'
	} else if (item.status === 'inactive') {
		ccBadgeClass = 'badge-inactive'
		badgeName = 'Tidak Tersedia'
	}

	let monthlyFee
	let weeklyFee
	let quarterlyFee

	if (item.price_monthly > 0) {
		monthlyFee = (
			<span className="priceBorder">
				{formatter.format(item.price_monthly)}/bulan
			</span>
		)
	}
	if (item.price_weekly > 0) {
		weeklyFee = (
			<span className="priceBorder">
				{formatter.format(item.price_weekly)}/minggu
			</span>
		)
	}
	if (item.price_quarterly > 0) {
		quarterlyFee = (
			<span className="priceBorder">
				{formatter.format(item.price_quarterly)}/3 bulan
			</span>
		)
	}

	let deleteRoomButton = ''
	if (!item.tenant_id) {
		if (
			role === 'owner' ||
			(role === 'admin' && admin_access.rooms_delete === 'yes')
		) {
			deleteRoomButton = (
				<button
					className="btn btn-small btn-danger"
					onClick={() => deleteRoom(property_id, item.id)}
				>
					<FaTimesCircle /> Hapus
				</button>
			)
		}
	}

	return (
		<Card reverse={false}>
			<div className="flex-2cols">
				<div className="text-title">Kamar {item.name}</div>
				<span className={`badge ${ccBadgeClass}`}>{badgeName}</span>
			</div>
			{item.tenant_id && (
				<div className="text-display">
					<Link to={`/tenants`}>
						<strong>
							<FaUser /> {item.tenant_name}
						</strong>
					</Link>
				</div>
			)}
			<Link
				to={`/rooms/edit/${item.id}`}
				style={{ color: 'black', fontWeight: 'normal' }}
			>
				<div className="text-display">
					{monthlyFee} {weeklyFee} {quarterlyFee}
				</div>
			</Link>
			<div className="text-display" style={{ fontSize: '0.8rem' }}>
				{item.description}
			</div>
			<div style={{ marginTop: '10px' }}>
				<Link to={`/rooms/edit/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaEdit /> Ubah
					</button>
				</Link>
				<button
					className="btn btn-small btn-success"
					onClick={() => duplicateRoom(property_id, item.id)}
				>
					<FaCopy /> Copy
				</button>

				{deleteRoomButton}
			</div>
		</Card>
	)
}

RoomsItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default RoomsItem
