import { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthContext from '../context/AuthContext'
import PropertiesContext from '../context/PropertiesContext'

function LogoutPage() {
	const { logoutUser, userData } = useContext(AuthContext)
	const { user_id, isAuthenticated } = userData
	const { setProperties, setProperty } = useContext(PropertiesContext)

	const navigate = useNavigate()

	const shouldLogout = useRef(true)

	useEffect(() => {
		if (shouldLogout.current) {
			shouldLogout.current = false

			if (!user_id || !isAuthenticated) {
				navigate('/login')
			} else {
				logoutUser()
				setProperties('')
				setProperty({
					name: '',
					address: '',
					admin_handphone: '',
					city: '',
					monthly_wa_daysbeforedue: '',
					weekly_wa_daysbeforedue: '',
					quarterly_wa_daysbeforedue: '',
					send_wa_daysafterduedate: '',
				})
			}
		}

		//eslint-disable-next-line
	}, [user_id])

	return null
}

export default LogoutPage
