import { useContext } from 'react'
import PropTypes from 'prop-types'
import { FaTimesCircle, FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'

function PaymentMethodsItem({ item, deletePaymentMethod }) {
	const { activeProperty } = useContext(AuthContext)
	const { property_id } = activeProperty

	return (
		<Card reverse={false}>
			<div className="text-title">{item.payment_type}</div>
			<div className="text-display">
				{item.provider_name} {item.account_number} {item.account_name}
			</div>
			<div style={{ marginTop: '10px' }}>
				<Link to={`/payment_methods/edit/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaEdit /> Ubah
					</button>
				</Link>
				<button
					className="btn btn-small btn-danger"
					onClick={() => deletePaymentMethod(property_id, item.id)}
				>
					<FaTimesCircle /> Hapus
				</button>
			</div>
		</Card>
	)
}

PaymentMethodsItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default PaymentMethodsItem
