import { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft, FaCog } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/shared/Button'
import PropertiesContext from '../../context/PropertiesContext'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'

function PropertiesForm() {
	const { addProperty, property, getProperty, updateProperty, isLoading } =
		useContext(PropertiesContext)
	const { userData } = useContext(AuthContext)
	const { handphone, role } = userData

	const [formData, setFormData] = useState({
		name: '',
		address: '',
		admin_handphone: '',
		city: '',
		monthly_wa_daysbeforedue: '',
		weekly_wa_daysbeforedue: '',
		quarterly_wa_daysbeforedue: '',
		send_wa_daysafterduedate: '',
	})
	const {
		name,
		address,
		admin_handphone,
		city,
		monthly_wa_daysbeforedue,
		weekly_wa_daysbeforedue,
		quarterly_wa_daysbeforedue,
		send_wa_daysafterduedate,
	} = formData

	let { id } = useParams()

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role])

	useEffect(() => {
		if (id) {
			getProperty(id)
		}

		if (!id) {
			setFormData((prevState) => ({
				...prevState,
				admin_handphone: handphone,
				monthly_wa_daysbeforedue: '15',
				weekly_wa_daysbeforedue: '4',
				quarterly_wa_daysbeforedue: '30',
				send_wa_daysafterduedate: '1',
			}))
		}
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (id) {
			setFormData({
				name: property.name,
				address: property.address,
				admin_handphone: property.admin_handphone,
				city: property.city,
				monthly_wa_daysbeforedue: property.monthly_wa_daysbeforedue,
				weekly_wa_daysbeforedue: property.weekly_wa_daysbeforedue,
				quarterly_wa_daysbeforedue: property.quarterly_wa_daysbeforedue,
				send_wa_daysafterduedate: property.send_wa_daysafterduedate,
			})
		}
		//eslint-disable-next-line
	}, [property])

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (
			!name ||
			!address ||
			!city ||
			!admin_handphone ||
			!monthly_wa_daysbeforedue ||
			!weekly_wa_daysbeforedue ||
			!quarterly_wa_daysbeforedue ||
			!send_wa_daysafterduedate
		) {
			toast.error('Mohon melengkapi semua isian.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const twoCharsHp = admin_handphone.substring(0, 2)

		if (twoCharsHp !== '08') {
			toast.error('Nomor Hp harus dimulai dengan 08.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const newProperty = {
			name,
			address,
			admin_handphone,
			city,
			monthly_wa_daysbeforedue,
			weekly_wa_daysbeforedue,
			quarterly_wa_daysbeforedue,
			send_wa_daysafterduedate,
		}

		if (id) {
			updateProperty(id, newProperty)
		} else {
			addProperty(newProperty)
		}
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Ubah'
	} else if (!isLoading && !id) {
		buttonState = 'Tambah'
	}

	let updatePropertyButton = ''
	if (role === 'owner') {
		updatePropertyButton = (
			<Button type="submit" version="block" isDisabled={isLoading}>
				{buttonState}
			</Button>
		)
	}

	return (
		<>
			<Link to="/properties">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">{id ? 'Edit' : 'Tambah'} Kos</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Kos</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Nama Kos"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Alamat Kos</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Alamat lengkap"
						id="address"
						value={address}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Handphone (WA) Admin</b>
							<br />
							Nomor HP akan muncul di invoice/kwitansi.
						</label>
					</div>
					<input
						type="number"
						className="textInput"
						placeholder="Nomor WA 08XXXXXXXXXX"
						id="admin_handphone"
						value={admin_handphone}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Kota</b>
						</label>
					</div>
					<select
						id="city"
						value={city}
						onChange={onChange}
						className="select"
						required
					>
						<option value="">Pilih Kota</option>
						<option value="Jakarta">Jakarta</option>
						<option value="Bogor">Bogor</option>
						<option value="Depok">Depok</option>
						<option value="Tangerang">Tangerang</option>
						<option value="Bekasi">Bekasi</option>
						<option value="Lainnya">Lainnya</option>
					</select>
				</div>
				<Accordion allowZeroExpanded>
					<AccordionItem>
						<AccordionItemHeading>
							<AccordionItemButton>
								<FaCog /> Pengaturan Tambahan
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p
								style={{ fontSize: '0.9rem', borderBottom: '1px solid white' }}
							>
								Kirim WA invoice ke penyewa X hari sebelum & setelah jatuh tempo
							</p>
							<div className="inputGroup2cols">
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>WA (Bulanan)</b>
										</label>
									</div>
									<input
										type="number"
										className="textInput"
										placeholder="jumlah hari"
										id="monthly_wa_daysbeforedue"
										value={monthly_wa_daysbeforedue}
										onChange={onChange}
										required
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>WA (Mingguan)</b>
										</label>
									</div>
									<input
										type="number"
										className="textInput"
										placeholder="jumlah hari"
										id="weekly_wa_daysbeforedue"
										value={weekly_wa_daysbeforedue}
										onChange={onChange}
										required
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>WA (3 Bulanan)</b>
										</label>
									</div>
									<input
										type="number"
										className="textInput"
										placeholder="jumlah hari"
										id="quarterly_wa_daysbeforedue"
										value={quarterly_wa_daysbeforedue}
										onChange={onChange}
										required
									/>
								</div>
								<div className="inputGroup">
									<div className="inputLabel">
										<label>
											<b>Setelah Due</b>
										</label>
									</div>
									<input
										type="number"
										className="textInput"
										placeholder="X hari setelahnya"
										id="send_wa_daysafterduedate"
										value={send_wa_daysafterduedate}
										onChange={onChange}
										required
									/>
								</div>
							</div>
						</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
				{updatePropertyButton}
			</form>
		</>
	)
}

export default PropertiesForm
