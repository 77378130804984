import { createContext, useContext, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom'

import AuthContext from './AuthContext'

const PropertiesContext = createContext()

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

export const PropertiesProvider = ({ children }) => {
	const { setActiveProperty } = useContext(AuthContext)

	const [properties, setProperties] = useState([])
	const [property, setProperty] = useState({
		name: '',
		address: '',
		admin_handphone: '',
		city: '',
		monthly_wa_daysbeforedue: '',
		weekly_wa_daysbeforedue: '',
		quarterly_wa_daysbeforedue: '',
		send_wa_daysafterduedate: '',
	})
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	//get all properties from API
	const getAllProperties = async () => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/property/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			const res = await axios.get(url, config)

			setProperties(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get single property from API
	const getProperty = async (id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/property/detail?user_id=${tokens[2]}&property_id=${id}&refresh_token=${tokens[1]}`

			const config = configData()

			const res = await axios.get(url, config)

			setProperty({
				name: res.data.data.name,
				address: res.data.data.address,
				admin_handphone: res.data.data.admin_handphone,
				city: res.data.data.city,
				monthly_wa_daysbeforedue: res.data.data.monthly_wa_daysbeforedue,
				weekly_wa_daysbeforedue: res.data.data.weekly_wa_daysbeforedue,
				quarterly_wa_daysbeforedue: res.data.data.quarterly_wa_daysbeforedue,
				send_wa_daysafterduedate: res.data.data.send_wa_daysafterduedate,
			})
		} catch (err) {
			navigate('/properties')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new property
	const addProperty = async (newProperty) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/property/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('name', newProperty.name)
			formData.append('address', newProperty.address)
			formData.append('admin_handphone', newProperty.admin_handphone)
			formData.append('city', newProperty.city)
			formData.append('status', 'active')
			formData.append(
				'monthly_wa_daysbeforedue',
				newProperty.monthly_wa_daysbeforedue
			)
			formData.append(
				'weekly_wa_daysbeforedue',
				newProperty.weekly_wa_daysbeforedue
			)
			formData.append(
				'quarterly_wa_daysbeforedue',
				newProperty.quarterly_wa_daysbeforedue
			)
			formData.append(
				'send_wa_daysafterduedate',
				newProperty.send_wa_daysafterduedate
			)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				getAllProperties()

				navigate('/properties')

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(true)
	}

	//update property
	const updateProperty = async (id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/property/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', id)
			formData.append('name', updItem.name)
			formData.append('address', updItem.address)
			formData.append('admin_handphone', updItem.admin_handphone)
			formData.append('city', updItem.city)
			formData.append('status', 'active')
			formData.append(
				'monthly_wa_daysbeforedue',
				updItem.monthly_wa_daysbeforedue
			)
			formData.append(
				'weekly_wa_daysbeforedue',
				updItem.weekly_wa_daysbeforedue
			)
			formData.append(
				'quarterly_wa_daysbeforedue',
				updItem.quarterly_wa_daysbeforedue
			)
			formData.append(
				'send_wa_daysafterduedate',
				updItem.send_wa_daysafterduedate
			)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				getAllProperties()

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//delete property item
	const deleteProperty = async (id) => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		confirmAlert({
			title: 'Yakin mau hapus?',
			message: 'Data properti ini akan terhapus selamanya.',
			buttons: [
				{
					label: 'Batal',
					onClick: () => null,
				},
				{
					label: 'Hapus',
					onClick: async () => {
						setIsLoading(true)

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/property/delete?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

							const config = configData()

							let formData = new FormData()
							formData.append('property_id', id)

							const res = await axios.post(url, formData, config)

							if (res.status === 200) {
								getAllProperties()

								setActiveProperty({
									property_id: '',
									property_name: '',
								})

								toast.success(res.data.message, {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	return (
		<PropertiesContext.Provider
			value={{
				properties,
				addProperty,
				updateProperty,
				deleteProperty,
				property,
				getProperty,
				getAllProperties,
				setProperties,
				setProperty,
				isLoading,
			}}
		>
			{children}
		</PropertiesContext.Provider>
	)
}

export default PropertiesContext
