import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft, FaUser, FaBed, FaWhatsapp } from 'react-icons/fa'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

import InvoicesItem from './InvoicesItem'
import AuthContext from '../../context/AuthContext'
import Spinner2 from '../../components/Spinner2'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function InvoicesList() {
	const { activeProperty } = useContext(AuthContext)
	const { property_id } = activeProperty

	const [isLoading, setIsLoading] = useState(false)
	const [invoices, setInvoices] = useState('')
	const [tenant, setTenant] = useState({
		room_id: '',
		room_name: '',
		name: '',
		handphone: '',
		identity_image: '',
		photo_image: '',
		rental_price: '',
		deposit: '',
		total_price: '',
		duration: '',
		note: '',
		status: '',
		rental_start: '',
		rental_end: '',
	})
	// const [availableRooms, setAvailableRooms] = useState([])
	//const [currServices, setCurrServices] = useState('')

	let { tenantId } = useParams()

	useEffect(() => {
		if (property_id && tenantId) {
			getAllInvoices(property_id, tenantId)
			getTenant(property_id, tenantId)
		}
		//eslint-disable-next-line
	}, [property_id, tenantId])

	const navigate = useNavigate()

	//get single tenant from API
	const getTenant = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const config = configData()

			const tenant_data = `${process.env.REACT_APP_APIURL}v1/tenant/detail?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}&tenant_id=${id}`

			const tenant_res = await axios.get(tenant_data, config)

			if (tenant_res.data.data) {
				//setAvailableRooms(tenant_res.data.data.availableRooms)

				setTenant({
					room_id: tenant_res.data.data.room_id,
					room_name: tenant_res.data.data.room_name,
					name: tenant_res.data.data.name,
					handphone: tenant_res.data.data.handphone,
					identity_image: tenant_res.data.data.identity_image,
					photo_image: tenant_res.data.data.photo_image,
					rental_price: tenant_res.data.data.rental_price,
					deposit: tenant_res.data.data.deposit,
					total_price: tenant_res.data.data.total_price,
					duration: tenant_res.data.data.duration,
					note: tenant_res.data.data.note,
					status: tenant_res.data.data.status,
					rental_start: tenant_res.data.data.rental_start,
					rental_end: tenant_res.data.data.rental_end,
				})

				//setCurrServices(tenant_res.data.data.chosenServices)
			}
		} catch (err) {
			navigate('/tenants')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get all invoices from API
	const getAllInvoices = async (property_id, tenant_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/invoice/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}&tenant_id=${tenant_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setInvoices(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	let invoiceInfo

	if (invoices && invoices.length === 0) {
		invoiceInfo = <p>Belum ada tagihan.</p>
	} else if (invoices && invoices.length > 0) {
		invoiceInfo = (
			<div className="feedback-list">
				{invoices.map((item) => (
					<InvoicesItem
						key={item.id}
						item={item}
						getAllInvoices={getAllInvoices}
					/>
				))}
			</div>
		)
	}

	const whatsapp = tenant.handphone.substring(1)

	return (
		<>
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">Tagihan & Pembayaran</h2>
			{tenant && (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3 style={{ textTransform: 'capitalize' }}>
						<FaUser /> {tenant.name}
						<br />
						<a
							href={`https://api.whatsapp.com/send?phone=62${whatsapp}`}
							target="_blank"
							rel="noreferrer"
						>
							<FaWhatsapp /> {tenant.handphone}
						</a>
					</h3>
					<h3>
						<FaBed /> {tenant.room_name}
					</h3>
				</div>
			)}

			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{invoiceInfo}
		</>
	)
}

export default InvoicesList
