import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import TenantsItemPast from './TenantsItemPast'
import AuthContext from '../../context/AuthContext'
import Spinner2 from '../../components/Spinner2'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function TenantsListPast() {
	const { activeProperty } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty

	const [isLoading, setIsLoading] = useState(false)
	const [pastTenants, setPastTenants] = useState('')

	useEffect(() => {
		if (property_id) {
			getPastTenants(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all past tenants from API
	const getPastTenants = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/allinactive?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setPastTenants(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	let tenantInfo

	if (pastTenants && pastTenants.length === 0) {
		tenantInfo = <p>Belum ada penyewa lama.</p>
	} else if (pastTenants && pastTenants.length > 0) {
		tenantInfo = (
			<div className="feedback-list">
				{pastTenants.map((item) => (
					<TenantsItemPast key={item.id} item={item} />
				))}
			</div>
		)
	}

	return (
		<>
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
			<h3 style={{ marginTop: '1rem' }}>Penyewa lama {property_name}</h3>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{tenantInfo}
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
		</>
	)
}

export default TenantsListPast
