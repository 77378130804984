import PropTypes from 'prop-types'

function Card({ children, reverse, cssClass }) {
	//example with conditional class
	//return <div className={`card ${reverse && 'reverse'}`}>{children}</div>
	//example with conditional style
	return (
		<div
			className={cssClass}
			style={{
				backgroundColor: reverse ? 'rgba(0,0,0,0.4)' : '#fff',
				color: reverse ? '#fff' : '#000',
			}}
		>
			{children}
		</div>
	)
}

Card.defaultProps = {
	reverse: false,
	cssClass: 'card',
}

Card.propTypes = {
	children: PropTypes.node.isRequired,
	reverse: PropTypes.bool,
	cssClass: PropTypes.string.isRequired,
}

export default Card
