import { useContext } from 'react'
import {
	FaChevronRight,
	FaHotel,
	FaBroom,
	FaSignOutAlt,
	FaUserTimes,
	// FaUser,
	// FaUsers,
	FaKey,
	FaCreditCard,
	FaGavel,
	FaFileInvoice,
	FaWhatsapp,
	FaLock,
	// FaHistory,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'

function SettingPage() {
	const { userData } = useContext(AuthContext)
	const { role, admin_access } = userData

	let propertyLink = ''
	if (role === 'owner') {
		propertyLink = (
			<Link to={`/properties`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaHotel /> Atur Properti
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
		)
	}

	let serviceLink = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.services === 'yes')
	) {
		serviceLink = (
			<Link to={`/services`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaBroom /> Layanan Tambahan
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
		)
	}

	let paymentmethodLink = ''
	if (role === 'owner') {
		paymentmethodLink = (
			<Link to={`/payment_methods`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaCreditCard /> Metode Pembayaran
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
		)
	}

	let adminaccessLink = ''
	if (role === 'owner') {
		adminaccessLink = (
			<Link to={`/adminaccess`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaKey /> Kelola Admin
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
		)
	}

	let billingLink = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.billing === 'yes')
	) {
		billingLink = (
			<Link to={`/billings`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaFileInvoice /> Langganan
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
		)
	}

	return (
		<>
			<div>
				<h2 className="pageTitle">Pengaturan Properti</h2>
			</div>
			{propertyLink}
			{serviceLink}
			{paymentmethodLink}
			{/* <Link to={`/`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaGavel /> Peraturan Sewa
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link> */}
			{adminaccessLink}
			{/* <Link to={`/`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaHistory /> Log Aktivitas
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link> */}
			<div>
				<h2 className="pageTitle">Langganan & Lain-Lain</h2>
			</div>
			{billingLink}
			{/* <Link to={`/`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaUser />
							Profil Saya
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link> */}
			<a
				href={`https://api.whatsapp.com/send?phone=6281390085453`}
				target="_blank"
				rel="noreferrer"
			>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaWhatsapp /> Bantuan & Masukan
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</a>
			<a
				href={`https://www.adminkos.com/page/syarat-dan-ketentuan`}
				target="_blank"
				rel="noreferrer"
			>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaGavel /> Syarat dan ketentuan
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</a>
			<a
				href={`https://www.adminkos.com/page/kebijakan-privasi`}
				target="_blank"
				rel="noreferrer"
			>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaLock /> Kebijakan privasi
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</a>
			<Link to={`/logout`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaSignOutAlt /> Keluar
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
			<Link to={`/delete-user`}>
				<Card reverse={false} cssClass="card">
					<div className="card2Flex">
						<div className="text-title-smaller">
							<FaUserTimes /> Hapus Akun
						</div>
						<FaChevronRight color="#f55c0a" size={20} />
					</div>
				</Card>
			</Link>
		</>
	)
}

export default SettingPage
