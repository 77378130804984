import { useContext, useState } from 'react'

import AuthContext from '../context/AuthContext'
import PropertiesContext from '../context/PropertiesContext'

function PropertySelect() {
	const { properties } = useContext(PropertiesContext)
	const { activeProperty, setActiveProperty } = useContext(AuthContext)
	const { property_id } = activeProperty

	const [propertyId, setPropertyId] = useState(property_id)

	const onChange = (e) => {
		setPropertyId(e.target.value)
		setActiveProperty({
			property_id: e.target.value,
			property_name: e.target.options[e.target.selectedIndex].text,
		})
	}

	return (
		<div className="inputGroup">
			<div className="inputLabel">
				<label>
					<b>Pilih Properti</b>
				</label>
			</div>
			<select
				value={propertyId}
				onChange={onChange}
				className="select"
				required
			>
				{properties &&
					properties.map((item) => (
						<option key={item.id} value={item.id}>
							{item.name}
						</option>
					))}
			</select>
		</div>
	)
}

export default PropertySelect
