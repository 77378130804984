import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

import BillingItem from './BillingItem'
import Spinner2 from '../../components/Spinner2'
import AuthContext from '../../context/AuthContext'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function BillingList() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	const [isLoading, setIsLoading] = useState(false)
	const [billings, setBillings] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin' && admin_access.billing === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role, admin_access])

	useEffect(() => {
		if (property_id) {
			getAllBillings(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all billings from API
	const getAllBillings = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/billing/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setBillings(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	let billingInfo

	if (billings && billings.length === 0) {
		billingInfo = <p>Belum ada tagihan.</p>
	} else if (billings && billings.length > 0) {
		billingInfo = (
			<div className="feedback-list">
				{billings.map((item) => (
					<BillingItem
						key={item.id}
						item={item}
						getAllBillings={getAllBillings}
					/>
				))}
			</div>
		)
	}

	return (
		<>
			<Link to="/setting">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">Langganan</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{billingInfo}
		</>
	)
}

export default BillingList
