import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	FaChevronLeft,
	FaTimes,
	FaUserAlt,
	FaEye,
	FaQuestionCircle,
} from 'react-icons/fa'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import imageCompression from 'browser-image-compression'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
import Modal from 'react-modal'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Switch from 'react-switch'

import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'
import TenantsServicesForm from './TenantsServicesForm'

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

const customStyles = {
	content: {
		bottom: 'auto',
		background: '#672f0a',
		borderRadius: '10px',
		inset: '20px 20px auto',
		zIndex: 1,
		maxWidth: '600px',
		maxHeight: '95%',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function TenantsAdd() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty
	const { role, admin_access } = userData

	const [services, setServices] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [modal2IsOpen, setModal2IsOpen] = useState(false)
	const [rentalStart, setRentalStart] = useState('')
	const [formData, setFormData] = useState({
		room_id: '',
		room_name: '',
		tenant_past_id: '',
		name: '',
		handphone: '',
		photo_image: '',
		photo_url: '',
		identity_image: '',
		identity_url: '',
		chosenServices: [],
		rental_price: '',
		deposit: '',
		penalty: '',
		duration: '',
		durationWording: '',
		note: '',
		send_whatsapp: false,
	})
	const {
		room_id,
		room_name,
		tenant_past_id,
		name,
		handphone,
		photo_image,
		photo_url,
		identity_image,
		identity_url,
		chosenServices,
		rental_price,
		deposit,
		penalty,
		duration,
		durationWording,
		note,
		send_whatsapp,
	} = formData
	const [availableRooms, setAvailableRooms] = useState([])
	const [room, setRoom] = useState({
		name: '',
		description: '',
		price_monthly: '',
		price_weekly: '',
		price_quarterly: '',
		deposit: '',
		penalty: '',
		status: '',
	})
	const [pastTenants, setPastTenants] = useState([])

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin' && admin_access.tenants_add === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role, admin_access])

	useEffect(() => {
		if (property_id) {
			getAvailableRooms(property_id)
		}
		//eslint-disable-next-line
	}, [property_id, room_id])

	useEffect(() => {
		if (property_id) {
			getAllServices(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	useEffect(() => {
		if (property_id) {
			getPastTenants(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	useEffect(() => {
		//if update (edit) only
		if (room) {
			setFormData((prevState) => ({
				...prevState,
				deposit: addCommas(removeNonNumeric(room.deposit)),
				penalty: room.penalty,
			}))

			//count if duration option only 1 or more > 1
			let count_duration = 0
			let use_monthly = false
			let use_weekly = false
			let use_quarterly = false

			if (room.price_monthly) {
				count_duration++
				use_monthly = true
			}

			if (room.price_weekly) {
				count_duration++
				use_weekly = true
			}

			if (room.price_quarterly) {
				count_duration++
				use_quarterly = true
			}

			if (count_duration === 1) {
				if (use_monthly) {
					setFormData((prevState) => ({
						...prevState,
						duration: 'month',
						durationWording: 'Bulanan',
						chosenServices: [],
						rental_price: addCommas(removeNonNumeric(room.price_monthly)),
					}))
				} else if (use_weekly) {
					setFormData((prevState) => ({
						...prevState,
						duration: 'week',
						durationWording: 'Mingguan',
						chosenServices: [],
						rental_price: addCommas(removeNonNumeric(room.price_weekly)),
					}))
				} else if (use_quarterly) {
					setFormData((prevState) => ({
						...prevState,
						duration: 'quarter',
						durationWording: '3 Bulanan',
						chosenServices: [],
						rental_price: addCommas(removeNonNumeric(room.price_quarterly)),
					}))
				}
			}

			switch (duration) {
				case 'month':
					setFormData((prevState) => ({
						...prevState,
						rental_price: addCommas(removeNonNumeric(room.price_monthly)),
					}))
					break
				case 'week':
					setFormData((prevState) => ({
						...prevState,
						rental_price: addCommas(removeNonNumeric(room.price_weekly)),
					}))
					break
				case 'quarter':
					setFormData((prevState) => ({
						...prevState,
						rental_price: addCommas(removeNonNumeric(room.price_quarterly)),
					}))
					break
				default:
					break
			}
		}
		//eslint-disable-next-line
	}, [room])

	//get all past tenants from API
	const getPastTenants = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/allinactive?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setPastTenants(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get all Services from API
	const getAllServices = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/available?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setServices(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get only available Rooms (non booked) from API
	const getAvailableRooms = async (property_id, curr_roomid = '') => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/available?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}&curr_roomid=${curr_roomid}`

			const config = configData()

			const res = await axios.get(url, config)

			setAvailableRooms(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get single room from API
	const getRoom = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/detail?user_id=${tokens[2]}&room_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setRoom({
				name: res.data.data.name,
				description: res.data.data.description,
				price_monthly: res.data.data.price_monthly,
				price_weekly: res.data.data.price_weekly,
				price_quarterly: res.data.data.price_quarterly,
				deposit: res.data.data.deposit,
				penalty: res.data.data.penalty,
				status: res.data.data.status,
			})
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new Tenant
	const addTenant = async (property_id, newTenant) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			let total_price
			if (newTenant.services_price) {
				total_price =
					parseInt(newTenant.rental_price) + parseInt(newTenant.services_price)
			} else {
				total_price = newTenant.rental_price
			}

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('room_id', newTenant.room_id)
			formData.append('name', newTenant.name)
			formData.append('handphone', newTenant.handphone)
			formData.append('identity_image', newTenant.identity_image)
			formData.append('photo_image', newTenant.photo_image)
			formData.append(
				'chosen_services',
				JSON.stringify(newTenant.chosenServices)
			)
			formData.append('rental_price', newTenant.rental_price)
			formData.append('total_price', total_price)
			formData.append('duration', newTenant.duration)
			formData.append('note', newTenant.note)
			formData.append('send_whatsapp', newTenant.send_whatsapp)
			formData.append('rental_start', newTenant.rental_start)
			if (newTenant.past_id) {
				formData.append('past_id', newTenant.past_id)
			}
			if (newTenant.deposit) {
				formData.append('deposit', newTenant.deposit)
			}
			if (newTenant.penalty) {
				formData.append('penalty', newTenant.penalty)
			}

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				navigate('/tenants')

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	function openModal() {
		setModalIsOpen(true)
	}

	function closeModal() {
		setModalIsOpen(false)
	}

	function openModal2() {
		setModal2IsOpen(true)
	}

	function closeModal2() {
		setModal2IsOpen(false)
	}

	const onChange = (e) => {
		if (e.target.id === 'room_id') {
			if (e.target.value !== '') {
				if (property_id) {
					getRoom(property_id, e.target.value)
				}
			} else {
				setFormData((prevState) => ({
					...prevState,
					deposit: '',
					penalty: '',
				}))
			}

			setFormData((prevState) => ({
				...prevState,
				duration: '',
				durationWording: '',
				rental_price: '',
				chosenServices: [],
			}))
		}

		if (e.target.id === 'duration') {
			switch (e.target.value) {
				case 'month':
					setFormData((prevState) => ({
						...prevState,
						durationWording: e.target.options[e.target.selectedIndex].text,
						chosenServices: [],
						rental_price: addCommas(removeNonNumeric(room.price_monthly)),
					}))
					break
				case 'week':
					setFormData((prevState) => ({
						...prevState,
						durationWording: e.target.options[e.target.selectedIndex].text,
						chosenServices: [],
						rental_price: addCommas(removeNonNumeric(room.price_weekly)),
					}))
					break
				case 'quarter':
					setFormData((prevState) => ({
						...prevState,
						durationWording: e.target.options[e.target.selectedIndex].text,
						chosenServices: [],
						rental_price: addCommas(removeNonNumeric(room.price_quarterly)),
					}))
					break
				default:
					setFormData((prevState) => ({
						...prevState,
						chosenServices: [],
						rental_price: '',
					}))
					break
			}
		}

		let targetValue

		if (
			e.target.id === 'rental_price' ||
			e.target.id === 'services_price' ||
			e.target.id === 'deposit' ||
			e.target.id === 'penalty'
		) {
			targetValue = addCommas(removeNonNumeric(e.target.value))
		} else {
			targetValue = e.target.value
		}

		if (e.target.id === 'room_id') {
			setFormData((prevState) => ({
				...prevState,
				room_name: e.target.options[e.target.selectedIndex].text,
			}))
		}

		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: targetValue,
		}))
	}

	//onchange identity image
	const onChangeImage = async (e) => {
		const imageFile = e.target.files[0]

		if (!imageFile.type.startsWith('image')) {
			toast.error('Tipe gambar harus jpg/png', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		} else {
			const options = {
				maxSizeMB: 0.8,
				maxWidthOrHeight: 600,
				useWebWorker: true,
				onProgress: () => null,
			}
			try {
				const compressedBlob = await imageCompression(imageFile, options)

				const compressedFile = new File([compressedBlob], imageFile.name, {
					lastModified: compressedBlob.lastModified,
					type: 'image/jpeg',
				})

				setFormData((prevState) => ({
					...prevState,
					identity_image: compressedFile,
				}))
			} catch (error) {
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		}
	}

	//onchange photo image
	const onChangePhoto = async (e) => {
		const imageFile = e.target.files[0]

		if (!imageFile.type.startsWith('image')) {
			toast.error('Tipe gambar harus jpg/png', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		} else {
			const options = {
				maxSizeMB: 0.8,
				maxWidthOrHeight: 600,
				useWebWorker: true,
				onProgress: () => null,
			}
			try {
				const compressedBlob = await imageCompression(imageFile, options)

				const compressedFile = new File([compressedBlob], imageFile.name, {
					lastModified: compressedBlob.lastModified,
					type: 'image/jpeg',
				})

				setFormData((prevState) => ({
					...prevState,
					photo_image: compressedFile,
				}))
			} catch (error) {
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!name || !handphone || !room_id || !rentalStart || !duration) {
			toast.error('Mohon lengkapi isian yang diwajibkan.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const twoCharsHp = handphone.substring(0, 2)

		if (twoCharsHp !== '08') {
			toast.error('Nomor Hp harus dimulai dengan 08.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		if (rental_price === '0' || rental_price === '') {
			toast.error('Harga sewa tidak boleh 0 atau kosong.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		//check chosenService id must not be the same
		const uniqueServices = new Set(chosenServices.map((service) => service.id))

		if (uniqueServices.size < chosenServices.length) {
			toast.error(
				'Nama layanan tidak boleh sama. Silahkan pilih layanan lainnya.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		openModal()
	}

	const submitForm = () => {
		const newTenant = {
			name,
			handphone,
			identity_image,
			photo_image,
			chosenServices,
			rental_price:
				rental_price !== '' ? parseInt(rental_price.replace(/\./g, '')) : '',
			note,
			send_whatsapp,
		}

		newTenant.room_id = room_id
		newTenant.rental_start = moment(rentalStart).format('YYYY-MM-DD')

		newTenant.deposit =
			deposit !== '' ? parseInt(deposit.replace(/\./g, '')) : ''

		newTenant.penalty = penalty !== '' ? parseInt(penalty) : ''

		newTenant.duration = duration

		if (tenant_past_id) {
			newTenant.past_id = tenant_past_id
		}

		addTenant(property_id, newTenant)
	}

	const onAddService = (e) => {
		e.preventDefault()

		let newServices = [...chosenServices]

		let allowPush = true
		newServices.forEach((service) => {
			if (service.id === '' || service.price === '' || service.price === null) {
				toast.error('Mohon lengkapi dahulu layanan dan harga.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})

				allowPush = false
				return false
			}
		})

		if (allowPush) {
			newServices.push({
				id: '',
				name: '',
				price: '',
			})

			setFormData((prevState) => ({
				...prevState,
				chosenServices: newServices,
			}))
		}
	}

	const onEditService = (index, item) => {
		let newServices = [...chosenServices]

		newServices[index].id = item.id
		newServices[index].name = item.name
		newServices[index].price = item.price
			? addCommas(removeNonNumeric(item.price))
			: ''

		setFormData((prevState) => ({
			...prevState,
			chosenServices: newServices,
		}))
	}

	const onDeleteService = (index) => {
		const newServices = [...chosenServices]

		newServices.splice(index, 1)

		setFormData((prevState) => ({
			...prevState,
			chosenServices: newServices,
		}))
	}

	const modalOldTenant = () => {
		openModal2()
	}

	const chooseOldTenant = (index) => {
		setFormData((prevState) => ({
			...prevState,
			tenant_past_id: pastTenants[index].id,
			name: pastTenants[index].name,
			handphone: pastTenants[index].handphone,
			identity_url: pastTenants[index].identity_image,
			photo_url: pastTenants[index].photo_image,
			note: pastTenants[index].note,
		}))

		closeModal2()
	}

	const onClickImage = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-alert">
						<p>
							<img
								src={
									process.env.REACT_APP_APIURL +
									'uploads/identity/' +
									identity_url
								}
								alt="foto identitas"
							/>
						</p>
						<button className="btn btn-small btn-secondary" onClick={onClose}>
							Tutup
						</button>
						<div className="clear"></div>
					</div>
				)
			},
		})
	}

	const onClickPhoto = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-alert">
						<p>
							<img
								src={
									process.env.REACT_APP_APIURL + 'uploads/photo/' + photo_url
								}
								alt="foto penyewa"
							/>
						</p>
						<button className="btn btn-small btn-secondary" onClick={onClose}>
							Tutup
						</button>
						<div className="clear"></div>
					</div>
				)
			},
		})
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading) {
		buttonState = 'Yakin, Tambahkan'
	}

	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})

	let durationText

	switch (duration) {
		case 'month':
			durationText = 'Bulan'
			break
		case 'week':
			durationText = 'Minggu'
			break
		case 'quarter':
			durationText = '3 Bulan'
			break
		default:
			durationText = 'Durasi'
			break
	}

	let rentalPriceInt = 0
	let servicesPriceInt = 0

	if (rental_price) {
		rentalPriceInt = parseInt(rental_price.replace(/\./g, ''))
	}

	if (chosenServices.length > 0) {
		chosenServices.forEach((service) => {
			servicesPriceInt =
				servicesPriceInt + parseInt(service.price.replace(/\./g, ''))
		})
	}

	const onChangeSwitch = (isChecked, event, id) => {
		switch (id) {
			//tenants
			case 'send_whatsapp':
				setFormData((prevState) => ({
					...prevState,
					send_whatsapp: !send_whatsapp,
				}))
				break
			default:
				break
		}
	}

	let sendWaMessage = ''

	if (send_whatsapp) {
		sendWaMessage = (
			<p>
				AdminKos akan mengirim chat tagihan, kwitansi, dan pengingat pembayaran
				ke Hp WhatsApp penyewa sesuai dengan jadwal pengiriman WA.
			</p>
		)
	} else {
		sendWaMessage = (
			<p>
				AdminKos tidak akan mengirim chat tagihan, kwitansi, dan pengingat
				pembayaran ke HP WhatsApp penyewa.
			</p>
		)
	}

	return (
		<>
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<h2 className="pageTitle">Data Penyewa</h2>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Penyewa</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Nama penyewa"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
					{pastTenants.length > 0 && (
						<div style={{ marginBottom: '10px', marginTop: '10px' }}>
							<button
								type="button"
								onClick={modalOldTenant}
								className="btn btn-small btn-secondary"
							>
								<FaUserAlt /> Penyewa sebelumnya
							</button>
						</div>
					)}
				</div>

				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Handphone (WA)</b>
						</label>
					</div>
					<input
						type="number"
						className="textInput"
						placeholder="Nomor WA 08XXXXXXXXXX"
						id="handphone"
						value={handphone}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div
						className="inputLabel"
						data-tooltip-id="q-sendwa"
						data-tooltip-content="AdminKos akan mengirim WhatsApp otomatis ke penyewa, seperti tagihan, kwitansi dan pengingat pembayaran."
					>
						<label>
							<b>Kirim WhatsApp ke penyewa</b> <FaQuestionCircle />
						</label>
						<Tooltip id="q-sendwa" style={{ maxWidth: '70%' }} />
					</div>
					<Switch
						id="send_whatsapp"
						width={70}
						onChange={onChangeSwitch}
						checked={send_whatsapp}
					/>
				</div>
				<h2 className="pageTitle">Data Kamar & Sewa</h2>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Pilih Kamar</b>
							</label>
						</div>
						<select
							id="room_id"
							value={room_id}
							onChange={onChange}
							className="select"
							required
						>
							<option value="">Pilih kamar</option>
							{availableRooms &&
								availableRooms.map((item) => (
									<option key={item.id} value={item.id}>
										Kamar {item.name}
									</option>
								))}
						</select>
					</div>
					<div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-paydate"
							data-tooltip-content="Penyewa baru: Pilih tanggal masuk. Penyewa lama: Pilih tanggal jatuh tempo terakhir dimana penyewa belum bayar."
						>
							<label>
								<b>Tgl Masuk/Due</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-paydate" style={{ maxWidth: '70%' }} />
						</div>
						<DatePicker
							selected={rentalStart}
							required
							onChange={(date) => setRentalStart(date)}
							dateFormat="dd-MM-yyyy"
							onKeyDown={(e) => {
								e.preventDefault()
							}}
							placeholderText="Pilih tanggal"
							onFocus={(e) => e.target.blur()}
						/>
					</div>
				</div>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Durasi Sewa</b>
							</label>
						</div>
						<select
							id="duration"
							value={duration}
							onChange={onChange}
							className="select"
							required
						>
							<option value="">Pilih durasi</option>
							<option value="month">Bulanan</option>
							<option value="week">Mingguan</option>
							<option value="quarter">3 bulanan</option>
						</select>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Sewa/{durationText} (Rp)</b>
							</label>
						</div>
						<NumericFormat
							value={rental_price}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga sewa"
							id="rental_price"
							onChange={onChange}
							required
						/>
					</div>

					{/* <div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-deposit"
							data-tooltip-content="Deposit adalah uang jaminan yang akan dikembalikan di akhir sewa."
						>
							<label>
								<b>Deposit (Rp)</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-deposit" style={{ maxWidth: '70%' }} />
						</div>
						<NumericFormat
							value={deposit}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Opsional"
							id="deposit"
							onChange={onChange}
						/>
					</div> */}

					{/* <div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-penalty"
							data-tooltip-content="Denda keterlambatan pembayaran (%) dari total harga sewa dan layanan."
						>
							<label style={{ cursor: 'pointer' }}>
								<b>Denda (%) </b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-penalty" style={{ maxWidth: '70%' }} />
						</div>
						<NumericFormat
							value={penalty}
							className="textInput"
							thousandSeparator="." 
							decimalSeparator=","
							placeholder="Opsional"
							id="penalty"
							onChange={onChange}
						/>
					</div> */}
				</div>
				<h2 className="pageTitle">Layanan Tambahan</h2>
				<div className="inputGroup">
					<div className="inputLabel inputLabel2cols">
						<label style={{ paddingBottom: '0px' }}>
							<b>Layanan (opsional)</b>
							{services && services.length === 0 && (
								<>
									<br />
									Anda belum mengisi layanan. Silahkan{' '}
									<Link to="/services">Isi Disini</Link>.
								</>
							)}
						</label>
						<button
							onClick={onAddService}
							type="button"
							className="btn btn-secondary btn-small"
						>
							Tambah
						</button>
					</div>
					{chosenServices.length > 0 &&
						chosenServices.map((item, index) => (
							<TenantsServicesForm
								key={index}
								item={item}
								index={index}
								duration={duration}
								deleteService={onDeleteService}
								editService={onEditService}
							/>
						))}
				</div>
				<h2 className="pageTitle">Data Tambahan</h2>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Identitas KTP, SIM (opsional, jpg/png)</b>
						</label>
					</div>
					<input
						type="file"
						className="textInput fileInput"
						id="identity_image"
						onChange={onChangeImage}
					/>
					{identity_url && (
						<div style={{ marginBottom: '10px', marginTop: '10px' }}>
							<button
								type="button"
								onClick={onClickImage}
								className="btn btn-small btn-secondary"
								style={{ width: '160px' }}
							>
								<FaEye /> Lihat Gambar
							</button>
						</div>
					)}
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Foto Penyewa (opsional, jpg/png)</b>
						</label>
					</div>
					<input
						type="file"
						className="textInput fileInput"
						id="photo_image"
						onChange={onChangePhoto}
					/>
					{photo_url && (
						<div style={{ marginBottom: '10px', marginTop: '10px' }}>
							<button
								type="button"
								onClick={onClickPhoto}
								className="btn btn-small btn-secondary"
								style={{ width: '160px' }}
							>
								<FaEye /> Lihat Foto
							</button>
						</div>
					)}
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Catatan Tambahan</b>
						</label>
					</div>
					<textarea
						className="textarea"
						placeholder="Contoh: 2 penghuni, suami istri, karyawan swasta"
						id="note"
						value={note}
						onChange={onChange}
					/>
				</div>
				<button
					type="submit"
					className="btn btn-secondary btn-block"
					disabled={isLoading}
				>
					Tambah Penyewa
				</button>
			</form>
			<br />
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Rangkuman</h2>
				<table
					style={{
						tableLayout: 'fixed',
						width: '100%',
					}}
				>
					<tbody>
						<tr className="cellHeader">
							<td colSpan="2">
								<strong>Penyewa & Kamar</strong>
							</td>
						</tr>
						{name && (
							<tr>
								<td>Penyewa</td>
								<td>{name}</td>
							</tr>
						)}
						{handphone && (
							<tr>
								<td>Handphone</td>
								<td>{handphone}</td>
							</tr>
						)}
						{note && (
							<tr>
								<td>Catatan Tambahan</td>
								<td>{note}</td>
							</tr>
						)}
						{property_name && (
							<tr>
								<td>Properti</td>
								<td>{property_name}</td>
							</tr>
						)}
						{room_name && (
							<tr>
								<td>Kamar</td>
								<td>{room_name}</td>
							</tr>
						)}
						<tr className="cellHeader">
							<td colSpan="2">
								<strong>Informasi Sewa</strong>
							</td>
						</tr>
						{duration && (
							<tr>
								<td>Durasi Sewa</td>
								<td>{durationWording}</td>
							</tr>
						)}
						{/* {deposit && (
							<tr>
								<td>Deposit</td>
								<td>
									{formatter.format(parseInt(deposit.replace(/\./g, '')))}
								</td>
							</tr>
						)} */}
						{/* {penalty && (
							<tr>
								<td>Denda</td>
								<td>{parseInt(penalty)} %</td>
							</tr>
						)} */}
						{rental_price && (
							<tr>
								<td>Sewa / {durationText}</td>
								<td>
									{formatter.format(parseInt(rental_price.replace(/\./g, '')))}
								</td>
							</tr>
						)}
						{rentalStart && (
							<tr>
								<td>Jatuh Tempo</td>
								<td>{moment(rentalStart).format('DD MMM YYYY')}</td>
							</tr>
						)}
						{chosenServices.length > 0 && (
							<tr className="cellHeader">
								<td colSpan="2">
									<strong>Layanan Tambahan</strong>
								</td>
							</tr>
						)}
						{chosenServices.length > 0 &&
							chosenServices.map((service, index) => (
								<tr key={index}>
									<td>{service.name}</td>
									<td>
										{formatter.format(
											parseInt(service.price.replace(/\./g, ''))
										)}
									</td>
								</tr>
							))}
						<tr className="cellHeader">
							<td colSpan="2">
								<strong>Total</strong>
							</td>
						</tr>
						<tr>
							<td>
								<strong>Total / {durationText}</strong>
							</td>
							<td>
								<strong>
									{formatter.format(rentalPriceInt + servicesPriceInt)}
								</strong>
							</td>
						</tr>
					</tbody>
				</table>
				<br />
				{sendWaMessage}
				<p>Apakah Anda sudah yakin dengan data diatas?</p>
				<div className="inputGroup2cols">
					<button className="btn btn-block btn-cancel" onClick={closeModal}>
						<FaTimes /> Batal
					</button>
					<button
						className="btn btn-block btn-secondary"
						onClick={submitForm}
						disabled={isLoading}
					>
						{buttonState}
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modal2IsOpen}
				onRequestClose={closeModal2}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Pilih penyewa sebelumnya</h2>
				<table
					style={{
						tableLayout: 'fixed',
						width: '100%',
					}}
				>
					<tbody>
						<tr>
							<th>Nama</th>
							<th>Handphone</th>
						</tr>
						{pastTenants.length > 0 &&
							pastTenants.map((tenant, index) => (
								<tr key={tenant.id}>
									<td
										onClick={() => chooseOldTenant(index)}
										style={{
											cursor: 'pointer',
											color: '#f55c0a',
											fontWeight: 'bold',
										}}
									>
										{tenant.name}
									</td>
									<td>{tenant.handphone}</td>
								</tr>
							))}
					</tbody>
				</table>
				<button className="btn btn-block btn-cancel" onClick={closeModal2}>
					<FaTimes /> Batal
				</button>
			</Modal>
		</>
	)
}

export default TenantsAdd
