import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { FaPlus, FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom'

import AdminAccessItem from './AdminAccessItem'
import Button from '../../components/shared/Button'
import Spinner2 from '../../components/Spinner2'
import AuthContext from '../../context/AuthContext'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function AdminAccessList() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role } = userData

	const [adminaccess, setAdminAccess] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role])

	useEffect(() => {
		if (property_id) {
			getAllAdminAccess()
		}
		//eslint-disable-next-line
	}, [property_id])

	//get all properties from API
	const getAllAdminAccess = async () => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/admin_access/all?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setAdminAccess(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//delete admin
	const deleteAdminAccess = async (id) => {
		const tokens = JSON.parse(localStorage.getItem('tokens'))

		confirmAlert({
			title: 'Yakin mau hapus?',
			message: 'Data admin ini akan terhapus selamanya.',
			buttons: [
				{
					label: 'Batal',
					onClick: () => null,
				},
				{
					label: 'Hapus',
					onClick: async () => {
						setIsLoading(true)

						try {
							const url = `${process.env.REACT_APP_APIURL}v1/admin_access/delete?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

							const config = configData()

							let formData = new FormData()
							formData.append('property_id', property_id)
							formData.append('admin_id', id)

							const res = await axios.post(url, formData, config)

							if (res.status === 200) {
								getAllAdminAccess()

								toast.success('Sukses menghapus admin.', {
									position: toast.POSITION.BOTTOM_RIGHT,
									autoClose: 2000,
									theme: 'colored',
								})
							}
						} catch (err) {
							toast.error(err.response.data.message, {
								position: toast.POSITION.BOTTOM_RIGHT,
								autoClose: 2000,
								theme: 'colored',
							})
						}

						setIsLoading(false)
					},
				},
			],
		})
	}

	let adminInfo

	if (adminaccess && adminaccess.length === 0) {
		adminInfo = <p>Belum ada Admin. Silahkan tambah baru.</p>
	} else if (adminaccess && adminaccess.length > 0) {
		adminInfo = (
			<div className="feedback-list">
				{adminaccess.map((item) => (
					<AdminAccessItem
						key={item.id}
						item={item}
						deleteAdminAccess={deleteAdminAccess}
					/>
				))}
			</div>
		)
	}

	return (
		<>
			<Link to="/setting">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">Kelola Admin</h2>
			<p>Staf admin dan akses yang dapat membantu Anda mengelola properti.</p>
			<Link to="/adminaccess/edit">
				<Button type="button" version="block" isDisabled={false}>
					<FaPlus /> Tambah Admin
				</Button>
			</Link>
			<h3>Daftar Admin</h3>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{adminInfo}
		</>
	)
}

export default AdminAccessList
