import { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Button from '../components/shared/Button'
import AuthContext from '../context/AuthContext'
import Spinner from '../components/Spinner'

function OtpPage() {
	const { userData, verifyOtp, toOtpPage, setToOtpPage, isLoading } =
		useContext(AuthContext)
	const { isAuthenticated, user_id } = userData

	const [otp, setOtp] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		if (!toOtpPage) {
			navigate('/register')
		}
		//eslint-disable-next-line
	}, [toOtpPage])

	useEffect(() => {
		if (isAuthenticated && user_id) {
			navigate('/')
		} else if (!isAuthenticated && !user_id) {
			navigate('/login')
		}
		//eslint-disable-next-line
	}, [isAuthenticated, user_id])

	const onChange = (e) => {
		setOtp(e.target.value)
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (otp.length !== 6) {
			toast.error('Kode OTP harus 6 angka.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		verifyOtp(otp)
	}

	const toRegisterPage = (e) => {
		e.preventDefault()
		setToOtpPage(false)
	}

	return (
		<>
			<div className="container">
				<h2 className="pageTitle">Kode OTP</h2>
				<form onSubmit={handleSubmit} autoComplete="off">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Masukan kode OTP yang dikirimkan ke WA Anda.</b>
							</label>
						</div>
						<input
							type="number"
							className="textInput"
							placeholder="Isi kode OTP"
							id="otp"
							value={otp}
							onChange={onChange}
							required
						/>
					</div>
					<Button type="submit" version="block" isDisabled={isLoading}>
						{isLoading ? <Spinner /> : 'Verifikasi'}
					</Button>
				</form>
				<p className="registerLink">
					<Link onClick={toRegisterPage}>
						<span style={{ textDecoration: 'underline' }}>
							atau daftar/login ulang
						</span>
					</Link>
				</p>
			</div>
		</>
	)
}

export default OtpPage
