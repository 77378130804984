import { useContext } from 'react'
import PropTypes from 'prop-types'
import { FaTimesCircle, FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

function ServicesItem({ item, deleteService }) {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	let monthlyFee
	let weeklyFee
	let quarterlyFee
	if (item.price_monthly > 0) {
		monthlyFee = (
			<span className="priceBorder">
				{formatter.format(item.price_monthly)}/bulan
			</span>
		)
	}
	if (item.price_weekly > 0) {
		weeklyFee = (
			<span className="priceBorder">
				{formatter.format(item.price_weekly)}/minggu
			</span>
		)
	}
	if (item.price_quarterly > 0) {
		quarterlyFee = (
			<span className="priceBorder">
				{formatter.format(item.price_quarterly)}/3 bulan
			</span>
		)
	}

	let deleteServiceButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.services_delete === 'yes')
	) {
		deleteServiceButton = (
			<button
				className="btn btn-small btn-danger btnNoMargin"
				onClick={() => deleteService(property_id, item.id)}
			>
				<FaTimesCircle /> Hapus
			</button>
		)
	}

	return (
		<Card reverse={false}>
			<div className="text-title">{item.name}</div>
			<div className="text-display">
				{monthlyFee} {weeklyFee} {quarterlyFee}
			</div>
			<div style={{ marginTop: '10px' }}>
				<Link to={`/services/edit/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaEdit /> Ubah
					</button>
				</Link>
				{deleteServiceButton}
			</div>
		</Card>
	)
}

ServicesItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default ServicesItem
