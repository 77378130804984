import { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
	FaChevronLeft,
	FaEye,
	FaTimes,
	FaEdit,
	FaQuestionCircle,
} from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import imageCompression from 'browser-image-compression'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
import Modal from 'react-modal'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Switch from 'react-switch'

import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'
import TenantsServicesForm from './TenantsServicesForm'
import Card from '../../components/shared/Card'

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

const customStyles = {
	content: {
		bottom: 'auto',
		background: '#672f0a',
		borderRadius: '10px',
		inset: '20px 20px auto',
		zIndex: 1,
		maxWidth: '600px',
		maxHeight: '95%',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function TenantsEdit() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id, property_name } = activeProperty
	const { role, admin_access } = userData

	const [services, setServices] = useState([])
	const [chosenServices, setChosenServices] = useState([])
	const [isAddService, setIsAddService] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [rentalStart, setRentalStart] = useState('')
	const [formData, setFormData] = useState({
		room_id: '',
		room_name: '',
		name: '',
		handphone: '',
		identity_image: '',
		identity_url: '',
		photo_image: '',
		photo_url: '',
		rental_price: '',
		deposit: '',
		penalty: '',
		duration: '',
		durationWording: '',
		note: '',
		rental_end: '',
		status: '',
		send_whatsapp: false,
	})
	const {
		room_id,
		room_name,
		name,
		handphone,
		identity_image,
		identity_url,
		photo_image,
		photo_url,
		rental_price,
		duration,
		durationWording,
		note,
		status,
		rental_end,
		send_whatsapp,
	} = formData
	const [room, setRoom] = useState({
		name: '',
		description: '',
		price_monthly: '',
		price_weekly: '',
		price_quarterly: '',
		deposit: '',
		penalty: '',
		status: '',
	})

	let { id } = useParams()

	useEffect(() => {
		if (id && property_id) {
			getTenant(property_id, id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	useEffect(() => {
		if (id && property_id) {
			if (
				!isAddService &&
				chosenServices.length > 0 &&
				chosenServices[0].id === ''
			) {
				getTenant(property_id, id)
			}
		}
		//eslint-disable-next-line
	}, [chosenServices])

	useEffect(() => {
		if (id && property_id) {
			getAllServices(property_id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	const navigate = useNavigate()

	//get single tenant from API
	const getTenant = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const config = configData()

			const tenant_data = `${process.env.REACT_APP_APIURL}v1/tenant/detail?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}&tenant_id=${id}`

			const res = await axios.get(tenant_data, config)

			setFormData({
				room_id: res.data.data.room_id ? res.data.data.room_id : '',
				room_name: res.data.data.room_name,
				name: res.data.data.name,
				handphone: res.data.data.handphone,
				identity_image: '',
				photo_image: '',
				identity_url: res.data.data.identity_image,
				photo_url: res.data.data.photo_image,
				rental_price: res.data.data.rental_price
					? addCommas(removeNonNumeric(res.data.data.rental_price))
					: '',
				// deposit: res.data.data.deposit
				// 	? addCommas(removeNonNumeric(res.data.data.deposit))
				// 	: '',
				// penalty: res.data.data.penalty ? res.data.data.penalty : '',
				duration: res.data.data.duration,
				durationWording: res.data.data.duration,
				note: res.data.data.note,
				status: res.data.data.status,
				rental_end: res.data.data.rental_end,
				send_whatsapp: res.data.data.send_whatsapp === 'yes' ? true : false,
			})

			switch (res.data.data.duration) {
				case 'month':
					setFormData((prevState) => ({
						...prevState,
						durationWording: 'bulanan',
					}))
					break
				case 'week':
					setFormData((prevState) => ({
						...prevState,
						durationWording: 'mingguan',
					}))
					break
				case 'quarter':
					setFormData((prevState) => ({
						...prevState,
						durationWording: '3 bulanan',
					}))
					break
				default:
					break
			}

			setRentalStart(new Date(res.data.data.rental_start))
			setChosenServices(res.data.data.chosenServices)
		} catch (err) {
			navigate('/tenants')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//update tenant
	const updateWaSwitch = async (property_id, id, send_whatsapp) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/edit_wa_switch?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('tenant_id', id)
			formData.append('send_whatsapp', send_whatsapp)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				getTenant(property_id, id)

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})

				closeModal()
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//update tenant
	const updateTenant = async (property_id, id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			let total_price
			if (updItem.services_price) {
				total_price =
					parseInt(updItem.rental_price) + parseInt(updItem.services_price)
			} else {
				total_price = updItem.rental_price
			}

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('tenant_id', id)
			formData.append('name', updItem.name)
			formData.append('handphone', updItem.handphone)
			formData.append('identity_image', updItem.identity_image)
			formData.append('photo_image', updItem.photo_image)
			formData.append('chosen_services', JSON.stringify(updItem.chosenServices))
			formData.append('rental_price', updItem.rental_price)
			formData.append('total_price', total_price)
			formData.append('note', updItem.note)
			formData.append('send_whatsapp', updItem.send_whatsapp)
			if (updItem.penalty) {
				formData.append('penalty', updItem.penalty)
			}

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				getTenant(property_id, id)

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})

				closeModal()
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get all Services from API
	const getAllServices = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/service/available?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setServices(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get single room from API
	const getRoom = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/room/detail?user_id=${tokens[2]}&room_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setRoom({
				name: res.data.data.name,
				description: res.data.data.description,
				price_monthly: res.data.data.price_monthly,
				price_weekly: res.data.data.price_weekly,
				price_quarterly: res.data.data.price_quarterly,
				// deposit: res.data.data.deposit,
				// penalty: res.data.data.penalty,
				status: res.data.data.status,
			})
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	function openModal() {
		setModalIsOpen(true)
	}

	function closeModal() {
		setModalIsOpen(false)
	}

	const onChange = (e) => {
		if (e.target.id === 'room_id') {
			if (e.target.value !== '') {
				if (property_id) {
					getRoom(property_id, e.target.value)
				}
			} else {
				setFormData((prevState) => ({
					...prevState,
					deposit: '',
					penalty: '',
				}))
			}

			setFormData((prevState) => ({
				...prevState,
				duration: '',
				durationWording: '',
				rental_price: '',
			}))

			setChosenServices([])
		}

		if (e.target.id === 'duration') {
			switch (e.target.value) {
				case 'month':
					setFormData((prevState) => ({
						...prevState,
						durationWording: e.target.options[e.target.selectedIndex].text,
						rental_price: addCommas(removeNonNumeric(room.price_monthly)),
					}))

					setChosenServices([])

					break
				case 'week':
					setFormData((prevState) => ({
						...prevState,
						durationWording: e.target.options[e.target.selectedIndex].text,
						rental_price: addCommas(removeNonNumeric(room.price_weekly)),
					}))

					setChosenServices([])
					break
				case 'quarter':
					setFormData((prevState) => ({
						...prevState,
						durationWording: e.target.options[e.target.selectedIndex].text,
						rental_price: addCommas(removeNonNumeric(room.price_quarterly)),
					}))

					setChosenServices([])
					break
				default:
					setFormData((prevState) => ({
						...prevState,
						rental_price: '',
					}))

					setChosenServices([])
					break
			}
		}

		let targetValue

		if (
			e.target.id === 'rental_price' ||
			e.target.id === 'services_price' ||
			e.target.id === 'deposit' ||
			e.target.id === 'penalty'
		) {
			targetValue = addCommas(removeNonNumeric(e.target.value))
		} else {
			targetValue = e.target.value
		}

		if (e.target.id === 'room_id') {
			setFormData((prevState) => ({
				...prevState,
				room_name: e.target.options[e.target.selectedIndex].text,
			}))
		}

		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: targetValue,
		}))
	}

	const onChangeImage = async (e) => {
		const imageFile = e.target.files[0]

		if (!imageFile.type.startsWith('image')) {
			toast.error('Tipe gambar harus jpg/png', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		} else {
			const options = {
				maxSizeMB: 0.8,
				maxWidthOrHeight: 600,
				useWebWorker: true,
				onProgress: () => null,
			}
			try {
				const compressedBlob = await imageCompression(imageFile, options)

				const compressedFile = new File([compressedBlob], imageFile.name, {
					lastModified: compressedBlob.lastModified,
					type: 'image/jpeg',
				})

				setFormData((prevState) => ({
					...prevState,
					identity_image: compressedFile,
				}))
			} catch (error) {
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		}
	}

	const onChangePhoto = async (e) => {
		const imageFile = e.target.files[0]

		if (!imageFile.type.startsWith('image')) {
			toast.error('Tipe gambar harus jpg/png', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		} else {
			const options = {
				maxSizeMB: 0.8,
				maxWidthOrHeight: 600,
				useWebWorker: true,
				onProgress: () => null,
			}
			try {
				const compressedBlob = await imageCompression(imageFile, options)

				const compressedFile = new File([compressedBlob], imageFile.name, {
					lastModified: compressedBlob.lastModified,
					type: 'image/jpeg',
				})

				setFormData((prevState) => ({
					...prevState,
					photo_image: compressedFile,
				}))
			} catch (error) {
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!name || !handphone || !room_id || !rentalStart || !duration) {
			toast.error('Mohon lengkapi isian yang diwajibkan.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const twoCharsHp = handphone.substring(0, 2)

		if (twoCharsHp !== '08') {
			toast.error('Nomor Hp harus dimulai dengan 08.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		if (rental_price === '0' || rental_price === '') {
			toast.error('Harga sewa tidak boleh 0 atau kosong.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		//check chosenService id must not be the same
		const uniqueServices = new Set(chosenServices.map((service) => service.id))

		if (uniqueServices.size < chosenServices.length) {
			toast.error(
				'Nama layanan tidak boleh sama. Silahkan pilih layanan lainnya.',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				}
			)
			return false
		}

		openModal()
	}

	const submitForm = () => {
		const newTenant = {
			name,
			handphone,
			identity_image,
			photo_image,
			chosenServices,
			rental_price:
				rental_price !== '' ? parseInt(rental_price.replace(/\./g, '')) : '',
			note,
			send_whatsapp,
		}
		// newTenant.penalty = penalty !== '' ? parseInt(penalty) : ''

		if (id && property_id) {
			updateTenant(property_id, id, newTenant)
		}
	}

	const onClickImage = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-alert">
						<p>
							<img
								src={
									process.env.REACT_APP_APIURL +
									'uploads/identity/' +
									identity_url
								}
								alt="foto identitas"
							/>
						</p>
						<button className="btn btn-small btn-secondary" onClick={onClose}>
							Tutup
						</button>
						<div className="clear"></div>
					</div>
				)
			},
		})
	}

	const onClickPhoto = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-alert">
						<p>
							<img
								src={
									process.env.REACT_APP_APIURL + 'uploads/photo/' + photo_url
								}
								alt="foto penyewa"
							/>
						</p>
						<button className="btn btn-small btn-secondary" onClick={onClose}>
							Tutup
						</button>
						<div className="clear"></div>
					</div>
				)
			},
		})
	}

	const onAddService = (e) => {
		e.preventDefault()

		setIsAddService(true)

		let newServices = [...chosenServices]

		let allowPush = true
		newServices.forEach((service) => {
			if (service.id === '' || service.price === '' || service.price === null) {
				toast.error('Mohon lengkapi dahulu layanan dan harga.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})

				allowPush = false
				return false
			}
		})

		if (allowPush) {
			newServices.push({
				id: '',
				name: '',
				price: '',
			})

			setChosenServices(newServices)
		}
	}

	const onEditService = (index, item) => {
		let newServices = [...chosenServices]

		newServices[index].id = item.id
		newServices[index].name = item.name
		newServices[index].price = item.price
			? addCommas(removeNonNumeric(item.price))
			: ''

		setChosenServices(newServices)
	}

	const onDeleteService = (index) => {
		const newServices = [...chosenServices]

		newServices.splice(index, 1)

		setChosenServices(newServices)
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Yakin, Ubah'
	} else if (!isLoading && !id) {
		buttonState = 'Yakin, Tambahkan'
	}

	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})

	let durationText

	switch (duration) {
		case 'month':
			durationText = 'Bulan'
			break
		case 'week':
			durationText = 'Minggu'
			break
		case 'quarter':
			durationText = '3 Bulan'
			break
		default:
			durationText = 'Durasi'
			break
	}

	let rentalPriceInt = 0
	let servicesPriceInt = 0

	if (rental_price) {
		rentalPriceInt = parseInt(rental_price.replace(/\./g, ''))
	}

	if (chosenServices.length > 0) {
		chosenServices.forEach((service) => {
			servicesPriceInt =
				servicesPriceInt + parseInt(service.price.replace(/\./g, ''))
		})
	}

	let updateTenantButton = ''
	if (
		status === 'active' &&
		(role === 'owner' ||
			(role === 'admin' && admin_access.tenants_edit === 'yes'))
	) {
		updateTenantButton = (
			<button
				type="submit"
				className="btn btn-secondary btn-block"
				disabled={isLoading}
			>
				<FaEdit /> Ubah Data Penyewa
			</button>
		)
	}

	let backlink = ''

	if (status === 'checkout' || status === 'change room') {
		backlink = (
			<Link to="/tenants/past">
				<FaChevronLeft /> Kembali
			</Link>
		)
	} else {
		backlink = (
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
		)
	}

	const onChangeSwitch = (isChecked, event, switch_id) => {
		switch (switch_id) {
			//tenants
			case 'send_whatsapp':
				updateWaSwitch(property_id, id, !send_whatsapp)

				setFormData((prevState) => ({
					...prevState,
					send_whatsapp: !send_whatsapp,
				}))
				break
			default:
				break
		}
	}

	let sendWaMessage = ''

	if (send_whatsapp) {
		sendWaMessage = (
			<p>
				Tagihan {durationText} berikutnya akan dibuat oleh sistem dan dikirim
				otomatis ke chat WhatsApp penyewa sesuai dengan data diatas.
			</p>
		)
	} else {
		sendWaMessage = (
			<p>
				Tagihan {durationText} berikutnya akan dibuat oleh sistem sesuai dengan
				data diatas. AdminKos tidak akan mengirim chat tagihan, kwitansi, dan
				pengingat pembayaran ke HP WhatsApp penyewa.
			</p>
		)
	}

	return (
		<>
			{backlink}
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			{(status === 'checkout' || status === 'change room') && rental_end && (
				<Card reverse={true}>
					<h3>Penyewa sudah check out</h3>
					<p>
						Penyewa <span style={{ textTransform: 'capitalize' }}>{name}</span>{' '}
						sudah tercatat keluar dari Kamar{' '}
						<span style={{ textTransform: 'capitalize' }}>{room_name}</span>{' '}
						pada tanggal {moment(rental_end).format('DD MMM YYYY')}.
					</p>
				</Card>
			)}
			<form onSubmit={handleSubmit} autoComplete="off">
				<h2 className="pageTitle">Data Penyewa</h2>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Nama Penyewa</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder="Nama penyewa"
						id="name"
						value={name}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Handphone (WA)</b>
						</label>
					</div>
					<input
						type="number"
						className="textInput"
						placeholder="Nomor WA 08XXXXXXXXXX"
						id="handphone"
						value={handphone}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div
						className="inputLabel"
						data-tooltip-id="q-sendwa"
						data-tooltip-content="AdminKos akan mengirim WhatsApp otomatis ke penyewa, seperti tagihan, kwitansi dan pengingat pembayaran."
					>
						<label>
							<b>Kirim WhatsApp ke penyewa</b> <FaQuestionCircle />
						</label>
						<Tooltip id="q-sendwa" style={{ maxWidth: '70%' }} />
					</div>
					<Switch
						id="send_whatsapp"
						width={70}
						onChange={onChangeSwitch}
						checked={send_whatsapp}
					/>
				</div>
				<h2 className="pageTitle">Data Kamar & Sewa</h2>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>nomor Kamar</b>
							</label>
						</div>
						{room_name && (
							<p style={{ textTransform: 'capitalize' }}>{room_name}</p>
						)}
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Tanggal Mulai Sewa</b>
							</label>
						</div>
						{moment(rentalStart).format('DD MMM YYYY')}
					</div>
				</div>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Durasi Sewa</b>
							</label>
						</div>
						<select
							id="duration"
							value={duration}
							onChange={onChange}
							className="select"
							required
							// disabled={id ? true : false}
						>
							<option value="">Pilih durasi</option>
							<option value="month">Bulanan</option>
							<option value="week">Mingguan</option>
							<option value="quarter">3 bulanan</option>
						</select>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Sewa / {durationText} (Rp)</b>
							</label>
						</div>
						<NumericFormat
							value={rental_price}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Harga sewa"
							id="rental_price"
							onChange={onChange}
							required
						/>
					</div>
					{/* <div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-deposit"
							data-tooltip-content="Deposit adalah uang jaminan yang akan dikembalikan di akhir sewa."
						>
							<label>
								<b>Deposit (Rp)</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-deposit" style={{ maxWidth: '70%' }} />
						</div>
						<NumericFormat
							value={deposit}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Opsional"
							id="deposit"
							onChange={onChange}
							disabled={id ? true : false}
						/>
					</div> */}
					{/* <div className="inputGroup">
						<div
							className="inputLabel"
							data-tooltip-id="q-penalty"
							data-tooltip-content="Denda keterlambatan pembayaran (%) dari total harga sewa dan layanan."
						>
							<label>
								<b>Denda (%)</b> <FaQuestionCircle />
							</label>
							<Tooltip id="q-penalty" style={{ maxWidth: '70%' }} />
						</div>
						<NumericFormat
							value={penalty}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Opsional"
							id="penalty"
							onChange={onChange}
						/>
					</div> */}
				</div>
				<h2 className="pageTitle">Layanan Tambahan</h2>
				<div className="inputGroup">
					<div className="inputLabel inputLabel2cols">
						<label style={{ paddingBottom: '0px' }}>
							<b>Layanan (opsional)</b>
							{services && services.length === 0 && (
								<>
									<br />
									Anda belum mengisi layanan. Silahkan{' '}
									<Link to="/services">Isi Disini</Link>.
								</>
							)}
						</label>
						<button
							onClick={onAddService}
							type="button"
							className="btn btn-secondary btn-small"
						>
							Tambah
						</button>
					</div>
					{chosenServices.length > 0 &&
						chosenServices.map((item, index) => (
							<TenantsServicesForm
								key={index}
								item={item}
								index={index}
								duration={duration}
								deleteService={onDeleteService}
								editService={onEditService}
							/>
						))}
				</div>
				<h2 className="pageTitle">Data Tambahan</h2>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Identitas KTP, SIM (opsional, jpg/png)</b>
						</label>
					</div>
					<input
						type="file"
						className="textInput fileInput"
						id="identity_image"
						onChange={onChangeImage}
					/>
					{identity_url && (
						<div style={{ marginBottom: '10px', marginTop: '10px' }}>
							<button
								type="button"
								onClick={onClickImage}
								className="btn btn-small btn-secondary"
								style={{ width: '160px' }}
							>
								<FaEye /> Lihat Gambar
							</button>
						</div>
					)}
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Foto Penyewa (opsional, jpg/png)</b>
						</label>
					</div>
					<input
						type="file"
						className="textInput fileInput"
						id="photo_image"
						onChange={onChangePhoto}
					/>
					{photo_url && (
						<div style={{ marginBottom: '10px', marginTop: '10px' }}>
							<button
								type="button"
								onClick={onClickPhoto}
								className="btn btn-small btn-secondary"
								style={{ width: '160px' }}
							>
								<FaEye /> Lihat Foto
							</button>
						</div>
					)}
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Catatan Tambahan (opsional)</b>
						</label>
					</div>
					<textarea
						className="textarea"
						placeholder="Contoh: 2 penghuni, suami istri, karyawan swasta"
						id="note"
						value={note}
						onChange={onChange}
					/>
				</div>
				{updateTenantButton}
			</form>
			<br />
			<Link to="/tenants">
				<FaChevronLeft /> Kembali
			</Link>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Rangkuman</h2>
				<table
					style={{
						tableLayout: 'fixed',
						width: '100%',
					}}
				>
					<tbody>
						<tr className="cellHeader">
							<td colSpan="2">
								<strong>Penyewa & Kamar</strong>
							</td>
						</tr>
						{name && (
							<tr>
								<td>Nama Penyewa</td>
								<td>{name}</td>
							</tr>
						)}
						{handphone && (
							<tr>
								<td>Handphone</td>
								<td>{handphone}</td>
							</tr>
						)}
						{note && (
							<tr>
								<td>Catatan Tambahan</td>
								<td>{note}</td>
							</tr>
						)}
						{property_name && (
							<tr>
								<td>Properti</td>
								<td>{property_name}</td>
							</tr>
						)}
						{room_name && (
							<tr>
								<td>Kamar</td>
								<td>{room_name}</td>
							</tr>
						)}
						<tr className="cellHeader">
							<td colSpan="2">
								<strong>Sewa</strong>
							</td>
						</tr>
						{duration && (
							<tr>
								<td>Durasi Sewa</td>
								<td>{durationWording}</td>
							</tr>
						)}
						{/* {deposit && (
							<tr>
								<td>Deposit</td>
								<td>
									{formatter.format(parseInt(deposit.replace(/\./g, '')))}
								</td>
							</tr>
						)} */}
						{/* {penalty && (
							<tr>
								<td>Denda</td>
								<td>{parseInt(penalty)} %</td>
							</tr>
						)} */}
						{rental_price && (
							<tr>
								<td>Sewa / {durationText}</td>
								<td>
									{formatter.format(parseInt(rental_price.replace(/\./g, '')))}
								</td>
							</tr>
						)}
						{rentalStart && (
							<tr>
								<td>Jatuh Tempo</td>
								<td>{moment(rentalStart).format('DD MMM YYYY')}</td>
							</tr>
						)}
						{chosenServices.length > 0 && (
							<tr className="cellHeader">
								<td colSpan="2">
									<strong>Layanan Tambahan</strong>
								</td>
							</tr>
						)}
						{chosenServices.length > 0 &&
							chosenServices.map((service, index) => (
								<tr key={index}>
									<td>{service.name}</td>
									<td>
										{formatter.format(
											parseInt(service.price.replace(/\./g, ''))
										)}
									</td>
								</tr>
							))}
						<tr className="cellHeader">
							<td colSpan="2">
								<strong>Total</strong>
							</td>
						</tr>
						<tr>
							<td>
								<strong>Total / {durationText}</strong>
							</td>
							<td>
								<strong>
									{formatter.format(rentalPriceInt + servicesPriceInt)}
								</strong>
							</td>
						</tr>
					</tbody>
				</table>
				<br />
				{sendWaMessage}
				<p>Apakah Anda sudah yakin dengan data diatas?</p>
				<div className="inputGroup2cols">
					<button className="btn btn-block btn-cancel" onClick={closeModal}>
						<FaTimes /> Batal
					</button>
					<button
						className="btn btn-block btn-secondary"
						onClick={submitForm}
						disabled={isLoading}
					>
						{buttonState}
					</button>
				</div>
			</Modal>
		</>
	)
}

export default TenantsEdit
