import { Route, Routes } from 'react-router-dom'

import ExpensesList from './ExpensesList'
import ExpensesForm from './ExpensesForm'

function ExpensesPage() {
	return (
		<Routes>
			<Route path="/" element={<ExpensesList />} />
			<Route path="/edit" element={<ExpensesForm />} />
			<Route path="/edit/:id" element={<ExpensesForm />} />
			<Route
				path="*"
				element={
					<>
						<h1>404</h1>
						<p>Upps halaman tidak ditemukan.</p>
					</>
				}
			/>
		</Routes>
	)
}

export default ExpensesPage
