import { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaChartLine, FaBed, FaUsers, FaHome, FaFan } from 'react-icons/fa'

import AuthContext from '../context/AuthContext'

function NavBar() {
	const { userData } = useContext(AuthContext)
	const { role, admin_access } = userData

	const navigate = useNavigate()
	const location = useLocation()

	const pathMatchRoute = (route) => {
		if (route === location.pathname) {
			return true
		}
	}

	let tenantsLink = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.tenants === 'yes')
	) {
		tenantsLink = (
			<li className="navbarListItem" onClick={() => navigate('/tenants')}>
				<FaUsers
					size={25}
					color={pathMatchRoute('/tenants') ? '#f55c0a' : '#fff'}
				/>
				<p
					style={
						pathMatchRoute('/tenants')
							? { color: '#f55c0a' }
							: { color: 'white' }
					}
				>
					Penyewa
				</p>
			</li>
		)
	}

	let expensesLink = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.expenses === 'yes')
	) {
		expensesLink = (
			<li className="navbarListItem" onClick={() => navigate('/expenses')}>
				<FaFan
					size={25}
					color={pathMatchRoute('/expenses') ? '#f55c0a' : '#fff'}
				/>
				<p
					style={
						pathMatchRoute('/expenses')
							? { color: '#f55c0a' }
							: { color: 'white' }
					}
				>
					Biaya
				</p>
			</li>
		)
	}

	let roomsLink = ''
	if (role === 'owner' || (role === 'admin' && admin_access.rooms === 'yes')) {
		roomsLink = (
			<li className="navbarListItem" onClick={() => navigate('/rooms')}>
				<FaBed
					size={25}
					color={pathMatchRoute('/rooms') ? '#f55c0a' : '#fff'}
				/>
				<p
					style={
						pathMatchRoute('/rooms') ? { color: '#f55c0a' } : { color: 'white' }
					}
				>
					Kamar
				</p>
			</li>
		)
	}

	let reportLink = ''
	if (role === 'owner' || (role === 'admin' && admin_access.report === 'yes')) {
		reportLink = (
			<li className="navbarListItem" onClick={() => navigate('/report')}>
				<FaChartLine
					size={25}
					color={pathMatchRoute('/report') ? '#f55c0a' : '#fff'}
				/>
				<p
					style={
						pathMatchRoute('/report')
							? { color: '#f55c0a' }
							: { color: 'white' }
					}
				>
					Laporan
				</p>
			</li>
		)
	}

	return (
		<footer className="navbar">
			<nav className="navbarNav">
				<ul className="navbarListItems">
					<li className="navbarListItem" onClick={() => navigate('/')}>
						<FaHome
							size={25}
							color={pathMatchRoute('/') ? '#f55c0a' : '#fff'}
						/>
						<p
							style={
								pathMatchRoute('/') ? { color: '#f55c0a' } : { color: 'white' }
							}
						>
							Beranda
						</p>
					</li>
					{roomsLink}
					{tenantsLink}
					{expensesLink}
					{reportLink}
				</ul>
			</nav>
		</footer>
	)
}

export default NavBar
