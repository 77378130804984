import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
	FaFileInvoiceDollar,
	FaEye,
	FaWhatsapp,
	FaClock,
	FaUser,
	FaSignOutAlt,
	FaTimes,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'

const customStyles = {
	content: {
		bottom: 'auto',
		background: '#672f0a',
		borderRadius: '10px',
		inset: '20px 20px auto',
		zIndex: 1,
		maxWidth: '600px',
		maxHeight: '95%',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

function TenantsItem({ item, getAllTenants }) {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [checkoutType, setCheckoutType] = useState('')
	const [checkoutDate, setCheckoutDate] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	const handleCheckout = (e) => {
		e.preventDefault()

		if (!checkoutDate || !checkoutType) {
			toast.error('Mohon melengkapi tipe checkout & tanggal keluar.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const rental_end = moment(checkoutDate).format('YYYY-MM-DD')

		if (item.id && property_id) {
			checkoutTenant(property_id, item.id, rental_end, checkoutType)
			setCheckoutDate('')
			setCheckoutType('')
		}
	}

	//checkout tenant
	const checkoutTenant = async (property_id, id, rental_end, checkout_type) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/tenant/checkout?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('tenant_id', id)
			formData.append('rental_end', rental_end)
			formData.append('checkout_type', checkout_type)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				if (checkout_type === 'checkout property') {
					navigate('/tenants/past')
				} else if (checkout_type === 'change room') {
					getAllTenants(property_id)
					navigate('/tenants')
				}

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const whatsapp = item.handphone.substring(1)

	let durationText
	switch (item.duration) {
		case 'month':
			durationText = 'bulan'
			break
		case 'week':
			durationText = 'minggu'
			break
		case 'quarter':
			durationText = '3 bulan'
			break
		default:
			break
	}

	const dueDate = new Date(item.due_date)
	let currDate = new Date()
	let passDueText = ''

	if (
		dueDate < currDate &&
		item.status !== 'fully paid' &&
		item.status !== 'cancel'
	) {
		passDueText = (
			<span style={{ color: 'red', fontSize: '0.85rem' }}>
				<strong>Sudah Jatuh Tempo</strong>
			</span>
		)
	}

	function openModal() {
		setModalIsOpen(true)
	}

	function closeModal() {
		setModalIsOpen(false)
	}

	let checkoutButton = ''

	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.tenants_checkout === 'yes')
	) {
		checkoutButton = (
			<button className="btn btn-small btn-danger" onClick={openModal}>
				<FaSignOutAlt /> Keluar
			</button>
		)
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading) {
		buttonState = 'Keluar'
	}

	return (
		<>
			<Card reverse={false}>
				<div className="flex-2cols">
					<div className="text-title">
						<FaUser /> {item.name}
					</div>
					<span className="badge badge-info">Kamar {item.room_name}</span>
				</div>
				<div className="flex-2cols">
					<a
						href={`https://api.whatsapp.com/send?phone=62${whatsapp}`}
						target="_blank"
						rel="noreferrer"
					>
						<div className="text-display">
							<FaWhatsapp /> {item.handphone}
						</div>
					</a>
					<div className="text-display">
						{formatter.format(item.total_price)}/{durationText}
					</div>
				</div>
				<div className="flex-2cols">
					<div className="text-display">
						{item.due_date && (
							<>
								<FaClock />{' '}
								<strong>{moment(item.due_date).format('DD MMM YYYY')}</strong>
							</>
						)}
					</div>
					<div className="text-display">{passDueText}</div>
				</div>
				<div style={{ marginTop: '10px' }}>
					<Link to={`/invoices/${item.id}`}>
						<button className="btn btn-small btn-success">
							<FaFileInvoiceDollar /> Tagihan
						</button>
					</Link>
					<Link to={`/tenants/edit/${item.id}`}>
						<button className="btn btn-small btn-success">
							<FaEye /> Detail
						</button>
					</Link>
					{checkoutButton}
				</div>
			</Card>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Keluar Kamar</h2>
				<p>
					Penyewa keluar dari kamar, baik pindah kamar atau keluar dari
					properti.
				</p>
				<form onSubmit={handleCheckout} autoComplete="off">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Pilih Tipe</b>
							</label>
						</div>
						<select
							value={checkoutType}
							onChange={(e) => setCheckoutType(e.target.value)}
							className="select"
							required
						>
							<option value="">Pilih tipe</option>
							<option value="change room">Pindah Kamar</option>
							<option value="checkout property">Keluar Dari Properti</option>
						</select>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Tanggal Keluar</b>
							</label>
						</div>
						<DatePicker
							selected={checkoutDate}
							maxDate={new Date()}
							onChange={(date) => setCheckoutDate(date)}
							dateFormat="dd-MM-yyyy"
							required
							onKeyDown={(e) => {
								e.preventDefault()
							}}
							placeholderText="Pilih tanggal checkout"
							onFocus={(e) => e.target.blur()}
						/>
					</div>
					<p>
						Penyewa{' '}
						<span style={{ textTransform: 'capitalize' }}>{item.name}</span>,
						akan tercatat keluar dari kamar {item.room_name} pada tanggal{' '}
						{checkoutDate && moment(checkoutDate).format('DD MMM YYYY')}.
					</p>
					<div className="inputGroup2cols">
						<button className="btn btn-block btn-cancel" onClick={closeModal}>
							<FaTimes /> Batal
						</button>
						<button
							type="submit"
							className="btn btn-block btn-danger"
							disabled={isLoading}
						>
							{buttonState}
						</button>
					</div>
				</form>
			</Modal>
		</>
	)
}

TenantsItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default TenantsItem
