import PropTypes from 'prop-types'

function Header({ text, bgColor, textColor }) {
	const headerStyles = {
		backgroundColor: bgColor,
		color: textColor,
	}

	return (
		<header style={headerStyles}>
			<div className="container">
				<div className="headerSection">
					<div style={{ textAlign: 'left', width: '50px' }}></div>
					<h2 style={{ fontSize: '1.5rem' }}>
						<img
							width="35"
							src={process.env.PUBLIC_URL + '/logo192.png'}
							alt="Admin Kos"
							style={{ float: 'left', marginRight: '10px' }}
						/>{' '}
						{text}
					</h2>
					<div style={{ textAlign: 'right', width: '50px' }}></div>
				</div>
			</div>
		</header>
	)
}

Header.defaultProps = {
	text: 'Default title here',
	bgColor: 'rgba(0,0,0,0.5)',
	textColor: '#ff5900',
}

Header.propTypes = {
	text: PropTypes.string.isRequired,
	bgColor: PropTypes.string.isRequired,
	textColor: PropTypes.string.isRequired,
}

export default Header
