import { useState } from 'react'
import { browserName, isIOS, isAndroid } from 'react-device-detect'
import { FaDesktop, FaTimes } from 'react-icons/fa'
import Modal from 'react-modal'

const customStyles = {
	content: {
		bottom: 'auto',
		background: '#672f0a',
		borderRadius: '10px',
		inset: '20px 20px auto',
		zIndex: 2,
		maxWidth: '600px',
		maxHeight: '95%',
		overflowY: 'scroll',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: '10px',
	},
}

function DownloadApp() {
	const [modalIOSIsOpen, setModalIOSIsOpen] = useState(false)
	const [modalChromeIsOpen, setModalChromeIsOpen] = useState(false)
	const [modalSamsungIsOpen, setModalSamsungIsOpen] = useState(false)

	function openIOSModal() {
		setModalIOSIsOpen(true)
	}

	function closeIOSModal() {
		setModalIOSIsOpen(false)
	}

	function openChromeModal() {
		setModalChromeIsOpen(true)
	}

	function closeChromeModal() {
		setModalChromeIsOpen(false)
	}

	function openSamsungModal() {
		setModalSamsungIsOpen(true)
	}

	function closeSamsungModal() {
		setModalSamsungIsOpen(false)
	}

	const modalIOS = () => {
		openIOSModal()
	}

	const modalChrome = () => {
		openChromeModal()
	}

	const modalSamsung = () => {
		openSamsungModal()
	}

	let downloadButton = ''
	if (window.matchMedia('(display-mode: standalone)').matches) {
		//do nothing
	} else {
		if (isAndroid && browserName === 'Chrome') {
			downloadButton = (
				<div
					className="notifCard notifCardReverse"
					onClick={modalChrome}
					style={{ cursor: 'pointer' }}
				>
					<FaDesktop /> Install Aplikasi ke HP Android Anda
				</div>
			)
		} else if (isIOS && browserName === 'Mobile Safari') {
			downloadButton = (
				<div
					className="notifCard notifCardReverse"
					onClick={modalIOS}
					style={{ cursor: 'pointer' }}
				>
					<FaDesktop /> Install Aplikasi ke Iphone Anda
				</div>
			)
		} else if (
			isAndroid &&
			browserName !== 'Chrome' &&
			browserName !== 'Samsung Browser'
		) {
			downloadButton = (
				<div
					className="notifCard notifCardReverse"
					onClick={modalChrome}
					style={{ cursor: 'pointer' }}
				>
					<FaDesktop /> Install Aplikasi ke HP Android Anda
				</div>
			)
		}
	}

	let downloadButton2 = ''
	if (isAndroid && browserName === 'Samsung Browser') {
		downloadButton2 = (
			<div
				className="notifCard notifCardReverse"
				onClick={modalSamsung}
				style={{ cursor: 'pointer' }}
			>
				<FaDesktop /> Install Aplikasi ke HP Samsung Anda
			</div>
		)
	}

	return (
		<>
			{downloadButton}
			{downloadButton2}
			<Modal
				isOpen={modalIOSIsOpen}
				onRequestClose={closeIOSModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Cara install ke Iphone</h2>
				<p>lihat gambar dibawah:</p>
				<p>
					1. Klik icon Share
					<br />
					2. Klik Add to Home Screen
					<br />
					3. Klik Add
				</p>
				<img
					width="100%"
					src={process.env.PUBLIC_URL + '/ios-save.webp'}
					alt="Admin Kos IOS"
				/>
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<button className="btn btn-small btn-cancel" onClick={closeIOSModal}>
						<FaTimes /> Tutup
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modalChromeIsOpen}
				onRequestClose={closeChromeModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Cara install ke Android</h2>
				<p>Contoh menggunakan Chrome (lihat gambar dibawah):</p>
				<p>
					1. Klik icon dots (3 titik)
					<br />
					2. Klik Install App / Add to Home Screen
					<br />
					3. Klik Install
				</p>
				<img
					width="100%"
					src={process.env.PUBLIC_URL + '/chrome-save.webp'}
					alt="Admin Kos Android"
				/>
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<button
						className="btn btn-small btn-cancel"
						onClick={closeChromeModal}
					>
						<FaTimes /> Tutup
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modalSamsungIsOpen}
				onRequestClose={closeSamsungModal}
				style={customStyles}
				ariaHideApp={false}
			>
				<h2 className="pageTitle">Cara install ke Samsung</h2>
				<p>Lihat gambar dibawah:</p>
				<p>
					1. Klik icon menu (3 strips)
					<br />
					2. Klik Add page to
					<br />
					3. Klik Home screen
					<br />
					4. Klik Add Automatically
				</p>
				<img
					width="100%"
					src={process.env.PUBLIC_URL + '/samsung-save.webp'}
					alt="Admin Kos Samsung"
				/>
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<button
						className="btn btn-small btn-cancel"
						onClick={closeSamsungModal}
					>
						<FaTimes /> Tutup
					</button>
				</div>
			</Modal>
		</>
	)
}

export default DownloadApp
