import { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft, FaKey } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/shared/Button'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function PaymentMethodsForm() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role } = userData

	const [formData, setFormData] = useState({
		payment_type: '',
		provider_name: '',
		account_number: '',
		account_name: '',
		otp: '',
	})
	const { payment_type, provider_name, account_number, account_name, otp } =
		formData
	const [paymentMethod, setPaymentMethod] = useState({
		id: '',
		payment_type: '',
		provider_name: '',
		account_number: '',
		account_name: '',
	})
	const [isLoading, setIsLoading] = useState(false)

	let { id } = useParams()

	const navigate = useNavigate()

	useEffect(() => {
		if (role === 'admin') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [role])

	useEffect(() => {
		if (id && property_id) {
			getPaymentMethod(property_id, id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	useEffect(() => {
		if (id) {
			setFormData({
				payment_type: paymentMethod.payment_type,
				provider_name: paymentMethod.provider_name
					? paymentMethod.provider_name
					: '',
				account_number: paymentMethod.account_number
					? paymentMethod.account_number
					: '',
				account_name: paymentMethod.account_name
					? paymentMethod.account_name
					: '',
				otp: '',
			})
		}
		//eslint-disable-next-line
	}, [paymentMethod])

	//get single payment method from API
	const getPaymentMethod = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/payment_method/detail?user_id=${tokens[2]}&payment_method_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setPaymentMethod({
				id: res.data.data.id,
				payment_type: res.data.data.payment_type,
				provider_name: res.data.data.provider_name,
				account_number: res.data.data.account_number,
				account_name: res.data.data.account_name,
			})
		} catch (err) {
			navigate('/payment_methods')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//update payment method
	const updatePaymentMethod = async (property_id, id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/payment_method/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('payment_method_id', id)
			formData.append('payment_type', updItem.payment_type)
			formData.append('provider_name', updItem.provider_name)
			formData.append('account_number', updItem.account_number)
			formData.append('account_name', updItem.account_name)
			formData.append('otp', updItem.otp)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				toast.success('Sukses merubah data metode pembayaran.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new payment method
	const addPaymentMethod = async (property_id, newpayment) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/payment_method/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('payment_type', newpayment.payment_type)
			formData.append('provider_name', newpayment.provider_name)
			formData.append('account_number', newpayment.account_number)
			formData.append('account_name', newpayment.account_name)
			formData.append('otp', newpayment.otp)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				navigate('/payment_methods')

				toast.success('Sukses menambah metode pembayaran.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//send OTP
	const sendOTP = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/payment_method/send_otp?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				toast.success('Kode OTP sudah dikirim ke Nomor WA Anda.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!payment_type || !otp) {
			toast.error('Mohon mengisi tipe pembayaran dan otp.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		if (payment_type === 'bank transfer' || payment_type === 'e-wallet') {
			if (!provider_name || !account_number || !account_name) {
				toast.error('Mohon mengisi semua field.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
				return false
			}
		}

		const newPaymentMethod = {
			payment_type,
			provider_name,
			account_number,
			account_name,
			otp,
		}

		if (id && property_id) {
			updatePaymentMethod(property_id, id, newPaymentMethod)
		} else {
			addPaymentMethod(property_id, newPaymentMethod)
		}
	}

	let buttonState
	let buttonOTPState

	if (isLoading) {
		buttonState = <Spinner />
		buttonOTPState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Ubah'
		buttonOTPState = (
			<>
				<FaKey /> Kirim OTP
			</>
		)
	} else if (!isLoading && !id) {
		buttonState = 'Tambah'
		buttonOTPState = (
			<>
				<FaKey /> Kirim OTP
			</>
		)
	}

	let paymentLabels = {
		name: '',
		account_number: '',
		account_name: '',
	}

	switch (payment_type) {
		case 'bank transfer':
			paymentLabels = {
				name: 'Nama Bank',
				account_number: 'Nomor Rekening',
				account_name: 'Nama Rekening',
			}
			break
		case 'e-wallet':
			paymentLabels = {
				name: 'Nama E-Wallet',
				account_number: 'Nomor Akun',
				account_name: 'Nama Akun',
			}
			break
		default:
			break
	}

	let bank_options = ''
	if (payment_type === 'bank transfer' || payment_type === 'e-wallet') {
		bank_options = (
			<>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>{paymentLabels.name}</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder={paymentLabels.name}
						id="provider_name"
						value={provider_name}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>{paymentLabels.account_number}</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder={paymentLabels.account_number}
						id="account_number"
						value={account_number}
						onChange={onChange}
						required
					/>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>{paymentLabels.account_name}</b>
						</label>
					</div>
					<input
						type="text"
						className="textInput"
						placeholder={paymentLabels.account_name}
						id="account_name"
						value={account_name}
						onChange={onChange}
						required
					/>
				</div>
			</>
		)
	}

	const handleSendOTP = (e) => {
		e.preventDefault()

		sendOTP(property_id)
	}

	let updatePaymentButton = ''
	if (role === 'owner') {
		updatePaymentButton = (
			<Button type="submit" version="block" isDisabled={isLoading}>
				{buttonState}
			</Button>
		)
	}

	return (
		<>
			<Link to="/payment_methods">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">{id ? 'Edit' : 'Tambah'} Metode Pembayaran</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Tipe Pembayaran</b>
						</label>
					</div>
					<select
						id="payment_type"
						value={payment_type}
						onChange={onChange}
						className="select"
						required
					>
						<option value="">Pilih Tipe Pembayaran</option>
						<option value="cash">Cash / Tunai</option>
						<option value="bank transfer">Bank Transfer</option>
						<option value="e-wallet">E-Wallet (Dana, Gopay, Ovo dll)</option>
					</select>
				</div>
				{bank_options}
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<input
							type="text"
							className="textInput"
							placeholder="Isi OTP"
							id="otp"
							value={otp}
							onChange={onChange}
							required
						/>
					</div>
					<button
						type="button"
						className="btn btn-secondary btn-medium"
						disabled={isLoading}
						onClick={handleSendOTP}
					>
						{buttonOTPState}
					</button>
				</div>
				{updatePaymentButton}
			</form>
		</>
	)
}

export default PaymentMethodsForm
