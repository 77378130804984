import PropTypes from 'prop-types'
import {
	FaFileInvoiceDollar,
	FaWhatsapp,
	FaUser,
	FaClock,
	FaEye,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Card from '../../components/shared/Card'

const formatter = new Intl.NumberFormat('id-ID', {
	style: 'currency',
	currency: 'IDR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
})

function TenantsItemPast({ item }) {
	const whatsapp = item.handphone.substring(1)

	let durationText
	switch (item.duration) {
		case 'month':
			durationText = 'bulan'
			break
		case 'week':
			durationText = 'minggu'
			break
		case 'quarter':
			durationText = '3 bulan'
			break
		default:
			break
	}

	const dueDate = new Date(item.due_date)
	let currDate = new Date()
	let passDueText = ''

	if (
		dueDate < currDate &&
		item.status !== 'fully paid' &&
		item.status !== 'cancel'
	) {
		passDueText = (
			<span style={{ color: 'red', fontSize: '0.85rem' }}>
				<strong>Sudah Jatuh Tempo</strong>
			</span>
		)
	}

	return (
		<Card reverse={false}>
			<div className="flex-2cols">
				<div className="text-title">
					<FaUser /> {item.name}
				</div>
				<span className="badge badge-info">Kamar {item.room_name}</span>
			</div>
			<div className="flex-2cols">
				<a
					href={`https://api.whatsapp.com/send?phone=62${whatsapp}`}
					target="_blank"
					rel="noreferrer"
				>
					<div className="text-display">
						<FaWhatsapp /> {item.handphone}
					</div>
				</a>
				<div className="text-display">
					{formatter.format(item.total_price)}/{durationText}
				</div>
			</div>
			<div className="flex-2cols">
				<div className="text-display">
					{item.due_date && (
						<>
							<FaClock />{' '}
							<strong>{moment(item.due_date).format('DD MMM YYYY')}</strong>
						</>
					)}
				</div>
				<div className="text-display">{passDueText}</div>
			</div>
			<div className="flex-2cols">
				<div className="text-display">
					Masuk {moment(item.rental_start).format('DD MMM YYYY')}
				</div>
				<div className="text-display">
					Keluar {moment(item.rental_end).format('DD MMM YYYY')}
				</div>
			</div>
			<div style={{ marginTop: '10px' }}>
				<Link to={`/invoices/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaFileInvoiceDollar /> Tagihan
					</button>
				</Link>
				<Link to={`/tenants/edit/${item.id}`}>
					<button className="btn btn-small btn-success">
						<FaEye /> Lihat
					</button>
				</Link>
			</div>
		</Card>
	)
}

TenantsItemPast.propTypes = {
	item: PropTypes.object.isRequired,
}

export default TenantsItemPast
