import { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaChevronLeft, FaEye } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NumericFormat } from 'react-number-format'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import imageCompression from 'browser-image-compression'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/shared/Button'
import AuthContext from '../../context/AuthContext'
import Spinner from '../../components/Spinner'
import Spinner2 from '../../components/Spinner2'

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function ExpensesForm() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { property_id } = activeProperty
	const { role, admin_access } = userData

	const [expenseOptions, setExpenseOptions] = useState('')
	const [expenseDate, setExpenseDate] = useState('')
	const [formData, setFormData] = useState({
		expense_id: '',
		amount: '',
		image: '',
		image_path: '',
		note: '',
	})
	const { expense_id, amount, image, image_path, note } = formData
	const [expense, setExpense] = useState({
		expense_id: '',
		amount: '',
		date: '',
		image: '',
		image_path: '',
		note: '',
	})
	const [isLoading, setIsLoading] = useState(false)

	let { id } = useParams()

	const navigate = useNavigate()

	useEffect(() => {
		if (!id && role === 'admin' && admin_access.expenses_add === 'no') {
			navigate('/')
		}
		//eslint-disable-next-line
	}, [id, role, admin_access])

	useEffect(() => {
		if (id && property_id) {
			getExpense(property_id, id)
		}
		//eslint-disable-next-line
	}, [property_id, id])

	useEffect(() => {
		if (property_id) {
			getExpenseOptions(property_id)
		}
		//eslint-disable-next-line
	}, [property_id])

	useEffect(() => {
		if (id) {
			setFormData((prevState) => ({
				...prevState,
				expense_id: expense.expense_id,
				amount: expense.amount,
				image_path: expense.image_path,
				note: expense.note,
			}))

			if (expense.date) {
				setExpenseDate(new Date(expense.date))
			}
		}
		//eslint-disable-next-line
	}, [expense])

	//get single expense from API
	const getExpense = async (property_id, id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/expense/detail?user_id=${tokens[2]}&expense_id=${id}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setExpense({
				expense_id: res.data.data.expense_id,
				amount: res.data.data.amount,
				date: res.data.data.date,
				image_path: res.data.data.image,
				note: res.data.data.note,
			})
		} catch (err) {
			navigate('/expenses')

			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//get all expenses from API
	const getExpenseOptions = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/expense/options?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setExpenseOptions(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//add new expense
	const addExpense = async (property_id, newExpense) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/expense/create?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('expense_id', newExpense.expense_id)
			formData.append('date', newExpense.date)
			formData.append('amount', newExpense.amount)
			formData.append('image', newExpense.image)
			formData.append('note', newExpense.note)

			const res = await axios.post(url, formData, config)

			if (res.status === 201) {
				navigate('/expenses')

				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//update expense
	const updateExpense = async (property_id, id, updItem) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/expense/edit?user_id=${tokens[2]}&refresh_token=${tokens[1]}`

			const config = configData()

			let formData = new FormData()
			formData.append('property_id', property_id)
			formData.append('id', updItem.id)
			formData.append('expense_id', updItem.expense_id)
			formData.append('date', updItem.date)
			formData.append('amount', updItem.amount)
			formData.append('image', updItem.image)
			formData.append('note', updItem.note)

			const res = await axios.post(url, formData, config)

			if (res.status === 200) {
				toast.success(res.data.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	//onchange identity image
	const onChangeImage = async (e) => {
		const imageFile = e.target.files[0]

		if (!imageFile.type.startsWith('image')) {
			toast.error('Tipe gambar harus jpg/png', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		} else {
			const options = {
				maxSizeMB: 0.8,
				maxWidthOrHeight: 600,
				useWebWorker: true,
				onProgress: () => null,
			}
			try {
				const compressedBlob = await imageCompression(imageFile, options)

				const compressedFile = new File([compressedBlob], imageFile.name, {
					lastModified: compressedBlob.lastModified,
					type: 'image/jpeg',
				})

				setFormData((prevState) => ({
					...prevState,
					image: compressedFile,
				}))
			} catch (error) {
				toast.error(error, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
					theme: 'colored',
				})
			}
		}
	}

	const onClickImage = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-alert">
						<p>
							<img
								src={
									process.env.REACT_APP_APIURL +
									'uploads/expenses/' +
									image_path
								}
								alt="foto biaya"
							/>
						</p>
						<button className="btn btn-small btn-secondary" onClick={onClose}>
							Tutup
						</button>
						<div className="clear"></div>
					</div>
				)
			},
		})
	}

	const onChange = (e) => {
		let targetValue

		if (e.target.id === 'amount') {
			targetValue = addCommas(removeNonNumeric(e.target.value))
		} else {
			targetValue = e.target.value
		}

		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: targetValue,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!amount) {
			toast.error('Mohon mengisi isian yang dibutuhkan.', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
			return false
		}

		const newExpense = {
			id,
			expense_id,
			image,
			amount: amount !== '' ? parseInt(amount.replace(/\./g, '')) : '',
			date: moment(expenseDate).format('YYYY-MM-DD'),
			note,
		}

		if (id && property_id) {
			updateExpense(property_id, id, newExpense)
		} else {
			addExpense(property_id, newExpense)
		}
	}

	let buttonState

	if (isLoading) {
		buttonState = <Spinner />
	} else if (!isLoading && id) {
		buttonState = 'Ubah'
	} else if (!isLoading && !id) {
		buttonState = 'Tambah'
	}

	let updateExpenseButton = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.expenses_edit === 'yes')
	) {
		updateExpenseButton = (
			<Button type="submit" version="block" isDisabled={isLoading}>
				{buttonState}
			</Button>
		)
	}

	return (
		<>
			<Link to="/expenses">
				<FaChevronLeft /> Kembali
			</Link>
			<h2 className="pageTitle">{id ? 'Edit' : 'Tambah'} Biaya</h2>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Pilih Biaya</b>
						</label>
					</div>
					<select
						id="expense_id"
						value={expense_id}
						onChange={onChange}
						className="select"
						required
					>
						<option value="">Pilih Biaya</option>
						{expenseOptions &&
							expenseOptions.map((item) => (
								<option key={item.id} value={item.id}>
									{item.name}
								</option>
							))}
					</select>
				</div>
				<div className="inputGroup2cols">
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Tanggal Biaya</b>
							</label>
						</div>
						<DatePicker
							selected={expenseDate}
							required
							onChange={(date) => setExpenseDate(date)}
							dateFormat="dd-MM-yyyy"
							onKeyDown={(e) => {
								e.preventDefault()
							}}
							placeholderText="Pilih tanggal biaya"
							onFocus={(e) => e.target.blur()}
						/>
					</div>
					<div className="inputGroup">
						<div className="inputLabel">
							<label>
								<b>Biaya (Rp)</b>
							</label>
						</div>
						<NumericFormat
							value={amount}
							className="textInput"
							thousandSeparator="."
							decimalSeparator=","
							placeholder="Isi Biaya"
							id="amount"
							onChange={onChange}
							required
						/>
					</div>
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Foto bukti biaya (opsional, jpg/png)</b>
						</label>
					</div>
					<input
						type="file"
						className="textInput fileInput"
						id="image"
						onChange={onChangeImage}
					/>
					{image_path && (
						<div style={{ marginBottom: '10px', marginTop: '10px' }}>
							<button
								type="button"
								onClick={onClickImage}
								className="btn btn-small btn-secondary"
								style={{ width: '160px' }}
							>
								<FaEye /> Lihat Gambar
							</button>
						</div>
					)}
				</div>
				<div className="inputGroup">
					<div className="inputLabel">
						<label>
							<b>Catatan Tambahan (opsional)</b>
						</label>
					</div>
					<textarea
						className="textarea"
						placeholder="Isi catatan tambahan"
						id="note"
						value={note}
						onChange={onChange}
					/>
				</div>
				{updateExpenseButton}
			</form>
		</>
	)
}

export default ExpensesForm
