import { Route, Routes } from 'react-router-dom'

import PropertiesList from './PropertiesList'
import PropertiesForm from './PropertiesForm'

function PropertiesPage() {
	return (
		<Routes>
			<Route path="/" element={<PropertiesList />} />
			<Route path="/edit" element={<PropertiesForm />} />
			<Route path="/edit/:id" element={<PropertiesForm />} />
			<Route
				path="*"
				element={
					<>
						<h1>404</h1>
						<p>Upps halaman tidak ditemukan.</p>
					</>
				}
			/>
		</Routes>
	)
}

export default PropertiesPage
