import { useEffect, useState, useContext, Fragment } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import {
	FaBed,
	FaUsers,
	FaFan,
	FaBroom,
	FaKey,
	FaCreditCard,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import moment from 'moment'

import Card from '../../components/shared/Card'
import AuthContext from '../../context/AuthContext'
import Spinner2 from '../../components/Spinner2'
import DownloadApp from '../../components/DownloadApp'

const configData = () => {
	const tokens = JSON.parse(localStorage.getItem('tokens'))

	const config = {
		headers: {
			'X-API-Key': process.env.REACT_APP_XAPIKEY,
			Authorization: `Bearer ${tokens[0]}`,
		},
	}

	return config
}

function HomePage() {
	const { activeProperty, userData } = useContext(AuthContext)
	const { role, admin_access } = userData
	const { property_id } = activeProperty

	const [content, setContent] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (property_id) {
			getContent(property_id)
		}

		//eslint-disable-next-line
	}, [property_id])

	//get all expenses from API
	const getContent = async (property_id) => {
		setIsLoading(true)

		const tokens = JSON.parse(localStorage.getItem('tokens'))

		try {
			const url = `${process.env.REACT_APP_APIURL}v1/homepage/info?user_id=${tokens[2]}&refresh_token=${tokens[1]}&property_id=${property_id}`

			const config = configData()

			const res = await axios.get(url, config)

			setContent(res.data.data)
		} catch (err) {
			toast.error(err.response.data.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
				theme: 'colored',
			})
		}

		setIsLoading(false)
	}

	let roomBlock = ''
	if (role === 'owner' || (role === 'admin' && admin_access.rooms === 'yes')) {
		if (content && content.rooms === 'no') {
			roomBlock = (
				<Card reverse={true} cssClass="card smallMargin">
					<Link to="/rooms" style={{ color: 'white' }}>
						<div className="homeRecomm">
							<FaBed /> Kamar
						</div>
					</Link>
				</Card>
			)
		}
	}

	let servicesBlock = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.services === 'yes')
	) {
		if (content && content.services === 'no') {
			servicesBlock = (
				<Card reverse={true} cssClass="card smallMargin">
					<Link to="/services" style={{ color: 'white' }}>
						<div className="homeRecomm">
							<FaBroom /> Layanan
						</div>
					</Link>
				</Card>
			)
		}
	}

	let tenantsBlock = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.tenants === 'yes')
	) {
		if (content && content.tenants === 'no') {
			tenantsBlock = (
				<Card reverse={true} cssClass="card smallMargin">
					<Link to="/tenants" style={{ color: 'white' }}>
						<div className="homeRecomm">
							<FaUsers /> Penyewa
						</div>
					</Link>
				</Card>
			)
		}
	}

	let expensesBlock = ''
	if (
		role === 'owner' ||
		(role === 'admin' && admin_access.expenses === 'yes')
	) {
		if (content && content.expenses === 'no') {
			expensesBlock = (
				<Card reverse={true} cssClass="card smallMargin">
					<Link to="/expenses" style={{ color: 'white' }}>
						<div className="homeRecomm">
							<FaFan /> Biaya
						</div>
					</Link>
				</Card>
			)
		}
	}

	let paymentmethodBlock = ''
	if (role === 'owner') {
		if (content && content.payment_methods === 'no') {
			paymentmethodBlock = (
				<Card reverse={true} cssClass="card smallMargin">
					<Link to="/payment_methods" style={{ color: 'white' }}>
						<div className="homeRecomm">
							<FaCreditCard /> Metode Bayar
						</div>
					</Link>
				</Card>
			)
		}
	}

	let adminaccessBlock = ''
	if (role === 'owner') {
		if (content && content.admin_access === 'no') {
			adminaccessBlock = (
				<Card reverse={true} cssClass="card smallMargin">
					<Link to="/adminaccess" style={{ color: 'white' }}>
						<div className="homeRecomm">
							<FaKey /> Kelola Admin
						</div>
					</Link>
				</Card>
			)
		}
	}

	let duedate_text = ''
	if (role === 'owner' && content && content.billing_due_date) {
		if (content.is_newuser === 'no') {
			duedate_text = (
				<div className="notifCard">
					Aplikasi Anda aktif s/d{' '}
					{moment(content.billing_due_date).format('DD MMM YYYY')}
				</div>
			)
		} else if (content.is_newuser === 'yes') {
			duedate_text = (
				<div className="notifCard">
					Trial Gratis Anda aktif s/d{' '}
					{moment(content.billing_due_date).format('DD MMM YYYY')}
				</div>
			)
		}
	}

	return (
		<>
			<div className="spinnerBlock">{isLoading && <Spinner2 />}</div>
			<DownloadApp />
			{duedate_text}
			<Swiper spaceBetween={20} slidesPerView={1}>
				{content &&
					content.slideshows.length > 0 &&
					content.slideshows.map((slide) => (
						<Fragment key={slide.id}>
							<SwiperSlide>
								<img
									src={
										process.env.REACT_APP_APIURL +
										`uploads/slideshows/${slide.name}`
									}
									alt="slideshow"
								/>
							</SwiperSlide>
						</Fragment>
					))}
			</Swiper>
			<h2 className="pageTitle">Rekomendasi</h2>
			<p>Yuk lengkapi data dibawah ini.</p>
			<div className="inputGroup2cols">
				{roomBlock}
				{servicesBlock}
				{paymentmethodBlock}
				{tenantsBlock}
				{expensesBlock}
				{adminaccessBlock}
			</div>
			{/* <h2 className="pageTitle">Sekilas Info</h2> */}
		</>
	)
}

export default HomePage
